import { Button } from '@mui/material';


export default function BtnVoirPlusOuMoins({ action, readMore }) {
    return <Button
        size="small"
        onClick={action}
        style={{
            color: "skyblue",
            cursor: "pointer",
        }}
    >
        {!readMore ? "voir plus" : "voir moins"}
    </Button>
}