import {
  Box,
  Card,
  Grid,
  TextField,
  FormControl,
  Select,
  MenuItem,
  OutlinedInput,
  InputLabel,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useState, useRef } from "react";
import { makeStyles } from '@mui/styles';
import {Link, useHistory, useParams} from 'react-router-dom';
import { detailsEmailType, addEmailType, editEmailType } from "../Services/EmailTypeService";
import Loader from "../../components/Loader/Loader";
import EditorCustom from "../../components/EditorCustorm/EditorCustom";
import { removeLineBreak } from "../../utils/EditorUtil";
import { withSnackbar } from "../../utils/SnackbarHOC";
import { theme } from "../../utils/Theme";
import SaveBar from "../Candidat/CandidatForm/Components/SaveBar";
import {ArrowBack} from "@mui/icons-material";

const useStyles = makeStyles(() => ({
  buttonProgress: {
    color: "white",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const EmailTypeForm = ({ editMode, snackbarShowMessage }) => {
  const classes = useStyles();
  const [msgName, setMsgName] = useState("");
  const [objet, setObjet] = useState("");
  const [message, setMessage] = useState("");
  const [loadingData, setLoadingData] = useState(false);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const title = editMode ? "MODIFIER UN MESSAGE TYPE " : "AJOUTER UN MESSAGE TYPE";
  const { uuid } = useParams();
  const activities = ["CANDIDAT", "CLIENT", "PROSPECT"];
  const TypeMsgs = ["EMAIL", "SMS"];
  const [activite, setActivite] = useState("");
  const [TypeMsg, setTypeMsg] = useState("");
  const active = useRef(false);
  const history = useHistory();

  const validate = () => {
    let temp = {};
    temp.msgName = msgName ? "" : "Le champ 'INTITULÉ DU MESSAGE' est obligatoire.";
    temp.message = !(message?.replace(/<\/?[^>]+(>|$)/g, "") === "") ? "" : "Le champ 'CONTENU DU MESSAGE' est obligatoire.";;
    temp.activite = activite ? "" : "Le champ 'ACTIVITÉ' est obligatoire.";
    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  React.useState(() => {
    active.current = true;
    return () => {
      active.current = false;
    };
  }, []);

  React.useEffect(() => {
    let active = true;
    if (editMode) {
      (async () => {
        setLoadingData(true);
        try {
          const response = await detailsEmailType(uuid);
          if (!active) {
            return;
          }
          setMsgName(response.data.msgName);
          setObjet(response.data?.objet);
          setMessage(response.data.message);
          setActivite(response.data.activite);
          setTypeMsg(response.data.TypeMsg);
          setLoadingData(false);
        } catch (error) {
          setLoadingData(false);
          history.push({
            pathname: "/emailtype",
          });
        }
      })();
    }

    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid]);

  const handleSubmit = () => {
    if (validate()) {
      (async () => {
        setLoading(true);
        setLoadingData(true);
        const data = {
          msgName: removeLineBreak(msgName),
          objet: objet,
          message: message,
          activite: activite,
          TypeMsg: TypeMsg,
        };

        try {
          snackbarShowMessage("Patientez svp!", "warning", null)
          await addEmailType(data);
          snackbarShowMessage("Message bien ajoutée!", "success", 4000)
          history.push({
            pathname: "/emailtype",
          });
        } catch (error) {
          if (!active.current) {
            return;
          }
          snackbarShowMessage("Une erreur est survenue: " + error.message, "error", 4000)
        } finally {
          setLoading(false);
          setLoadingData(false);
        }
      })();
    }
  };

  const handleUpdate = () => {
    if (validate()) {
      (async () => {
        setLoading(true);
        setLoadingData(true);
        const data = {
          msgName: removeLineBreak(msgName),
          objet: objet,
          message: message,
          activite: activite,
          TypeMsg: TypeMsg,
        };
        try {
          snackbarShowMessage("Patientez svp!", "warning", null)
          await editEmailType(uuid, data);
          snackbarShowMessage("Message bien ajoutée!", "success", 4000)
          history.push({
            pathname: "/emailtype",
          });
        } catch (error) {
          if (!active.current) {
            return;
          }
          snackbarShowMessage("Une erreur est survenue: " + error.message, "error", 4000)
        } finally {
          setLoading(false);
          setLoadingData(false);
        }
      })();
    }
  };

  const editorCustomProps = {
    value: message,
    setValue: setMessage,
    enableOnBlur: false
  };
  const isSmMode = useMediaQuery(theme.breakpoints.between(0, 480));

  const saveBarProps = {
    handleSubmit,
    editMode,
    handleEdit: handleUpdate,
    loading: loadingData,
  }
  return (
    <Box sx={{ padding: "0 20px" }}>
      <Box className="candidatedit_header">
        <Grid container spacing={1} direction={isSmMode ? "column-reverse" : ""} >
          <Grid item xs={12} sm={6} md={6} xl={6}>
            <h4 className="text-capitalize" style={{ color: "rgb(17, 122, 167)" }}> <Link
                data-toggle="tooltip"
                title="Retourner vers la page précedente"
                onClick={(e) => {
                  e.preventDefault();
                  history.goBack();
                }}
                className="d-print-none font-sm"
                style={{ textDecoration: "none", paddingRight: "20px" }}
            >
              <ArrowBack />
            </Link>{title}</h4>
          </Grid>
          <Grid item xs={12} sm={6} md={6} xl={6}>
            <SaveBar item {...saveBarProps} />
          </Grid>
        </Grid>
      </Box>
      {loadingData & editMode ? (
        <Loader />
      ) : (
        <Box className="candidatedit_container">
          <Card style={{ marginTop: "2em", padding: "2em" }}>

            <Grid container spacing={1}>
              <Grid item md={4} xs={12}>
                <label className="input-label" htmlFor="accountManager">
                  ACTIVITÉ<span className="required-input">*</span>
                </label>
                <FormControl variant="outlined" fullWidth margin="normal">
                  <InputLabel
                    {...(errors.activite && {
                      error: true,
                    })}
                    shrink
                    htmlFor="activite"
                  >
                    {errors.activite}
                  </InputLabel>
                  <Select
                    value={activite}
                    name="activite"
                    onChange={(e) => setActivite(e.target.value)}
                    {...(errors.activite && {
                      error: true,
                    })}
                    input={
                      <OutlinedInput
                        name="activite"
                        notched
                        {...(errors.activite && {
                          labelWidth: 260,
                        })}
                      ></OutlinedInput>
                    }
                  >
                    {activities.map((activity) => (
                      <MenuItem key={activity.toLowerCase()} value={activity.toLowerCase()}>
                        <em>{activity}</em>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <label className="input-label" htmlFor="TypeMsg">
                  TYPE DE MESSAGE<span className="required-input">*</span>
                </label>
                <FormControl variant="outlined" fullWidth margin="normal">
                  <InputLabel
                    {...(errors.TypeMsg && {
                      error: true,
                    })}
                    shrink
                    htmlFor="TypeMsg"
                  >
                    {errors.TypeMsg}
                  </InputLabel>
                  <Select
                    value={TypeMsg ? TypeMsg : ""}
                    name="TypeMsg"
                    onChange={(e) => setTypeMsg(e.target.value)}
                    {...(errors.TypeMsg && {
                      error: true,
                    })}
                    input={
                      <OutlinedInput
                        name="TypeMsg"
                        notched
                        {...(errors.TypeMsg && {
                          labelWidth: 260,
                        })}
                      ></OutlinedInput>
                    }
                  >
                    {TypeMsgs.map((TypeMsg) => (
                      <MenuItem key={TypeMsg.toLowerCase()} value={TypeMsg.toLowerCase()}>
                        <em>{TypeMsg}</em>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <label className="input-label" htmlFor="prenom">
                  INTITULÉ DU MESSAGE<span className="required-input">*</span>
                </label>
                <TextField
                  onChange={(e) => setMsgName(e.target.value)}
                  name="msgName"
                  value={msgName}
                  placeholder="INTITULÉ DU MESSAGE."
                  margin="normal"
                  variant="outlined"
                  autoComplete="off"
                  fullWidth
                  {...(errors.msgName && {
                    error: true,
                    label: errors.msgName,
                  })}
                />
              </Grid>
              <Grid item xs={12}>
                {TypeMsg != "sms" &&
                  <> <label className="input-label">OBJET DU MAIL</label>
                    <TextField
                      name="objet"
                      value={objet}
                      onChange={(e) => setObjet(e.target.value)}
                      margin="normal"
                      variant="outlined"
                      autoComplete="off"
                      fullWidth
                      placeholder="OBJET DU MAIL."
                      {...(errors.objet && {
                        error: true,
                        label: errors.objet,
                      })}
                    /></>
                }
                <label className="input-label">
                  MESSAGE<span className="required-input">*</span>
                </label>
                <EditorCustom {...editorCustomProps} />
                {errors.message && (
                  <Typography variant="body2" color="error">
                    {errors.message}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Card>
        </Box>
      )}
    </Box >
  );
};
export default withSnackbar(EmailTypeForm);
