import axios from "axios";

const BASE_URL = process.env.NODE_ENV === 'production' 
  ? `${window.location.origin}/api` 
  : `http://localhost:8080/api`;

const AUTH_ENDPOINTS = {
  refresh: '/api/token/refresh',
  login: '/api/authentification/user/login_check'
};

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

const handleAuthTokens = {
  get: () => ({
    token: localStorage.getItem("token"),
    refresh: localStorage.getItem("refresh_token")
  }),
  set: (tokens) => {
    localStorage.setItem("token", tokens.token);
    localStorage.setItem("refresh_token", tokens.refresh_token);
  },
  clear: () => {
    localStorage.clear();
    // Sauvegarder l'URL courante avant la redirection
    localStorage.setItem("redirectUrl", window.location.pathname + window.location.search);
  }
};

// Fonction de redirection sécurisée
const redirectToLogin = () => {
  handleAuthTokens.clear();
  // Utiliser le router pour la redirection
  if (window.location.pathname !== '/login') {
    window.location.replace('/login');
  }
};

axiosInstance.interceptors.request.use(
  (config) => {
    const { token } = handleAuthTokens.get();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { config: originalRequest, response } = error;

    // Gestion spécifique pour le endpoint de login
    if (originalRequest.url === AUTH_ENDPOINTS.login) {
      return Promise.reject(error);
    }

    // Tentative de refresh uniquement sur 401
    if (response?.status === 401) {
      const { refresh } = handleAuthTokens.get();
      
      if (!refresh) {
        redirectToLogin();
        return Promise.reject(error);
      }

      try {
        const { data } = await axiosInstance.post(AUTH_ENDPOINTS.refresh, { 
          refresh_token: refresh 
        });
        handleAuthTokens.set(data);
        originalRequest.headers.Authorization = `Bearer ${data.token}`;
        return axiosInstance(originalRequest);
      } catch {
        redirectToLogin();
        return Promise.reject(error);
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
