import { TextField, Checkbox } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

const CustomAutoComplete = ({
  listData,
  multiple = false,
  value,
  onChange,
  handleDataChange,
  name,
  errors,
  disableClearable,
  ...props
}) => {
  return (
    <>
      <label className="input-label">
        {listData.label} {listData.required && <span className="required-input">*</span>}
      </label>
      <Autocomplete
        multiple={multiple}
        value={value}
        onChange={(event, newValue) => {
            if (multiple) {
                const ids = Array.isArray(newValue) ? newValue.map((item) => item.id) : [];
                handleDataChange(newValue);
                if (onChange) {
                    onChange({
                        target: { name: name, value: ids },
                    });
                }
            } else {
                handleDataChange(newValue);
                if (onChange) {
                    onChange({
                        target: { name: name, value: newValue?.id },
                    });
                }
            }
        }}
        options={listData.data}
        loading={listData.isLoading}
        getOptionLabel={(option) =>
          `${option?.nom_societe !== undefined ? option?.nom_societe + " - " : ""} ${
            option.value !== undefined ? option.value : option.selectionName
          }`
        }
        isOptionEqualToValue={(option, value) => option.id === value.id}
        noOptionsText="Aucune donnée trouvée"
        renderOption={(props, option) => (
          <li {...props}>
            {multiple && (
              <Checkbox
                checked={props.selected}
                style={{ marginRight: 8 }}
              />
            )}
            {`${option?.nom_societe || ""}${option?.nom_societe ? " - " : ""}${
              option.value || option.selectionName
            }`}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            error={Boolean(errors && errors[name])}
            helperText={errors && errors[name]}
          />
        )}
        disableClearable={disableClearable}
        {...props}
      />
    </>
  );
};

export default CustomAutoComplete;
