import { Box } from '@mui/material';
import { useEffect, useRef, memo } from "react";

const LabelHtmlToTextCustom = ({ data, ...rest }) => {
  const box = useRef(null);

  useEffect(() => {
    const row = document.createElement("div");
    row.innerHTML = data;
    box.current.appendChild(row);
    return () => {
      while (box?.current?.firstChild) {
        box.current.removeChild(box?.current?.firstChild);
      }
    };
  }, [data]);

  return (
    <Box
      {...rest}
      ref={box}
      sx={{
        textJustify: "inter-word",
        transition: "all 1s",
      }}
    />
  );
};

export default memo(LabelHtmlToTextCustom);
