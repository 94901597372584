import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  useMediaQuery,
} from '@mui/material';
import moment from "moment";
import MUIDataTable from "mui-datatables";
import React, { useState } from "react";
import { addTacheCandidat, deleteTacheCandidat, editTacheCandidat, getAllMesTaches } from "../../Services/TacheCandidatService";
import qs from "query-string";
import { Delete } from '@mui/icons-material';
import { Link } from "react-router-dom";
import FormTacheModal from "../../../components/FormTacheModal/FormTacheModal";
import LabelHtmlToTextCustom from "../../../components/LabelHtmlToTextCustom/LabelHtmlToTextCustom";
import { withSnackbar } from "../../../utils/SnackbarHOC";
import { handleOnViewColumnsChange, displayColumn } from "../../../utils/viewColumns";
import DialogDeleteCustom from "../../../components/DialogDeleteCustom/DialogDeleteCustom";
import {getRowData} from "../../../utils/FunctionHelpers";

function Taches({ snackbarShowMessage, searchParams, reloadingData, setReloadingData }) {
  const [loading, setLoading] = useState(false);
  const [tachesCandidat, setTachesCandidat] = useState([]);
  const [tacheCandidatInfo, setTacheCandidatInfo] = useState({ affectePar: "", tacheId: "" });
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [loadingToDelete, setLoadingToDelete] = useState(false);
  const props = {
    reloadingData,
    setReloadingData,
    editTache: editTacheCandidat, addTache: addTacheCandidat
  };
  const [tempTachesCandidat, setTempTachesCandidat] = useState([]);
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const isSmall = useMediaQuery((theme) => theme.breakpoints.between(0, 767));
  const isMediumMode = useMediaQuery((theme) => theme.breakpoints.between(767, 1023));
  const isLargeMode = useMediaQuery((theme) => theme.breakpoints.between(1023, 2800));
  const tableBodyMaxHeight = `${isSmall ? viewportHeight - 260 : isMediumMode ? viewportHeight - 440 : isLargeMode ? viewportHeight - 420 : viewportHeight - 420}px`;

  React.useEffect(() => {
    const handleResize = () => {
      setViewportHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleOpenDialog = (params, columns) => {
    const rowData = getRowData(params, columns);
    setTacheCandidatInfo({ affectePar: rowData.affectePar, tacheId: rowData.id });
    setOpenDialogDelete(true);
  };

  const handleCloseDialog = () => {
    if (loadingToDelete) {
      return;
    }
    setOpenDialogDelete(false);
  };

  const handleDeleteTask = () => {
    (async () => {
      setLoadingToDelete(true);
      try {
        snackbarShowMessage("Patientez svp!", "warning", null);
        const res = await deleteTacheCandidat(tacheCandidatInfo.tacheId);
        handleCloseDialog();
        setReloadingData(!reloadingData);
        snackbarShowMessage("Tâche de candidat bien supprimée!", "success", 4000);
      } catch (error) {
        snackbarShowMessage("Erreur de suppression de la données,veuillez réssayer plutard.", "error", 4000);
      } finally {
        setLoadingToDelete(false);
      }
    })();
  };

  React.useEffect(() => {
    let active = true;
    (async () => {
      setLoading(true);
      setTachesCandidat([]);
      try {
        const newTachesCandidat = await getAllMesTaches(qs.stringify(searchParams));
        if (!active) {
          return;
        }
        setTachesCandidat(newTachesCandidat.data.TachesCandidat);
        if (tempTachesCandidat.length > 0) {
          setTempTachesCandidat([...tachesCandidat]);
        }
      } catch (error) {
        if (!active) {
          return;
        }
        snackbarShowMessage("Erreur de chargement des tâches candidat.", "error", 4000);
      } finally {
        setLoading(false);
      }
    })();

    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadingData]);

  const tableName = "candidatTasksColumnVisibility";

  const columns = [
    {
      name: "id",
      label: "N°TÂCHE",
      options: {
        display: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Box Box data-toggle="tooltip" title={value} style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", width: "100px" }}>{value}</Box>
          );
        },
      },
    },
    {
      name: "affecteANom",
      label: "AFFECTÉE A",
      options: {
        display: displayColumn("affecteA", tableName),
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Box data-toggle="tooltip" title={value} style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", width: "100px" }}>{value}</Box>
          );
        },
      },
    },
    {
      name: "statut",
      label: "STATUT",

      options: {
        display: displayColumn("statut", tableName),
        filter: true,
        sort: false,
      },
    },
    {
      name: "createdAt",
      label: "CREE LE",
      options: {
        display: displayColumn("createdAt", tableName),
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({ style: { whiteSpace: "nowrap" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(new Date(value)).format("DD/MM/YYYY")
        },
      },
    },
    {
      name: "dateTime",
      label: "À FAIRE LE",
      options: {
        display: displayColumn("dateTime", tableName),
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({ style: { whiteSpace: "nowrap" } }),
        customBodyRender: (value, tableMeta, updateValue) => moment(new Date(value)).format("DD/MM/YYYY")
      }
    },
    {
      name: "candidat",
      label: "CANDIDAT",
      options: {
        display: displayColumn("candidat", tableName),
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowData = getRowData(tableMeta, columns)
          return (<Box data-toggle="tooltip" title={value} style={{ justifyContent: "center" }}>
            <Link to={{ pathname: `/candidats/${rowData.condidatId}/view` }}>{value}</Link>
          </Box>)
        },
      },
    },
    {
      name: "fonction",
      label: "FONCTION",
      options: {
        display: displayColumn("fonction", tableName),
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Box data-toggle="tooltip" title={value} style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", width: "200px" }}>
              {value !== "" ? value : "---"}</Box>
          );
        },
      },
    },
    {
      name: "societe",
      label: "SOCIETE",
      options: {
        display: displayColumn("societe", tableName),
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowData = getRowData(tableMeta, columns);
          return (
            <Box data-toggle="tooltip" title={value} style={{ justifyContent: "center" }}>
              <Link to={{ pathname: `/societe/${rowData.societeUuid}/view` }}>{value}</Link>
            </Box>
          );
        },
      },
    },
    {
      name: "affectePar",
      label: "AFFECTÉE PAR",
      options: {
        display: displayColumn("affectePar", tableName),
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({ style: { justifyContent: "center", whiteSpace: "nowrap" } })
      },
      customBodyRender: (value) => {
        return (
          <Box data-toggle="tooltip" title={value}>
            {value !== "" ? value : "---"}
          </Box>
        );
      }
    },

    {
      name: "commentaire",
      label: "NOTE CONTACT",
      options: {
        display: displayColumn("commentaire", tableName),
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({ style: { justifyContent: "center", whiteSpace: "nowrap" } }),
        customBodyRender: (value, tableMeta, updateValue) => <LabelHtmlToTextCustom data-toggle="tooltip" title={value?.replace(/<\/?[^>]+(>|$)/g, "")} data={value} />
      },
    },
    {
      name: "actions",
      label: "ACTIONS",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowData = getRowData(tableMeta, columns)
          return (
            <Grid container>
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <FormTacheModal
                  {...{ ...props, id: rowData.uuid }}
                  editMode={true}
                  oldAffecteA={rowData.affecteA}
                  oldStatut={rowData.status}
                  oldCommentaire={rowData.commentaire}
                  oldDateTime={rowData.dateTime.date}
                  tacheId={rowData.id}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <IconButton data-toggle="tooltip"
                  title="Supprimer" onClick={() => handleOpenDialog(tableMeta, columns)} style={{ color: "#EF6262" }}>
                  <Delete />
                </IconButton>
              </Grid>
            </Grid>
          );
        },
      },
    },
    {
      name: "societeUuid",
      label: "Id SOCIETE",
      options: {
        display: false,
        filter: true,
        sort: false,
        setCellProps: () => ({ style: { whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", width: "100px" } }),
        setCellHeaderProps: () => ({ style: { textAlign: "center", maxWidth: "100px" } }),
      },
    },
    {
      name: "affecteA",
      label: "Affecte A Id",
      options: {
        display: false,
        filter: true,
        sort: false,
        setCellProps: () => ({ style: { whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", width: "100px" } }),
        setCellHeaderProps: () => ({ style: { textAlign: "center", maxWidth: "100px" } }),
      },
    },
    {
      name: "condidatId",
      label: "Id CANDIDAT",
      options: {
        display: false,
        filter: true,
        sort: false,
        setCellProps: () => ({ style: { whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", width: "100px" } }),
        setCellHeaderProps: () => ({ style: { textAlign: "center", maxWidth: "100px" } }),
      },
    },
  ];

  const gridData = tachesCandidat.map((row) => {
    const updatedRow = {
      ...row,
      createdAt: moment(row.createdAt.date, "YYYY-MM-DD").toDate().getTime(),
      dateTime: moment(row.dateTime.date, "YYYY-MM-DD").toDate().getTime()
    };
    return updatedRow;
  });

  const options = {
    tableBodyMaxHeight: tableBodyMaxHeight,
    sort: true,
    rowsPerPage: 15,
    fixedHeaderOptions: {
      xAxis: false,
      yAxis: true
    },
    textLabels: {
      body: {
        noMatch: loading ? <CircularProgress /> : "Désolé, il n'y a aucun enregistrement dans la base de données pour le moment.",
      },
    },
    onViewColumnsChange: (columnName, action) => {
      handleOnViewColumnsChange(columnName, action, tableName);
    },
    selectableRows: "none",
    draggableColumns: {
      enabled: true,
    },
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    rowsPerPageOptions: [10, 15, 100],
  };

  const dialogDeleteProps = {
    handleClose: handleCloseDialog,
    open: openDialogDelete,
    handleConfirm: handleDeleteTask,
    loading: loadingToDelete,
    label: `Voulez vous vraiment supprimer la tâche de ${tacheCandidatInfo.affectePar}?`,
    title: "Supprimer la tâche",
  };

  return (
    <>
      <DialogDeleteCustom {...dialogDeleteProps} />
      <MUIDataTable data={gridData} columns={columns} options={options} />
    </>
  );
}

export default withSnackbar(Taches)
