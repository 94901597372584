import { Box } from '@mui/material';
import { useEffect, useRef, useState } from "react";
import BtnVoirPlusOuMoins from "../BtnVoirPlusOuMoins/BtnVoirPlusOuMoins";
import { Collapse } from '@mui/material';

const LabelReadMoreReadLess = ({ data, enableCollapse = true }) => {
  const box = useRef(null);
  const [showButton, setShowButton] = useState(false);
  const [isReadMoreShown, setReadMoreShown] = useState(false);
  const [totalLine, setTotalLine] = useState(0);

  useEffect(() => {
    const row = document.createElement("div");
    row.innerHTML = data;
    box.current.appendChild(row);
  }, [data]);

  useEffect(() => {
    const row = box.current.firstChild;
    if (row) {
      const rowHeight = row.offsetHeight;
      const lineHeight = parseInt(window.getComputedStyle(row).lineHeight);
      const lineCount = Math.round(rowHeight / lineHeight);
      setTotalLine(lineCount);
      lineCount > 4 ? setShowButton(true) : setShowButton(false);
    }
  }, [data]);

  return (
    <Box
      sx={{
        overflowY: "auto",
        scrollBehavior: "smooth",
        position: "relative",
        maxHeight: "300px",
        width: "100%",
      }}
    >
      {enableCollapse ? (
        <Collapse
          in={isReadMoreShown}
          collapsedSize={totalLine < 3 ? 40 : 85}
          style={{ padding: 0, boxShadow: "none", position: "relative" }}
        >
          <Box
            ref={box}
            sx={{
              textJustify: "inter-word",
              transition: "all 1s",
            }}
          />
          {!isReadMoreShown && showButton && (
            <Box
              sx={{
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                height: "20px",
                background:
                  "linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1))",
              }}
            />
          )}
        </Collapse>
      ) : (
        <Box
          ref={box}
          sx={{
            textJustify: "inter-word",
            transition: "all 1s",
          }}
        />
      )}
      {showButton && enableCollapse && (
        <BtnVoirPlusOuMoins
          action={() => setReadMoreShown((prevState) => !prevState)}
          readMore={isReadMoreShown}
        />
      )}
    </Box>
  );
};

export default LabelReadMoreReadLess;
