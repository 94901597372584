import React from "react";
import { Card, Grid, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import { v4 } from "uuid";


function ProfileRecherche({ missionData }) {
  return (
    <Grid item={true} xs={12}>
      <Card>
        <TableContainer>
          <Table className="table table-striped">
            <TableBody component="tbody">
              {[
                {
                  title: "Expérience",
                  data: missionData.experience,
                },
                {
                  title: "Formation",
                  data: missionData.formation,
                },
                {
                  title: "Compétences Sectorielles",
                  data: (missionData.secteur || []).map((sec, index, arr) => {
                    return index === arr.length - 1 ? `${sec}` : `${sec} - `;
                  })
                },
                {
                  title: "Compétences Métiers",
                  data: (missionData.metier || []).map((met, index, arr) => {
                    return index === arr.length - 1 ? `${met}` : `${met} - `;
                  })
                },
                {
                  title: "Compétences Techniques",
                  data: missionData.competence_tech,
                },
                {
                  title: "Niveau d'Anglais",
                  data: missionData.niveau,
                },
              ].map((section, index) => (
                <TableRow key={v4()}>
                  <TableCell scope="row">
                    <strong>{section.title}</strong>
                  </TableCell>
                  <TableCell style={{ width: "80%" }} align="left">{Array.isArray(section.data)
                    ? section.data?.map((item) => (item + "/ ").slice(0, -2))
                    : section.data ?? "-"}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </Grid>

  );
}



export default ProfileRecherche;
