import {
  Box,
  Button,
  CircularProgress,
  IconButton,
} from '@mui/material';
import React, { useState } from "react";
import { Delete } from '@mui/icons-material';
import FormNoteModal from "../../../../components/FormNoteModal/FormNoteModal";
import MUIDataTable from "mui-datatables";
import { getAll, deleteNoteContact, downloadNoteFile, addNoteContact, editNoteContact } from "../../../Services/NoteContact";
import moment from "moment";
import { useParams } from "react-router-dom";
import { GrDocumentDownload } from "react-icons/gr";
import LabelReadMoreReadLess from "../../../../components/LabelReadMoreReadLess/LabelReadMoreReadLess";
import EditorCustom from "../../../../components/EditorCustorm/EditorCustom";
import { removeLineBreak } from "../../../../utils/EditorUtil";
import { getUserData } from "../../../../utils/FunctionHelpers";
import { withSnackbar } from "../../../../utils/SnackbarHOC";
import DialogDeleteCustom from "../../../../components/DialogDeleteCustom/DialogDeleteCustom";


function Notes({ snackbarShowMessage, ...rest }) {
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [notesContact, setNotesContact] = useState([]);
  const [noteContactInfo, setNoteContactInfo] = useState({ auteur: "", id: "" });
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [loadingToDelete, setLoadingToDelete] = useState(false);
  const [reloadingData, setReloadingData] = useState(false);
  const [downloadingFile] = useState(false);
  const [textEdit, setTextEdit] = useState(true);
  const [comment, setComment] = useState("");
  const [pj, setPj] = useState(null);
  const [errors, setErrors] = useState({});
  const [modalState, setModalState] = useState({ open: false, ecole: "" });
  const [idNoteSeleted, setIdNoteSeleted] = useState(0);
  const parametters = useParams();
  const idContact = rest.uuid ?? parametters.idContact;

  const handlePageChange = (page) => {
    setPage(page + 1);
  };

  const handleOpenDialog = (params) => {
    setNoteContactInfo({ auteur: params.rowData[3], id: params.rowData[0] });
    setOpenDialogDelete(true);
  };

  const handleCloseDialog = () => {
    if (loadingToDelete) {
      return;
    }
    setOpenDialogDelete(false);
  };

  const handleDownload = (noteId, pj) => {
    const FileDownload = require("js-file-download");
    (async () => {
      setLoading(true);
      try {
        let response = await downloadNoteFile(noteId, pj);
        FileDownload(response.data, pj);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    })();
  };

  const handleDeleteNote = () => {
    (async () => {
      setLoadingToDelete(true);
      try {
        snackbarShowMessage("Patientez svp!", "warning", null)
        const res = await deleteNoteContact(noteContactInfo.id);
        snackbarShowMessage(res.data)
        setLoadingToDelete(false);
        handleCloseDialog();
        setReloadingData(!reloadingData);
      } catch (error) {
        snackbarShowMessage("Erreur de suppression de la données,veuillez réssayer plutard.", "error", 4000)
        setLoadingToDelete(false);
      }
    })();
  };

  React.useEffect(() => {
    let active = true;

    (async () => {
      setLoading(true);

      setNotesContact([]);
      let newNotesContact = await getAll(page, idContact);
      if (!active) {
        return;
      }
      setNotesContact(newNotesContact.data.notesContact);
      setCount(newNotesContact.data.count);
      setLoading(false);
    })();

    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, reloadingData, idContact]);

  const validate = (comment) => {
    let temp = {};
    temp.commentaire = !(comment?.replace(/<\/?[^>]+(>|$)/g, "") === "") ? "" : "Le champ 'COMMENTAIRE' est obligatoire.";
    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleOpen = () => {
    setModalState({ open: true });
  };
  const handleClose = () => {
    if (loading) {
      return;
    }
    setModalState({ open: false });
    setComment("")
    setErrors("")
  };

  const handleSubmit = (comment) => {
    if (validate(comment)) {
      (async () => {
        setLoading(true);
        const formData = new FormData();
        formData.append("dateTime", new Date());
        formData.append("commentaire", removeLineBreak(comment));
        formData.append("auteur", getUserData().uuid);
        formData.append("contact", idContact);
        formData.append("pj", pj);

        try {
          snackbarShowMessage("Patientez svp!", "warning", null)
          const res = await addNoteContact(formData);
          snackbarShowMessage(res.data)
          setLoading(false);
          setReloadingData(!reloadingData);
          handleClose();
        } catch (e) {
          snackbarShowMessage("Erreur d'ajout de note: " + e.message, "error", 4000)
          setLoading(false);
          handleClose();
        }
      })();
    }
  };

  const handleUpdate = (comment) => {
    if (validate(comment)) {
      (async () => {
        setLoading(true);
        const formData = new FormData();
        formData.append("commentaire", removeLineBreak(comment));
        formData.append("pj", pj);
        try {
          snackbarShowMessage("Patientez svp!", "warning", null)
          const response = await editNoteContact(formData, idNoteSeleted);
          snackbarShowMessage(response.data)
          setLoading(false);
          setReloadingData(!reloadingData);
          setTextEdit(true);
          handleClose();
        } catch (e) {
          snackbarShowMessage("Une erreur est survenue. veuillez réessayer plus tard.", "error", 4000)
          setLoading(false);;
          handleClose();
        }
      })();
    }
  };

  const editorCustomProps = {
    value: comment,
    setValue: setComment,
    handleSubmit: handleUpdate,
    enableOnBlur: true
  }

  const handleRowClick = (comment, id) => {
    setComment(comment);
    setIdNoteSeleted(id);
    setTextEdit(false);
  }

  const columns = [
    {
      name: "id",
      label: "N°NOTE",

      options: {
        display: false,
        filter: true,
        sort: false,
        setCellProps: () => ({ style: { whiteSpace: "nowrap", padding: "0px 12px", textOverflow: "ellipsis", overflow: "hidden", width: "100px" } }),
        setCellHeaderProps: () => ({ style: { textAlign: "center", maxWidth: "100px", padding: "2px 12px" } }),
      },
    },
    {
      name: "dateTime",
      label: "DATE/HEURE",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({ style: { width: '100px', padding: "2px 12px" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{moment(value.date).format("DD/MM/yyyy HH:mm:ss")}</>;
        },
      },
    },
    {
      name: "commentaire",
      label: "COMMENTAIRES",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({ style: { textAlign: "left", width: '60%', padding: "2px 12px" } }),
        setCellProps: () => ({ style: { padding: "0px 12px" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Box sx={{ cursor: "hand" }}>
              {textEdit ? (
                <span onDoubleClick={() => handleRowClick(value, tableMeta.rowData[0])}>
                  <LabelReadMoreReadLess data={value} />
                </span>
              ) : tableMeta.rowData[0] === idNoteSeleted ? (
                <EditorCustom {...editorCustomProps} />
              ) : (
                <LabelReadMoreReadLess data={value} />)
              }
            </Box >
          );
        },
      },
    },
    {
      name: "auteur",
      label: "AUTEUR",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({ style: { maxWidth: '100px', padding: "2px 12px" } }),
        setCellProps: () => ({ style: { padding: "0px 12px" } }),
      }
    },

    {
      name: "pj",
      label: "P.J",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({ style: { maxWidth: '50px', textAlign: "center", padding: "2px 12px" } }),
        setCellProps: () => ({ style: { padding: "0px 12px" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Box style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", width: "100px", textAlign: "center" }}>
              {value && (
                downloadingFile ? (
                  <CircularProgress key={`pj ${tableMeta.rowData[0]}`} size={10} />
                ) : (
                  <Button key={`pj ${tableMeta.rowData[0]}`} onClick={() => handleDownload(tableMeta.rowData[0], tableMeta.rowData[4])}>
                    <GrDocumentDownload style={{ fontSize: "20px" }} />
                  </Button>
                )
              )}
            </Box>
          );
        },
      },
    },
    {
      name: "action",
      label: "ACTION",
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({ style: { maxWidth: '50px', padding: "2px 12px" } }),
        setCellProps: () => ({ style: { padding: "0px 12px" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <IconButton data-toggle="tooltip"
              title="Supprimer" aria-label="Supprimer" onClick={() => handleOpenDialog(tableMeta)} style={{ color: "#EF6262" }}>
              <Delete />
            </IconButton>
          );
        },
      },
    },
  ];
  const options = {
    tableBodyMaxHeight: "44vh",
    serverSide: true,
    count: count,
    textLabels: {
      body: {
        noMatch: loading ? <CircularProgress /> : "Désolé, il n'y a aucun enregistrement dans la base de données pour le moment.",
      },
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case "changePage":
          handlePageChange(tableState.page);
          break;
        default:
      }
    },
    selectableRows: "none",
    draggableColumns: {
      enabled: true,
    },
    filterType: "dropdown",
    filter: false,
    print: false,
    columns: false,
    responsive: "standard",
    search: false,
    download: false,
    viewColumns: false,
    rowsPerPage: 50,
  };

  const formNoteProps = { errors, setPj, loading, handleSubmit, modalState: modalState.open, handleOpen, handleClose }

  const dialogDeleteProps = {
    handleClose: handleCloseDialog,
    handleOpen: openDialogDelete,
    handleDelete: handleDeleteNote,
    loading: loadingToDelete,
    label: ` Voulez vous vraiment supprimer la note de ${noteContactInfo?.auteur}?`
  }

  return (
    <>
      <DialogDeleteCustom {...dialogDeleteProps} />
      <FormNoteModal {...formNoteProps} />
      <MUIDataTable data={notesContact} columns={columns} options={options} />
    </>
  );
}

export default withSnackbar(Notes);