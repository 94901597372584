import React, { useState, useEffect, useCallback } from 'react';
import {
  Popover,
  Box,
  Typography,
  TextField,
  Checkbox,
  Button,
  IconButton,
  InputAdornment,
  CircularProgress,
  Divider
} from '@mui/material';
import {
  Close as CloseIcon,
  Search as SearchIcon,
  Edit as EditIcon,
  Add as AddIcon
} from '@mui/icons-material';
import { getAllCadidatSelections, getAllCandidatSelections, addremovecandidattoselection } from '../../Services/SelectionCandidatService';

const SelectionsPopover = ({ 
  anchorEl, 
  onClose, 
  candidatId,
  onSelectionChange 
}) => {
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [allSelections, setAllSelections] = useState([]);
  const [selectedSelections, setSelectedSelections] = useState([]);
  const [pendingChanges, setPendingChanges] = useState([]);

  const loadData = useCallback(async () => {
    setLoading(true);
    try {
      const [allSelectionsRes, candidatSelectionsRes] = await Promise.all([
        getAllCadidatSelections('?archived=false'),
        getAllCandidatSelections(candidatId)
      ]);

      setAllSelections(allSelectionsRes.data.candidatsSelections);
      const currentSelections = candidatSelectionsRes.data.selectionsCandidat;
      setSelectedSelections(currentSelections);
      setPendingChanges(currentSelections.map(s => s.id));
    } catch (err) {
      console.error('Error loading selections:', err);
    } finally {
      setLoading(false);
    }
  }, [candidatId]);

  useEffect(() => {
    if (anchorEl) {
      loadData();
    }
  }, [anchorEl, loadData]);

  const handleClose = (event) => {
    event?.stopPropagation();
    onClose();
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleToggleSelection = (selectionId) => {
    setPendingChanges(prev => {
      const isSelected = prev.includes(selectionId);
      return isSelected 
        ? prev.filter(id => id !== selectionId)
        : [...prev, selectionId];
    });
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      await addremovecandidattoselection(
        { selectedSelections: pendingChanges },
        candidatId
      );
      onSelectionChange && onSelectionChange();
      handleClose();
    } catch (error) {
      console.error('Error updating selections:', error);
    } finally {
      setLoading(false);
    }
  };

  const filteredSelections = allSelections.filter(selection =>
    selection.selectionName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClick={e => e.stopPropagation()}
      PaperProps={{
        sx: {
          width: 300,
          p: 2,
          '& .MuiPopover-paper': {
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.15)'
          }
        }
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="subtitle1">Sélections</Typography>
        <IconButton size="small" onClick={handleClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>

      <TextField
        fullWidth
        size="small"
        placeholder="Parcourir les séléctions..."
        value={searchTerm}
        onChange={handleSearchChange}
        sx={{ mb: 2 }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon fontSize="small" />
            </InputAdornment>
          ),
        }}
      />

      <Box sx={{ mb: 2 }}>
        <Typography variant="subtitle2" sx={{ mb: 1 }}>Étiquettes</Typography>
        <Box sx={{ maxHeight: 300, overflowY: 'auto' }}>
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', py: 2 }}>
              <CircularProgress size={24} />
            </Box>
          ) : (
            filteredSelections.map((selection) => (
              <Box
                key={selection.id}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mb: 1,
                  '&:hover': {
                    '& .edit-button': {
                      opacity: 1,
                    },
                  },
                }}
              >
                <Checkbox
                  checked={pendingChanges.includes(selection.id)}
                  onChange={() => handleToggleSelection(selection.id)}
                  size="small"
                />
                <Box
                  sx={{
                    flex: 1,
                    backgroundColor: 'primary.main',
                    color: 'white',
                    borderRadius: 1,
                    p: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography variant="body2">
                    {selection.selectionName}
                  </Typography>
                </Box>
              </Box>
            ))
          )}
        </Box>
      </Box>

      <Divider sx={{ my: 2 }} />

      <Box sx={{ display: 'flex', gap: 1 }}>
        <Button
          fullWidth
          variant="contained"
          onClick={handleSave}
          disabled={loading}
        >
          Enregistrer
        </Button>
        <Button
          fullWidth
          variant="outlined"
          onClick={handleClose}
          disabled={loading}
        >
          Annuler
        </Button>
      </Box>
    </Popover>
  );
};

export default React.memo(SelectionsPopover);
