import { Box } from '@mui/material';

export default function Loader() {
  return (
    <Box sx={{ width: "100%", height: "100%", paddingTop: "15%" }}>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          gap: "15px",
          alignItems: "center",
        }}
      >
        <svg
          version="1.1"
          id="L6"
          x="0px"
          y="0px"
          viewBox="0 0 100 100"
          enableBackground="new 0 0 100 100"
          style={{ width: "100px", height: "100px" }}
        >
          <rect
            fill="none"
            stroke="rgb(17, 122, 167)"
            strokeWidth="4"
            x="25"
            y="25"
            width="50"
            height="50"
          >
            <animateTransform
              attributeName="transform"
              dur="0.5s"
              from="0 50 50"
              to="180 50 50"
              type="rotate"
              id="strokeBox"
              attributeType="XML"
              begin="rectBox.end"
            />
          </rect>
          <rect x="27" y="27" fill="rgb(17, 122, 167)" width="46" height="50">
            <animate
              attributeName="height"
              dur="1.3s"
              attributeType="XML"
              from="50"
              to="0"
              id="rectBox"
              fill="freeze"
              begin="0s;strokeBox.end"
            />
          </rect>
        </svg>

        <h5>Patientez svp...</h5>
      </Box>
    </Box>
  );
}
