import DatePickerWrapper from '../../components/DatePicker/DatePickerWrapper';
import Modal from '@mui/material/Modal';
import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Fade,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from '@mui/material';
import { getAllUsersForTasks } from "../../pages/Services/UserService";
import { Edit } from '@mui/icons-material';
import { getUserData } from "../../utils/FunctionHelpers";
import { withSnackbar } from "../../utils/SnackbarHOC";
import EditorCustom from "../EditorCustorm/EditorCustom";
import { removeLineBreak } from "../../utils/EditorUtil";
import LoadingButtonCustom from "../LoadingButtonCustom/LoadingButtonCustom";
import {makeStyles} from "@mui/styles";

function getModalStyle() {
  const top = 0;
  const left = 0;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "20px",
    width: "450px",
  },
  saveButton: {
    backgroundColor: "rgb(66, 186, 150)",
    color: "#FFF",
    fontWeight: 500,
    "&:hover, &:focus": {
      backgroundColor: "#389e7f",
      borderColor: "#359478",
    },
  },
  plus: {
    fontWeight: 500,
    marginBottom: "0.2em",
  },
  closeButton: {
    color: "#1b2a4e",
    backgroundColor: "#d9e2ef",
    borderColor: "#d9e2ef",
    marginRight: "0.5em",
    fontWeight: "500",
  },
  modalTitle: {
    color: "rgb(17 122 167)",
  },
}));

const FormTache = ({
  reloadingData,
  setReloadingData,
  oldAffecteA,
  oldStatut,
  oldCommentaire,
  oldDateTime,
  editMode,
  tacheId,
  snackbarShowMessage,
  editTache,
  addTache,
  id,
}) => {
  const classes = useStyles();
  const [modalState, setModalState] = useState({ open: false, ecole: "" });
  const [affecteA, setAffecteA] = useState(
    oldAffecteA ? oldAffecteA : getUserData().uuid
  );
  const [statut, setStatut] = useState(oldStatut ? oldStatut : false);
  const [commentaire, setCommentaire] = useState(
    oldCommentaire !== "" ? oldCommentaire : ""
  );
  const [dateTime, setDateTime] = useState(
    oldDateTime ? oldDateTime : new Date()
  );
  const [modalStyle] = useState(getModalStyle);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [users, setUsers] = useState([]);
  const loaded = useRef(true);
  useEffect(() => {
    if (!editMode) {
      setCommentaire("");
    }
  }, [editMode]);

  const validate = () => {
    let temp = {};
    temp.affecteA = affecteA ? "" : "Le champ 'AFFECTÉE À' est obligatoire.";
    temp.commentaire = !(commentaire?.replace(/<\/?[^>]+(>|$)/g, "") === "")
      ? ""
      : "Le champ 'COMMENTAIRE' est obligatoire.";
    temp.dateTime = dateTime ? "" : "Le champ 'DATE/TIME' est obligatoire.";

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleOpen = () => {
    setModalState({ open: true });
    setCommentaire(oldCommentaire);
  };

  React.useEffect(() => {
    let active = true;
    if (modalState.open) {
      (async () => {
        setLoadingUsers(true);
        try {
          const users = await getAllUsersForTasks();
          if (!active) {
            return;
          }
          setUsers(users.data);
          setLoadingUsers(false);
        } catch (error) {
          if (!active) {
            return;
          }
          snackbarShowMessage(
            "Erreur de chargement des utilisateurs.",
            "error",
            4000
          );
          setLoadingUsers(false);
        }
      })();
    }

    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalState.open]);

  const handleClose = () => {
    if (loading) {
      return;
    }
    setModalState({ open: false });
    setCommentaire("");
  };

  const handleSubmit = () => {
    if (validate()) {
      (async () => {
        setLoading(true);
        const tache = {
          dateTime: dateTime,
          affectePar: getUserData().uuid,
          affecteA: affecteA,
          statut: statut,
          commentaire: removeLineBreak(commentaire),
          contact: id,
        };
        try {
          snackbarShowMessage("Patientez svp!", "warning", null);
          await addTache(id, tache);
          snackbarShowMessage("Tâche bien ajoutée!");
          setReloadingData(!reloadingData);
        } catch (e) {
          snackbarShowMessage("Une erreur est survenue.", "error", 4000);
        } finally {
          setLoading(false);
          handleClose();
        }
      })();
    }
  };

  const handleUpdate = () => {
    if (validate()) {
      (async () => {
        setLoading(true);
        const tachePayload = {
          affecteA: affecteA,
          statut: statut,
          commentaire: removeLineBreak(commentaire),
          dateTime: dateTime,
        };
        try {
          snackbarShowMessage("Patientez svp!", "warning", null);
          await editTache(tachePayload, tacheId);
          snackbarShowMessage("La tâche a été mise à jour !");
          if (loaded.current) {
            setReloadingData(!reloadingData);
            setLoading(false);
            handleClose();
          }
        } catch (e) {
          if (loaded.current) {
            snackbarShowMessage("une erreur est survenue.", "error", 4000);
            setLoading(false);
            handleClose();
          }
        }
      })();
    }
  };

  const editorCustomProps = {
    value: commentaire,
    setValue: setCommentaire,
    handleSubmit,
    enableOnBlur: false,
  };

  const loadingButtonProps = {
    handleSubmit: handleSubmit,
    handleUpdate: handleUpdate,
    loading,
    editMode: editMode,
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <div className="modal-header">
        <h4 className={classes.modalTitle}>
          {editMode ? "Modifier une tâche" : "Ajouter une tâche"}
        </h4>
      </div>
      <div className="modal-body">
        <label className="input-label" htmlFor="id">
          AFFECTÉE À <span className="required-input">*</span>
        </label>
        {loadingUsers ? (
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : (
          <FormControl variant="outlined" fullWidth margin="normal">
            <InputLabel
              {...(errors.affecteA && {
                error: true,
              })}
              shrink
            >
              {errors.affecteA}
            </InputLabel>
            <Select
              defaultValue=""
              value={affecteA}
              name="users"
              onChange={(e) => setAffecteA(e.target.value)}
              {...(errors.affecteA && {
                error: true,
              })}
              input={
                <OutlinedInput
                  name="users"
                  {...(errors.affecteA && {
                    labelWidth: 280,
                  })}
                  notched
                ></OutlinedInput>
              }
            >
              {loadingUsers ? (
                <CircularProgress />
              ) : (
                users.sort().map((user) => (
                  <MenuItem value={user.uuid} key={user.uuid}>
                    {user.prenom} {user.nom.toUpperCase()}
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>
        )}
        <div>
          <FormControl component="fieldset">
            <label className="input-label" htmlFor="statut">
              STATUT <span className="required-input">*</span>
            </label>
            <RadioGroup
              row
              name="statut1"
              value={statut}
              onChange={(e) => {
                setStatut(e.target.value === "true" ? true : false);
              }}
            >
              <FormControlLabel
                value={true}
                control={<Radio color="primary" />}
                label="FAIT"
              />
              <FormControlLabel
                value={false}
                control={<Radio color="primary" />}
                label="À FAIRE"
              />
            </RadioGroup>
          </FormControl>
        </div>
        <label className="input-label" htmlFor="commentaire">
          COMMENTAIRE <span className="required-input">*</span>
        </label>
        <EditorCustom id="note" {...editorCustomProps} />
        {errors.commentaire && (
          <Typography variant="body2" color="error">
            {errors.commentaire}
          </Typography>
        )}
        <label className="input-label" htmlFor="id">
          DATE/HEURE <span className="required-input">*</span>
        </label>
        <DatePickerWrapper
          style={{ marginTop: "0px" }}
          invalidDateMessage=""
          autoOk={true}
          disableToolbar
          variant="inline"
          views={["year", "month", "date"]}
          format="dd/MM/yyyy HH:mm"
          inputVariant="outlined"
          margin="normal"
          value={dateTime}
          fullWidth
          onChange={(value) => {
            setDateTime(value);
          }}
        />
      </div>
      <div className="modal-footer">
        <Button
          className={classes.closeButton}
          onClick={handleClose}
          disabled={loading}
        >
          Annuler
        </Button>
        <LoadingButtonCustom {...loadingButtonProps} />
      </div>
    </div>
  );

  return (
    <>
      {editMode ? (
        <IconButton
          style={{ color: "#C8D148" }}
          onClick={handleOpen}
          data-toggle="tooltip"
          title="Modifier"
        >
          <Edit />
        </IconButton>
      ) : (
        <Button
          onClick={handleOpen}
          style={{
            backgroundColor: "rgb(17 122 167)",
            color: "#fff",
            marginBottom: "1em",
          }}
          variant="contained"
          size="small"
        >
          AJOUTER
        </Button>
      )}

      <Modal
        className={classes.modal}
        open={modalState.open}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={modalState.open}>{body}</Fade>
      </Modal>
    </>
  );
};

export default withSnackbar(FormTache);
