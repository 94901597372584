import React from "react";
import { saveAs } from "file-saver";
import {Box, Button} from '@mui/material';
import {blue} from '@mui/material/colors';

const DownloadButton = ({ fileUrl, fileName }) => {
    const handleDownload = () => {
        saveAs(fileUrl, fileName);
    };

    return (
        <Box textAlign='center' paddingTop={"20px"}>
            <Button
                variant={"contained"}
                color={"primary"}
                style={{textAlign: "center"}}
                onClick={handleDownload}>
                Telecharger CV
            </Button>
        </Box>
    );
};

export default DownloadButton;
