import MenuIcon from '@mui/icons-material/Menu';
import {
  Box,
  IconButton,
  Paper,
  SwipeableDrawer,
  useTheme
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React from "react";
import NavBar from "../appNav/VerticalNavWrapper";
import UserBox, { UserBoxMdMode } from "./Components/UserBox";
import "./style.css";

const HeaderWrapper = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  height: '60px',
  backgroundColor: '#fff',
  padding: '10px 20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  boxShadow: '0 0 15px rgba(0, 0, 0, 0.1)',
  zIndex: 1100,
  overflow: 'hidden',
  [theme.breakpoints.down('sm')]: {
    padding: '10px'
  }
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: 'none',
  borderRadius: 0,
  padding: theme.spacing(2)
}));

const DrawerContent = styled(Box)({
  width: 250
});

const Logo = styled('img')({
  height: '40px',
  width: 'auto'
});

export const HeaderDesktop = () => {
  return (
    <HeaderWrapper>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Logo
          src={process.env.REACT_APP_LOGO_URL}
          alt="People fine Logo"
        />
        {/* <SearchBox /> */}
      </Box>
      <UserBox />
    </HeaderWrapper>
  );
};

export const HeaderMobile = () => {
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const theme = useTheme();

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setOpenDrawer(open);
  };

  return (
    <HeaderWrapper>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={toggleDrawer(true)}
        >
          <MenuIcon />
        </IconButton>
        
        <Logo
          src={process.env.REACT_APP_LOGO_URL}
          alt="People fine Logo"
        />
      </Box>
      
      <UserBoxMdMode />
      
      <SwipeableDrawer
        anchor="left"
        open={openDrawer}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <DrawerContent>
          <StyledPaper>
            <NavBar
              sideBarCollapsed={false}
              handleClose={toggleDrawer(false)}
            />
          </StyledPaper>
        </DrawerContent>
      </SwipeableDrawer>
    </HeaderWrapper>
  );
};

export default HeaderDesktop;
