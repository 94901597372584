import DatePickerWrapper from '../../components/DatePicker/DatePickerWrapper';
import { FormControl, Grid, Snackbar, Select, MenuItem, CircularProgress } from '@mui/material';
import React, { useState, useEffect } from "react";
import { Alert } from '@mui/lab';
import ContactTasksTable from "./Tables/ContactTasksTable";
import { Card } from "react-bootstrap";
import { getAllUsersForTasks } from "../Services/UserService";
import { getUserData } from "../../utils/FunctionHelpers";
import { Box } from '@mui/material';
import { v4 } from "uuid";

function ContactTasks({ statut }) {
  const [snackMsg, setSnackMsg] = useState();
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("success");
  const [affectePar, setAffectePar] = useState();
  const [affecteA, setAffecteA] = useState(getUserData().uuid);
  const [dateFilter, setDateFilter] = useState("toutes");
  const [dueDate, setDueDate] = useState();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reloadingData, setReloadingData] = useState(false);
  const searchParams = { affecteA, affectePar, dueDate, dateFilter, statut };
  const tachesContactProps = { setSeverity, setOpen, setSnackMsg, searchParams, reloadingData, setReloadingData };

  const handleClose = () => {
    setOpen(false);
  };

  const dateFilterData = {
    toutes: "TOUTES",
    aujourduietpasse: "AUJOURD'HUI ET PASSÉES",
    aujourduietavenir: "AUJOURD'HUI ET À VENIR",
    pardate: "PAR DATE",
  };

  const handleParDate = (e) => {
    if (e.target.value === "pardate") {
      setDateFilter(e.target.value);
      return;
    }
    setDateFilter(e.target.value);
    setReloadingData(!reloadingData);
  };

  useEffect(() => {
    let active = true;
    (async () => {
      setLoading(true);
      try {
        const users = await getAllUsersForTasks();
        if (!active) {
          return;
        }
        setUsers(users.data);
        setLoading(false);
      } catch (error) {
        if (!active) {
          return;
        }
        setOpen(true);
        setSnackMsg("Erreur de chargement des utilisateurs.");
        setSeverity("error");
        setLoading(false);
      }
    })();

    return () => {
      active = false;
    };
  }, []);

  return (
    <div className="main_content_iner" style={{ marginTop: "0px" }}>
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="QA_section">
              <h2>
                <span className="text-capitalize" style={{ color: "rgb(17, 122, 167)" }}>
                  MA LISTE DE TÂCHES {statut ? statut.toUpperCase() : "CONTACT"}
                </span>
              </h2>
              <div className="container-fluid animated fadeIn">
                <div className="row">
                  <div className="col-md-12">
                    <Card style={{ padding: "1em", marginBottom: "1em" }}>
                      <Grid container spacing={1}>
                        <Grid item={true} xs={12} md={3}>
                          <strong>Affectées par</strong>
                          <FormControl variant="outlined" fullWidth margin="normal">
                            <Select
                              name="affectePar"
                              value={"TOUTE"}
                              onChange={(e) => {
                                setAffectePar(e.target.value);
                                setReloadingData(!reloadingData);
                              }}
                              defaultValue=""
                            >
                              {loading ? (
                                <CircularProgress />
                              ) : (
                                users.map((user) => (
                                  <MenuItem value={user.uuid} key={user.uuid}>
                                    {user.prenom} {user.nom}
                                  </MenuItem>
                                ))
                              )}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item={true} xs={12} md={3}>
                          <strong>Affectées à</strong>
                          <FormControl variant="outlined" fullWidth margin="normal">
                            {loading ? (
                              <Box display="flex" justifyContent="center">
                                <CircularProgress />
                              </Box>
                            ) : (
                              <Select
                                value={users.length > 0 ? affecteA : ""}
                                name="users"
                                onChange={(e) => {
                                  setAffecteA(e.target.value);
                                  setReloadingData(!reloadingData);
                                }}
                              >
                                {loading ? (
                                  <CircularProgress />
                                ) : (
                                  users.map((user) => (
                                    <MenuItem value={user.uuid} key={user.uuid}>
                                      {user.prenom} {user.nom}
                                    </MenuItem>
                                  ))
                                )}
                              </Select>
                            )}
                          </FormControl>
                        </Grid>
                        <Grid item={true} xs={12} md={3}>
                          <strong>Afficher les tâches </strong>
                          <FormControl variant="outlined" fullWidth margin="normal">
                            <Select name="dateFilter" onChange={(e) => handleParDate(e)} value={dateFilter}>
                              {Object.keys(dateFilterData).map((keyname, index) => (
                                <MenuItem value={keyname} key={v4()}>
                                  {dateFilterData[keyname]}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        {dateFilter === "pardate" ? (
                          <Grid item={true} xs={12} md={3}>
                            <strong>Par date </strong>
                            <DatePickerWrapper
                              disableToolbar
                              variant="inline"
                              views={["date", "month", "year"]}
                              format="dd/MM/yyyy"
                              inputVariant="outlined"
                              margin="normal"
                              fullWidth
                              onChange={(date) => {
                                setDueDate(date);
                                setReloadingData(!reloadingData);
                              }}
                            />
                          </Grid>
                        ) : (
                          ""
                        )}
                      </Grid>
                    </Card>
                    <Grid item={true} xs={12}>
                      <ContactTasksTable {...tachesContactProps} />
                    </Grid>
                    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                      <Alert onClose={handleClose} severity={severity}>
                        {snackMsg}
                      </Alert>
                    </Snackbar>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactTasks;
