import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const MainContentWrapper = styled(Box)(({ theme }) => ({
  marginLeft: '280px', // Largeur de la navigation verticale
  marginTop: '80px', // Hauteur du header
  padding: '20px',
  minHeight: 'calc(100vh - 80px)',
  backgroundColor: '#f8f9fa',
  [theme.breakpoints.down('sm')]: {
    marginLeft: 0,
  }
}));

export const LayoutContainer = ({ children }) => {
  return (
    <MainContentWrapper>
      {children}
    </MainContentWrapper>
  );
};