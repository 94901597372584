import api from "../../utils/API";

async function loginUser(credentials) {
  return api.post("/authentification/user/login_check", credentials);
}

function logout(history) {
  localStorage.clear();
  history.push("/login");
}

function getCurrentUser() {
  return JSON.parse(localStorage.getItem("user"));
}

function getRedirectUrl() {
  const savedUrl = localStorage.getItem("redirectUrl");
  localStorage.removeItem("redirectUrl"); // Clear it after getting it
  return savedUrl || "/accueil"; // Default to /accueil if no saved URL
}

export {loginUser, logout, getCurrentUser, getRedirectUrl};
