import React, { useState } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import {
  Close as CloseIcon,
  ChevronLeft,
  ChevronRight,
  Download as DownloadIcon
} from '@mui/icons-material';
import { Document, Page, pdfjs } from "react-pdf";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import LoadingButton from '@mui/lab/LoadingButton';

// Configuration du worker PDF.js
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const FilePreview = ({ open, onClose, file, onDownload }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [downloading, setDownloading] = useState(false);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const changePage = (offset) => {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  };

  const handleDownload = async () => {
    if (!file || downloading) return;
    
    setDownloading(true);
    try {
      await onDownload(file);
    } finally {
      setDownloading(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          height: '90vh',
          maxHeight: '90vh'
        }
      }}
    >
      <DialogTitle sx={{ 
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        p: 2
      }}>
        <Typography variant="h6">
          {file?.name}
        </Typography>
        <Box>
          <LoadingButton
            loading={downloading}
            onClick={handleDownload}
            startIcon={<DownloadIcon />}
            variant="text"
            sx={{ mr: 1 }}
          >
            Télécharger
          </LoadingButton>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent dividers sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Box sx={{ flex: 1, width: '100%', overflow: 'auto' }}>
          <Document
            file={file?.url}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={<Typography>Chargement du PDF...</Typography>}
            error={<Typography color="error">Erreur lors du chargement du PDF</Typography>}
          >
            <Page 
              pageNumber={pageNumber} 
              renderTextLayer={true}
              renderAnnotationLayer={true}
              scale={1.2}
            />
          </Document>
        </Box>

        {numPages > 1 && (
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: 2,
            mt: 2
          }}>
            <IconButton 
              onClick={() => changePage(-1)} 
              disabled={pageNumber <= 1}
            >
              <ChevronLeft />
            </IconButton>
            
            <Typography>
              Page {pageNumber} sur {numPages}
            </Typography>
            
            <IconButton 
              onClick={() => changePage(1)} 
              disabled={pageNumber >= numPages}
            >
              <ChevronRight />
            </IconButton>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default FilePreview;
