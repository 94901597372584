import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  SwipeableDrawer,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useCallback, useEffect, useState } from 'react';
import { BiSearch } from "react-icons/bi";
import { useSelector } from 'react-redux';
import { withSnackbar } from '../../utils/SnackbarHOC';
import MultiCritere from './Accordions/MultiCritere';
import ButtonList from './Controls/ButtonList';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import {updateObjectStateValue} from "../../utils/FunctionHelpers";
import {getAllSearchLists} from "../Services/SearchService";

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[1],
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(2),
  '& .MuiCardContent-root': {
    padding: theme.spacing(2)
  }
}));

const StyledSearchField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    backgroundColor: theme.palette.background.paper,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.primary.main,
      }
    },
    '&.Mui-focused': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.primary.main,
        borderWidth: 2
      }
    }
  },
  '& .MuiInputAdornment-root': {
    '& .MuiIconButton-root': {
      padding: theme.spacing(1),
      '&:hover': {
        backgroundColor: theme.palette.action.hover
      }
    }
  }
}));

const SearchResults = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  '& .result-item': {
    padding: theme.spacing(1.5),
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    transition: theme.transitions.create(['background-color', 'box-shadow']),
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      cursor: 'pointer'
    }
  },
  '& .highlight': {
    backgroundColor: theme.palette.warning.light,
    padding: '0 4px',
    borderRadius: 2
  }
}));

const StyledBox = styled(Box)(({ theme }) => ({
  searchByName: {
    padding: "15px",
    justifyContent: "center",
  },
  title: {
    color: "#117aa7",
    fontWeight: 700,
    fontFamily: "myFont",
  },
  classHeading: {
    color: "#FFF",
  },
  multi2: {
    marginRight: "1.5em",
    width: "100%",
  },
  MuiTypographyCaption: {
    fontWeight: 700,
  },
  fontStyle: {
    fontFamily: "myFont",
    fontSize: "14px",
  },
}));

const Search = ({
  candidatsData,
  societesData,
  contactsData,
  handleSearch,
  snackbarShowMessage,
}) => {
  const history = useHistory();
  const classes = StyledBox;
  const [nomFamille, setNomFamille] = useState(false);
  const [searchMode, setSearchMode] = useState("or");

  const [motsCleCategorie, setMotsCleCategorie] = useState("tous");
  const [multiOpen, setMultiOpen] = useState(false);
  const [searchKeys, setSearchKeys] = useState({
    motsCle: [],
    societe: [],
    selection: ""
  })
  const [loading, setLoading] = useState(false)
  const [selectionsData, setSelectionsData] = useState({
    societes: {
      data: []
    },
    selections: {
      data: []
    },
    competenceMetier: [],
    competenceSecto: [],
    consultants: [],
    managers: []
  });
  const selectedCandidatForSelection = useSelector((state) => state.candidats.candidatsSelectedForSelection);
  const getInitialParameters = () => {
    const location = window.location.search
    if (location) {
      const searchParams = new URLSearchParams(location);
      const params = {};
      for (const [key, value] of searchParams.entries()) {
        params[key] = value;
      }
      return params;
    }

    return {
      prenom: "",
      categorie: "candidat",
      motsCleCategorie: "fonction",
    };
  };
  const [parameters, setParameters] = useState(getInitialParameters);

  const addParam = useCallback((e) => {
    const { name, value } = e.target;

    setParameters((prevParameters) => ({
      ...prevParameters,
      [name]: value || "",
    }));
  }, []);

  const settingUpListsForAutoComplete = (idKeyName, valueKeyName, data) => {
    return data?.map(({
      [idKeyName]: id,
      [valueKeyName]: value,
      ...rest
    }) => ({
      id,
      value,
      ...rest
    }));
  }

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      history.push(`/accueil/chercher?${new URLSearchParams(parameters).toString()}`);
    }
  };

  useEffect(() => {
    const updatedParams = {
      ...parameters,
      ...({ 'searchKeys': searchKeys?.motsCle?.length ? searchKeys.motsCle.join('|') : "" }),
      ...(searchKeys?.societe?.id ? { 'societe': searchKeys?.societe?.id } : {}),
      ...(searchKeys?.selection?.id ? { 'selection': searchKeys.selection.id } : {}),
      searchMode
    };
    setParameters(updatedParams);
  }, [searchKeys.motsCle, searchKeys?.societe, searchKeys.selection, parameters, searchMode]);

  useEffect(() => {
    if (multiOpen) {
      let active = true;
      setLoading(true);
      (async () => {
        try {
          const { data } = await getAllSearchLists();
          if (!active) {
            return;
          }
          console.log(data)
          const setupSocietes = settingUpListsForAutoComplete("uuid", "nom_societe", data.societes)
          updateObjectStateValue(setSelectionsData, {
            societes: {
              data: setupSocietes,
              isLoading: loading
            },
            selections: {
              data: data.selections,
              isLoading: loading,
              label: 'SÉLECTIONS'
            },
            consultants: data.consultants,
            managers: data.managers,
            competenceSecto: data.competencesSectorielles,
            competenceMetier: data.competencesMetiers
          })
        } catch (error) {
          snackbarShowMessage("Erreur de chargement des listes.", "error", 4000)
        } finally {
          setLoading(false);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multiOpen]);

  const handleNameChecked = (e) => {
    const { checked } = e.target;
    const paramName = checked ? "prenom" : "nom";
    const otherParamName = checked ? "nom" : "prenom";
    const { [paramName]: temp, ...newParameters } = parameters;
    setParameters({ ...newParameters, [otherParamName]: temp });
    setNomFamille(checked);
  };

  const handleSearchModeChange = (event) => {
    setSearchMode(event.target.value);
  };

  const multiCritereProps = {
    classes,
    motsCleCategorie,
    setMotsCleCategorie,
    multiOpen,
    addParam,
    parameters,
    searchKeys,
    setSearchKeys,
    loading,
    selectionsData,
    handleSearch,
    handleNameChecked,
    searchMode,
    handleSearchModeChange,
    nomFamille
  };

  const isSmall = useMediaQuery((theme) => theme.breakpoints.between(0, 767));
  const isMediumMode = useMediaQuery((theme) => theme.breakpoints.between(767, 1023));

  return (
    <Container >
      <StyledCard className={classes.searchByName} style={{ position: "center" }}>
        <CardContent>
          <Typography variant="h6" gutterBottom style={{ color: "rgb(17, 122, 167)" }} >
            RECHERCHE
          </Typography>
          <Grid container alignItems="center" spacing={1}>
            <Grid item xs={12} md={3}>
              <FormControl variant="outlined" fullWidth margin="normal">
                <Select
                  className={classes.fontStyle}
                  value={parameters && parameters.categorie}
                  name="categorie"
                  onChange={(e) => {
                    addParam(e);
                  }}
                  input={<OutlinedInput name="categorie" />}
                >
                  {["candidat", "contact", "societe"].map((category) => (
                    <MenuItem
                      value={category}
                      key={category}
                      className={classes.fontStyle}
                    >
                      {category.toUpperCase()}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid
              item
              xs={12}
              {...(multiOpen
                ? { md: 9, style: { transition: "ease", transitionDuration: "0.3s" } }
                : { md: 9, style: { transition: "ease", transitionDuration: "0.2" } })}
            >
              <StyledSearchField
                {...(nomFamille ? { name: "nom" } : { name: "prenom" })}
                value={parameters && parameters.nom ? parameters.nom : parameters.prenom}
                onChange={(e) => {
                  addParam(e);
                }}
                variant="outlined"
                autoComplete="off"
                fullWidth
                placeholder="Nom ou N° de dossier ou N° de téléphone ou Lien LKD"
                margin="normal"
                onKeyDown={handleKeyPress}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <BiSearch style={{
                        color: "#117aa7",
                        fontSize: "24px",
                      }} />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Rechercher">
                        <IconButton
                          onClick={() => history.push(`/accueil/chercher?${new URLSearchParams(parameters).toString()}`)}
                          size="small"
                          aria-label="search"
                        >
                          <SearchIcon />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Box sx={{ display: "flex" }}>
            {candidatsData.loadCandidats || societesData.loadSociete || contactsData.loadContacts ? <ButtonList loading={loading} selectedCandidats={selectedCandidatForSelection} history={history}></ButtonList> : ""}
            <Button startIcon={<FilterListIcon />} onClick={() => setMultiOpen(true)} className={classes.fontStyle} style={{ color: "#117aa7" }}>
              FILTRER
            </Button>
          </Box>
        </Box>
      </StyledCard>
      <SwipeableDrawer
        anchor="right"
        open={multiOpen}
        onClose={() => setMultiOpen(false)}
        onOpen={() => setMultiOpen(true)}
      >
        <Box sx={{ width: isSmall || isMediumMode ? "60vw" : "35vw" }}>
          <MultiCritere {...multiCritereProps} />
        </Box>
      </SwipeableDrawer>
    </Container >
  )
}

export default withSnackbar(Search);
