import Button from '@mui/material/Button';
import { BiMessage, BiPhone } from "react-icons/bi";
import SmsCandidat from "../../pages/Candidat/EmailCondidat/SmsCandidat";
import { Typography, capitalize, styled } from '@mui/material';
import { Edit } from '@mui/icons-material';
import "./style.css";
import EmailModal from "../EmailModal/EmailModal";
import { sendEmailContact } from "../../pages/Services/ProcessService";
import { TABLE } from "../../enums/tablesEnum";

export function ContactButtonAndEditButton({
  id,
  prenom,
  nom,
  modalOpenSms,
  setModalOpenSms,
  uuid,
  loadData,
  history,
  email,
  setLoadData,
  edit,
  telephone,
  ...props
}) {
  const useStyles = styled(() => ({
    contactIcons: {
      color: "#117aa7",
    },
  }));

  const classes = useStyles();

  return (
    <Typography sx={{ fontWeight: 600 }} className="buttonIcons">
      <Button
        startIcon={<BiPhone />}
        data-toggle="tooltip"
        title="APPEL"
        className={classes.contactIcons}
      ></Button>
      <Button
        startIcon={<BiMessage />}
        data-toggle="tooltip"
        title="SMS"
        className={classes.contactIcons}
      ></Button>
      {modalOpenSms && (
        <SmsCandidat
          setOpenModal={setModalOpenSms}
          {...props}
          candidatName={capitalize(prenom) + " " + nom.toUpperCase()}
          idCandidat={uuid}
          candidatTel={telephone}
        />
      )}
      <EmailModal
        loadData={loadData}
        setLoadData={setLoadData}
        prenom={prenom}
        emailTarget={email}
        serviceEmail={sendEmailContact}
        id={id}
        category={TABLE.CONTACT}
      />
      <Button
        data-toggle="tooltip"
        title="MODIFIER"
        onClick={() => edit(id, history)}
        style={{
          color: "#fff",
        }}
      >
        <Edit />
      </Button>
    </Typography>
  );
}
