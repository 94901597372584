import {
  Add as AddIcon,
  Attachment as AttachmentIcon,
  Close as CloseIcon,
  Comment as CommentIcon,
  Computer as ComputerIcon,
  Description as DescriptionIcon,
  Download as DownloadIcon,
  OpenInNew as OpenInNewIcon,
  Upload as UploadIcon
} from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Typography
} from '@mui/material';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import React, { useEffect, useRef, useState } from 'react';
import { removeLineBreak } from "../../utils/EditorUtil";
import { getUserData } from "../../utils/FunctionHelpers";
import { getCvBlob } from '../Services/FileService';
import { addNoteCandidat, downloadNoteFile, getAll } from '../Services/NoteCandidat';
import FilePreview from './Components/FilePreview';
import ProcessTimeline from './Components/ProcessTimeline';
import SelectionLabel from './Components/SelectionLabel';
import SelectionsPopover from './Components/SelectionsPopover';

const ProcessViewModal = ({ open, onClose, process, onNoteAdded, snackbarShowMessage }) => {
  console.log("process", process);
  const [selectionAnchorEl, setSelectionAnchorEl] = useState(null);
  const [comment, setComment] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [errors, setErrors] = useState({});
  const [downloadingFile, setDownloadingFile] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [notes, setNotes] = useState([]);
  const [loadingNotes, setLoadingNotes] = useState(false);
  const [addingNote, setAddingNote] = useState(false);
  const [pj, setPj] = useState(null);
  const uploadRef = useRef(null);

  useEffect(() => {
    let active = true;

    const fetchNotes = async () => {
      if (!process?.id) return;
      setLoadingNotes(true);
      try {
        const response = await getAll(1, process.id);
        if (active) {
          setNotes(response.data.notesCandidat || []);
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des notes:', error);
        snackbarShowMessage('Erreur lors de la récupération des notes', 'error');
      } finally {
        if (active) {
          setLoadingNotes(false);
        }
      }
    };

    fetchNotes();

    return () => {
      active = false;
    };
  }, [process?.id, snackbarShowMessage]);

  const handleOpenSelections = (event) => {
    event.stopPropagation();
    setSelectionAnchorEl(event.currentTarget);
  };

  const handleCloseSelections = () => {
    setSelectionAnchorEl(null);
  };

  const handleSelectionChange = () => {
    // Rafraîchir les données du process si nécessaire
  };

  const validate = (comment) => {
    let temp = {};
    temp.commentaire = !(comment?.replace(/<\/?[^>]+(>|$)/g, "") === "") ? "" : "Le champ 'COMMENTAIRE' est obligatoire.";
    setErrors({
      ...temp,
    });
    return Object.values(temp).every((x) => x === "");
  };

  const handleAddNote = async () => {
    if (!comment.trim()) return;

    setAddingNote(true);
    const formData = new FormData();
    formData.append("dateTime", new Date());
    formData.append("commentaire", removeLineBreak(comment));
    formData.append("auteur", getUserData().uuid);
    formData.append("candidat", process.id);
    if (pj) {
      formData.append("pj", pj);
    }

    try {
      await addNoteCandidat(formData);

      // Recharger les notes
      const response = await getAll(1, process.id);
      setNotes(response.data.notesCandidat || []);

      setComment('');
      setPj(null);
      setShowForm(false);
      onNoteAdded && onNoteAdded();
      snackbarShowMessage('Note ajoutée avec succès', 'success');
    } catch (error) {
      console.error('Erreur lors de l\'ajout de la note:', error);
      snackbarShowMessage('Erreur lors de l\'ajout de la note', 'error');
    } finally {
      setAddingNote(false);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setPj(file);
    }
  };

  const handleFileClick = async (file) => {
    setSelectedFile(file);
    setPreviewOpen(true);
  };

  const handleDownload = async (file) => {
    if (!file || downloadingFile === file.id) return;

    setDownloadingFile(file.id);
    try {
      const response = file.categorie === 'cv'
        ? await getCvBlob(file.id)
        : await downloadNoteFile(file.id);

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', file.name);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Erreur lors du téléchargement:', error);
      snackbarShowMessage('Erreur lors du téléchargement du fichier', 'error');
    } finally {
      setDownloadingFile(null);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      PaperProps={{
        sx: {
          maxHeight: '90vh',
          display: 'flex',
          flexDirection: 'column'
        }
      }}
    >
      <DialogTitle sx={{
        p: 2,
        display: 'flex',
        alignItems: 'flex-start',
        backgroundColor: 'white',
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
      }}>
        <Box sx={{ flex: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <ComputerIcon sx={{ mr: 1, color: 'text.secondary' }} />
            <Typography variant="h6">
              {process?.titre} {process?.nom} {process?.prenom}
            </Typography>
          </Box>
          <Typography variant="body2" color="text.secondary">
            dans la liste <strong>{process?.status}</strong>
          </Typography>
        </Box>
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ flex: 1, display: 'flex', flexDirection: 'column', p: 0, overflowY: 'hidden' }}>
        <Grid container spacing={3}>
          {/* Colonne principale */}
          <Grid item xs={12} md={7} px={1}>
            <Box sx={{ mb: 3 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <DescriptionIcon sx={{ mr: 1, color: 'primary.main' }} />
                <Typography variant="subtitle1" sx={{ color: 'primary.main', fontWeight: 600 }}>Description</Typography>
              </Box>
              <Typography variant="body2" color="text.secondary" sx={{ pl: 4 }}>
                {process?.description || 'Aucune description'}
              </Typography>
            </Box>

            <Box sx={{ mb: 3 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <AttachmentIcon sx={{ mr: 1, color: 'secondary.main' }} />
                <Typography variant="subtitle1" sx={{ color: 'secondary.main', fontWeight: 600 }}>Pièces jointes</Typography>
              </Box>
              <Box sx={{ pl: 4 }}>
                {process?.uploadedFiles?.length > 0 ? (
                  <Stack spacing={1}>
                    {process.uploadedFiles.map((file) => (
                      <Box
                        key={`file-${file.id}`}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          p: 1,
                          backgroundColor: 'background.paper',
                          borderRadius: 1,
                          '&:hover': {
                            backgroundColor: 'action.hover'
                          }
                        }}
                      >
                        <AttachmentIcon sx={{ mr: 1, color: 'text.secondary' }} />
                        <Box sx={{ flex: 1 }}>
                          <Typography variant="body2">{file.name}</Typography>
                          <Typography variant="caption" color="text.secondary">
                            {file.categorie === 'cv' ? 'CV' : 'Pièce jointe'}
                          </Typography>
                        </Box>
                        <LoadingButton
                          loading={downloadingFile === file.id}
                          onClick={() => handleDownload(file)}
                          startIcon={<DownloadIcon />}
                          sx={{ mr: 1 }}
                        >
                          Télécharger
                        </LoadingButton>
                        <IconButton size="small" onClick={() => handleFileClick(file)}>
                          <OpenInNewIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    ))}
                  </Stack>
                ) : (
                  <Typography variant="body2" color="text.secondary">
                    Aucune pièce jointe
                  </Typography>
                )}
              </Box>
            </Box>

            <Box sx={{ mb: 3, display: 'flex', flexDirection: 'column', height: '100%' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <CommentIcon sx={{ mr: 1, color: 'info.main' }} />
                <Typography variant="subtitle1" sx={{ color: 'info.main', fontWeight: 600 }}>Notes</Typography>
                <Box sx={{ flex: 1 }} />
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => setShowForm(!showForm)}
                >
                  {showForm ? 'Annuler' : 'Ajouter une note'}
                </Button>
              </Box>

              {/* Zone de saisie de note */}
              {showForm && (
                <Box sx={{ mb: 2 }}>
                  <Box sx={{ display: 'flex', gap: 2, alignItems: 'flex-start' }}>
                    <Avatar 
                      sx={{ 
                        width: 32, 
                        height: 32,
                        bgcolor: 'primary.main',
                        fontSize: '0.875rem'
                      }}
                    >
                      {getUserData()?.prenom?.[0]?.toUpperCase() || getUserData()?.email?.[0]?.toUpperCase() || 'U'}
                    </Avatar>
                    <Box
                      component="textarea"
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                      sx={{
                        flex: 1,
                        minHeight: 60,
                        maxHeight: 120,
                        p: 1.5,
                        mb: 1,
                        border: '1px solid',
                        borderColor: 'divider',
                        borderRadius: 1,
                        resize: 'vertical',
                        fontFamily: 'inherit',
                        fontSize: 'inherit',
                        backgroundColor: 'white',
                        '&:focus': {
                          outline: 'none',
                          borderColor: 'primary.main',
                          boxShadow: '0 0 0 2px rgba(25, 118, 210, 0.1)'
                        }
                      }}
                      placeholder="Écrivez votre note ici..."
                    />
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, pl: 5 }}>
                    <input
                      type="file"
                      ref={uploadRef}
                      style={{ display: 'none' }}
                      onChange={handleFileChange}
                    />
                    <Button
                      variant="outlined"
                      startIcon={<UploadIcon />}
                      onClick={() => uploadRef.current.click()}
                      size="small"
                    >
                      {pj ? pj.name : 'Ajouter une pièce jointe'}
                    </Button>
                    <LoadingButton
                      loading={addingNote}
                      variant="contained"
                      onClick={handleAddNote}
                      disabled={!comment.trim()}
                    >
                      Ajouter
                    </LoadingButton>
                    <Button
                      variant="text"
                      onClick={() => {
                        setShowForm(false);
                        setComment('');
                        setPj(null);
                      }}
                    >
                      Annuler
                    </Button>
                  </Box>
                </Box>
              )}

              {/* Liste des notes avec scroll */}
              <Box sx={{ 
                flex: 1,
                overflowY: 'auto',
                minHeight: 0,
                maxHeight: '400px',
                bgcolor: 'background.default',
                borderRadius: 1,
                p: 2
              }}>
                {/* Loader pour les notes */}
                {loadingNotes ? (
                  <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                    <CircularProgress size={24} />
                  </Box>
                ) : (
                  <Stack spacing={2}>
                    {notes.map((note) => (
                      <Box key={`note-${note.id}`}>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                          <Avatar 
                            sx={{ 
                              width: 32, 
                              height: 32,
                              bgcolor: 'primary.main',
                              fontSize: '0.875rem'
                            }}
                          >
                            {note.auteur?.split(' ')
                              .map(word => word[0])
                              .slice(0, 2)
                              .join('')
                              .toUpperCase() || '?'}
                          </Avatar>
                          <Box sx={{ flex: 1 }}>
                            <Box sx={{
                              backgroundColor: 'background.paper',
                              p: 1.5,
                              borderRadius: 1,
                              mb: 0.5
                            }}>
                              <Typography variant="subtitle2" gutterBottom>
                                {note.auteur}
                              </Typography>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: note.commentaire
                                }}
                              />
                              {note.pj && (
                                <Box sx={{ mt: 1, display: 'flex', alignItems: 'center', gap: 1 }}>
                                  <AttachmentIcon sx={{ fontSize: 20, color: 'text.secondary' }} />
                                  <Typography variant="body2" color="text.secondary">
                                    {note.pj}
                                  </Typography>
                                  <LoadingButton
                                    size="small"
                                    loading={downloadingFile === note.id}
                                    onClick={() => handleDownload({ id: note.id, name: note.pj })}
                                    startIcon={<DownloadIcon />}
                                  >
                                    Télécharger
                                  </LoadingButton>
                                </Box>
                              )}
                            </Box>
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 2,
                                color: 'text.secondary',
                                fontSize: '0.75rem'
                              }}
                            >
                              <Typography variant="caption">
                                {format(new Date(note.dateTime.date), 'dd/MM/yyyy HH:mm', {
                                  locale: fr
                                })}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    ))}
                  </Stack>
                )}
              </Box>
            </Box>
          </Grid>

          {/* Colonne latérale */}
          <Grid item xs={12} md={5} sx={{
            borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
            backgroundColor: 'white',
            p: 2
          }}>
            <Typography variant="subtitle2" gutterBottom>
              Étiquettes
            </Typography>
            <Box
              onClick={e => e.stopPropagation()}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 0.5,
                mb: 2
              }}
            >
              <SelectionLabel
                selections={process?.selections || []}
                onClick={(e) => handleOpenSelections(e, process.id)}
              />
            </Box>

            <ProcessTimeline process={process} />
          </Grid>
        </Grid>
      </DialogContent>

      <SelectionsPopover
        anchorEl={selectionAnchorEl}
        onClose={handleCloseSelections}
        candidatId={process?.candidatId}
        onSelectionChange={handleSelectionChange}
      />

      <FilePreview
        open={previewOpen}
        onClose={() => {
          setPreviewOpen(false);
          setSelectedFile(null);
        }}
        file={selectedFile}
        onDownload={handleDownload}
      />
    </Dialog>
  );
};

export default ProcessViewModal;
