import React, { useEffect, useState } from "react";
import "./Listeruser.css";
import { useLocation, useHistory } from 'react-router-dom';
import { Box, Button, Grid, IconButton, useMediaQuery } from '@mui/material';
import { styled, makeStyles } from '@mui/styles'
import { Edit, Archive, CheckCircleOutline, Block, Unarchive, Add, PersonAdd } from '@mui/icons-material';
import { getAll, archiveUser } from "../Services/UserService";
import { CircularProgress } from '@mui/material';
import { theme } from "../../utils/Theme";
import DialogModal from "../../utils/DialogModal";
import MUIDataTable from "mui-datatables";
import { withSnackbar } from "../../utils/SnackbarHOC";
import { handleOnViewColumnsChange, displayColumn } from "../../utils/viewColumns";

const useStyles = makeStyles(() => ({
  buttonProgress: {
    color: "rgb(17, 122, 167)",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  archiveButton: {
    color: "white",
    fontWeight: 500,
    backgroundColor: "rgb(17, 122, 167)",
    marginBottom: "10px",
    "&:hover": {
      backgroundColor: "rgb(17, 122, 167)"
    }
  },
  enabled: {
    color: "#4BB543"
  },
  disabled: {
    color: "#D2042D"
  },
  action: {
    display: 'ce'
  }
}));

const Listeruser = ({ snackbarShowMessage }) => {
  const history = useHistory();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [loadingAction, setLoadingAction] = useState(false);
  const [reloadData, setReloadData] = useState(false);

  const editUser = (params) => {
    history.push({
      pathname: "/users/" + params.rowData[0] + "/update",
    });
  };

  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        const response = await getAll();
        setUsers(response.data.users);
      } catch (error) {
        snackbarShowMessage("Erreur de chargement des utilisateur.", "error", 4000);
      } finally {
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadData]);

  const handleArchiverUser = (data, handleClose) => {
    setLoadingAction(true);
    archiveUser(data).then(() => {
      snackbarShowMessage(
        `L'utilisateur a été ${data.status ? 'activé' : 'désactivé'} avec succès`,
        'success',
        4000
      );
      setReloadData(!reloadData);
      handleClose();
    }).catch(error => {
      snackbarShowMessage(
        `Erreur ${data.status ? "d'activage" : "de désactivage"
        } de l'utilisateur.`,
        "error",
        4000
      );
    }).finally(() => {
      setLoadingAction(false);
    })
  }

  const handleShowArchiveButton = (utilisateur, status) => {
    const dialogProps = {
      label: status ? <Archive className={classes.disabled} /> : <Unarchive className={classes.enabled} />,
      description: `Voulez vous ${status ? 'désactiver' : 'activer'} l'utilisateur ?`,
      title: `${status ? 'Désactiver' : 'Activer'} l'utilisateur ?`,
      closeLabel: "Annuler",
      confirmLabel: status ? 'Activer' : 'Désactiver',
      loading: loadingAction,
      onConfirm: handleArchiverUser,
      data: {
        user: utilisateur,
        status: !status
      },
    };
    return <Box><DialogModal {...dialogProps} /></Box>;
  };

  const handleShowCheck = (status) => {
    if (status) return <CheckCircleOutline className={classes.enabled} />
    return <Block className={classes.disabled} />
  }

  const tableName = "usersColumnVisibility";
  const columns = [
    {
      name: "uuid",
      label: "N°UTILISATEUR",
      options: {
        display: false,
        setCellProps: () => ({ style: { whiteSpace: "nowrap", textOverFlow: "ellipsis", overflow: "hidden", maxWidth: "100px" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Box data-toggle="tooltip" title={value} style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", width: "100px" }}>{value !== null ? value : "---"}</Box>
            </>
          );
        },
      },
    },
    {
      name: "prenom",
      label: "PRÉNOM",
      options: {
        display: displayColumn("prenom", tableName),
        filter: true,
        sort: true,
        setCellProps: () => ({ style: { textTransform: "capitalize" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Box data-toggle="tooltip" title={value} style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", width: "100px" }}>{value !== null ? value : "---"}</Box>
            </>
          );
        },
      },
    },

    {
      name: "nom",
      label: "NOM",
      options: {
        display: displayColumn("nom", tableName),
        filter: true,
        sort: true,
        setCellProps: () => ({ style: { textTransform: "uppercase" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Box data-toggle="tooltip" title={value} style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", width: "100px" }}>{value !== null ? value : "---"}</Box>
            </>
          );
        },
      },
    },
    {
      name: "localisation",
      label: "LOCALISATION",
      options: {
        display: displayColumn("localisation", tableName),
        filter: true,
        sort: true,
        setCellProps: () => ({ style: { textTransform: "uppercase" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Box data-toggle="tooltip" title={value} style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", width: "50px" }}>{value !== null ? value : "---"}</Box>
            </>
          );
        },
      },
    },
    {
      name: "fonction",
      label: "FONCTION",
      options: {
        display: displayColumn("fonction", tableName),
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Box data-toggle="tooltip" title={value} style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", width: "100px" }}>{value !== null ? value : "---"}</Box>
            </>
          );
        },
      },
    },
    {
      name: "passworduser",
      label: "PASSWORD",
      options: {
        display: displayColumn("passworduser", tableName),
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Box data-toggle="tooltip" title={value}> {localStorage.getItem("rolecurrentuser") === "ROLE_DIRECTION" ? (value) : ("")} </Box>
            </>
          );
        },
      },
    },
    {
      name: "tel_mobile",
      label: "TÉL MOBILE",
      options: {
        display: displayColumn("tel_mobile", tableName),
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({ style: { whiteSpace: "nowrap" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Box data-toggle="tooltip" title={value} sx={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>{value !== null ? value : "---"}</Box>
          );
        },
      },
    },
    {
      name: "email",
      label: "EMAIL",
      options: {
        display: displayColumn("email", tableName),
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Box data-toggle="tooltip" title={value} style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", width: "100px" }}>{value !== null ? value : "---"}</Box>
            </>
          );
        },
      },
    },
    {
      name: "role",
      label: "STATUT",
      options: {
        display: displayColumn("role", tableName),
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Box data-toggle="tooltip" title={value}> {(value.replace("ROLE_", "").replace("_", " "))}</Box>
            </>
          );
        },
      },
    },
    {
      name: "isActive",
      label: "ETAT",
      options: {
        display: displayColumn("isActive", tableName),
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Box data-toggle="tooltip" title={value} style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", width: "50px" }}>
              {handleShowCheck(value)}
            </Box>
          );
        },
      },
    },
    {
      name: "Action",
      label: "ACTION",
      options: {
        customBodyRender: (data, tableMeta, updateValue) => {
          return (
            <Box sx={{ maxWidth: "100px" }}>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={6}>
                <Button
                  data-toggle="tooltip"
                  title="Modifier"
                  onClick={() => editUser(tableMeta)}
                  sx={{
                    color: "#C8D148",
                    minWidth: "auto",
                    width: "35px", 
                    height: "35px",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Edit />
                </Button>
                </Grid>
                <Grid item xs={6}>
                  {handleShowArchiveButton(tableMeta.rowData[0], tableMeta.rowData[9])}
                </Grid>
              </Grid>
            </Box>
          );
        },
      },
    },
  ];

  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setViewportHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isSmall = useMediaQuery((theme) => theme.breakpoints.between(0, 767));
  const isMediumMode = useMediaQuery((theme) => theme.breakpoints.between(767, 1023));
  const isLargeMode = useMediaQuery((theme) => theme.breakpoints.between(1023, 2800));

  const tableBodyMaxHeight = `${isSmall ? viewportHeight - 292 : isMediumMode ? viewportHeight - 370 : isLargeMode ? viewportHeight - 340 : viewportHeight - 340}px`;


  const options = {
    tableBodyMaxHeight: tableBodyMaxHeight,
    rowsPerPageOptions: [],
    rowsPerPage: 50,
    textLabels: {
      body: {
        noMatch: loading ? <CircularProgress /> : "Désolé, il n'y a aucun enregistrement dans la base de données pour le moment.",
      },
    },
    onViewColumnsChange: (columnName, action) => {
      handleOnViewColumnsChange(columnName, action, tableName);
    },
    selectableRows: "none",
    draggableColumns: {
      enabled: true,
    },
    filterType: "dropdown"
  };
  const isSmMode = useMediaQuery(theme.breakpoints.between(0, 480));

  const handleAddUser = () => {
    history.push("/users/add")
  }

  return (
    <Box sx={{ padding: "0 20px " }}>
      <Grid container style={{ paddingBottom: "5px" }}>
        <Grid item xs={10} sm={6} md={6} xl={6}>
          <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
            <h2 className="text-capitalize" style={{ color: "rgb(17, 122, 167)" }}>
              LISTE DES UTILISATEURS

            </h2>
          </Box>
        </Grid>
        <Grid item xs={2} sm={6} md={6} xl={6}>
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            {!isSmMode ?
              <Button startIcon={<PersonAdd />} onClick={() => handleAddUser()} className={classes.archiveButton}>
                UTILISATEUR
              </Button>
              : <IconButton size="medium" data-toggle="tooltip"
                title="Ajouter">
                <Add />
              </IconButton>}
          </Box>
        </Grid>
      </Grid>
      <Box className="user_container">
        <MUIDataTable data={users} columns={columns} options={options} />
      </Box>
    </Box >
  );
};

export default withSnackbar(Listeruser);
