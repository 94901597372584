import React, { useEffect, useState } from 'react'

import { styled } from '@mui/material/styles';
import { Backdrop, Box, Button } from '@mui/material';
import Modal from '@mui/material/Modal';
import { getCvBlob } from '../../Services/FileService';
import { Description } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Fade } from '@mui/material';
import { withSnackbar } from '../../../utils/SnackbarHOC';
import PdfPreview from "./PDFPreview";

const useStyles = styled((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        borderRadius: "20px",
        width: "60%",
        height: "95%",
    },
    buttonLabel: {
        marginLeft: 0,
    },
    closeButton: {
        color: "#1b2a4e",
        backgroundColor: "#d9e2ef",
        borderColor: "#d9e2ef",
        marginRight: "0.5em",
        fontWeight: "500",
    },
    actionBar: {
        marginTop: "1.5em",
    },
    modalTitle: {
        color: "rgb(17 122 167)",
        marginBottom: "1em",
    },
    buttonStyle: {
        color: "#2196f3",
        paddingLeft: "0px"
    }
}));

function ShowAddCvCandidat({ children, snackbarShowMessage, files, editMode = false, ...otherProps }) {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const classes = useStyles();
    const [iframe, setIframe] = useState('');
    const searchCv = files?.filter((cv) => cv.categorie === "cv")

    const handleShowButton = () => {
        return <Box sx={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
            {searchCv?.length > 0
                ? (<LoadingButton
                    data-toggle="tooltip" title={"Ouvrir CV."}
                    className={classes.buttonStyle}
                    onClick={handleOpen}
                    variant='text'
                    size="medium"
                    loading={loading}
                    loadingPosition="start"
                    startIcon={<Description />}
                >
                    <span className={classes.buttonLabel}>{children ?? ""}</span>
                </LoadingButton >)
                : <span className={classes.buttonLabel}>{children ?? "CV"}</span>}
        </Box>
    }

    const getIframe = async (cv) => {
        const res = await getCvBlob(cv)
        const fileUrl = window.URL.createObjectURL(res.data);
        const filename = res.config.url.split('/').pop();
        return (
            <div>
                <PdfPreview fileUrl={fileUrl} fileName={filename}/>
            </div>
        );
    }

    const handleOpen = async () => {
        try {
            setLoading(true)
            const iframe = await getIframe(searchCv[0]?.image_name ?? searchCv[0]?.imageName)
            setIframe(iframe)
            setOpen(true);
        } catch (e) {
            snackbarShowMessage("Impossible de charger le cv, fichier physique introuvable", "error", 4000)
        }
        finally {
            setLoading(false)
        }
    };

    const handleClose = () => setOpen(false);

    const body = (
        <div className={classes.paper}>
            <h2 className={classes.modalTitle}>
                CV CANDIDAT
            </h2>
            {iframe}
            <div className={classes.actionBar}>
                <Button className={classes.closeButton} onClick={handleClose}>
                    Close
                </Button>
            </div>
        </div>
    );
    return (
        <>
            {handleShowButton()}
            <Modal
                className={classes.modal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                open={open}
                onClose={handleClose}
            >
                <Fade in={open}>{body}</Fade>
            </Modal>
        </>
    );
}
export default withSnackbar(ShowAddCvCandidat);
