import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Box
} from '@mui/material';
import { Close } from '@mui/icons-material';

/**
 * Composant Modal générique et réutilisable
 * @param {Object} props - Les propriétés du composant
 * @param {boolean} props.open - État d'ouverture du modal
 * @param {function} props.onClose - Fonction appelée à la fermeture
 * @param {string} props.title - Titre du modal
 * @param {React.ReactNode} props.children - Contenu du modal
 * @param {React.ReactNode} props.actions - Actions du modal (boutons dans le footer)
 * @param {string} props.maxWidth - Largeur maximale du modal ('xs' | 'sm' | 'md' | 'lg' | 'xl')
 * @param {boolean} props.fullWidth - Si true, le modal prend toute la largeur disponible
 * @param {Object} props.contentProps - Props supplémentaires pour le DialogContent
 */
const Modal = ({
  open,
  onClose,
  title,
  children,
  actions,
  maxWidth = 'sm',
  fullWidth = true,
  contentProps = {}
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      sx={{
        '& .MuiDialog-paper': {
          margin: 2,
          maxHeight: 'calc(100% - 32px)'
        },
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(0, 0, 0, 0.5)'
        }
      }}
    >
      {title && (
        <DialogTitle>
          <Box sx={{ pr: 4 }}>{title}</Box>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
      )}

      <DialogContent {...contentProps}>
        {children}
      </DialogContent>

      {actions && (
        <DialogActions>
          {actions}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default Modal;
