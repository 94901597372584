import React from 'react';
import MUIDataTable from 'mui-datatables';
import { styled } from '@mui/material/styles';
import { Box, CircularProgress, Typography } from '@mui/material';
import PropTypes from 'prop-types';

const TableWrapper = styled(Box)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[2],
  },
  '& .MuiToolbar-root': {
    padding: theme.spacing(2),
  },
  '& .MuiTableCell-head': {
    fontWeight: 600,
    backgroundColor: theme.palette.background.default,
  },
  '& .MuiTableRow-root:hover': {
    backgroundColor: `${theme.palette.action.hover} !important`,
  },
}));

const LoaderContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: 400,
});

const NoDataContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: 400,
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
}));

const Table = ({
  title,
  data,
  columns,
  options: customOptions,
  loading = false,
  error = null,
  onRowClick,
  onSelectionChange,
  onColumnSortChange,
  onSearchChange,
  onFilterChange,
  components,
  ...props
}) => {
  const defaultOptions = {
    filterType: 'multiselect',
    responsive: 'standard',
    selectableRows: 'multiple',
    download: true,
    print: true,
    viewColumns: true,
    filter: true,
    search: true,
    pagination: true,
    rowsPerPage: 10,
    rowsPerPageOptions: [10, 25, 50, 100],
    textLabels: {
      body: {
        noMatch: "Désolé, aucun enregistrement correspondant n'a été trouvé",
        toolTip: "Trier",
      },
      pagination: {
        next: "Page suivante",
        previous: "Page précédente",
        rowsPerPage: "Lignes par page:",
        displayRows: "sur",
      },
      toolbar: {
        search: "Rechercher",
        downloadCsv: "Télécharger CSV",
        print: "Imprimer",
        viewColumns: "Afficher les colonnes",
        filterTable: "Filtrer",
      },
      filter: {
        all: "Tous",
        title: "FILTRES",
        reset: "RÉINITIALISER",
      },
      viewColumns: {
        title: "Afficher les colonnes",
        titleAria: "Afficher/Masquer les colonnes",
      },
      selectedRows: {
        text: "ligne(s) sélectionnée(s)",
        delete: "Supprimer",
        deleteAria: "Supprimer les lignes sélectionnées",
      },
    },
    ...customOptions,
    onRowClick: (rowData, rowMeta) => {
      if (onRowClick) {
        onRowClick(rowData, rowMeta);
      }
    },
    onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
      if (onSelectionChange) {
        onSelectionChange(currentRowsSelected, allRowsSelected, rowsSelected);
      }
    },
    onColumnSortChange: (changedColumn, direction) => {
      if (onColumnSortChange) {
        onColumnSortChange(changedColumn, direction);
      }
    },
    onSearchChange: (searchText) => {
      if (onSearchChange) {
        onSearchChange(searchText);
      }
    },
    onFilterChange: (changedColumn, filterList) => {
      if (onFilterChange) {
        onFilterChange(changedColumn, filterList);
      }
    },
    ...components,
  };

  if (loading) {
    return (
      <LoaderContainer>
        <CircularProgress />
      </LoaderContainer>
    );
  }

  if (error) {
    return (
      <NoDataContainer>
        <Typography variant="h6" color="error" gutterBottom>
          Une erreur s'est produite
        </Typography>
        <Typography color="textSecondary">{error}</Typography>
      </NoDataContainer>
    );
  }

  if (!data || data.length === 0) {
    return (
      <NoDataContainer>
        <Typography variant="h6" gutterBottom>
          Aucune donnée disponible
        </Typography>
        <Typography color="textSecondary">
          Aucun enregistrement n'a été trouvé
        </Typography>
      </NoDataContainer>
    );
  }

  return (
    <TableWrapper>
      <MUIDataTable
        title={title}
        data={data}
        columns={columns}
        options={defaultOptions}
        {...props}
      />
    </TableWrapper>
  );
};

Table.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  options: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.string,
  onRowClick: PropTypes.func,
  onSelectionChange: PropTypes.func,
  onColumnSortChange: PropTypes.func,
  onSearchChange: PropTypes.func,
  onFilterChange: PropTypes.func,
  components: PropTypes.object,
};

export default Table;
