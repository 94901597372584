import React, { useState } from "react";
import { Link, useParams, withRouter } from "react-router-dom";
import { getContsoci } from "../../../Services/ContactService";
import { Edit, LinkedIn } from '@mui/icons-material';
import { Box, Button, Card, CircularProgress } from '@mui/material';
import MUIDataTable from "mui-datatables";
import { IconButton } from '@mui/material';


const CONTACT = ({ history, nomSociete }) => {
  const [contacts, setContacts] = useState();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);

  const handlePageChange = (page) => {
    setPage(page + 1);
  };

  React.useEffect(() => {
    let isActive = true;

    (async () => {
      setLoading(true);

      try {
        let societeData = await getContsoci(id, page);
        if (!isActive) {
          return;
        }

        setContacts(societeData.data.contactsOfSociete);
        setCount(societeData.data.count);
        setLoading(false);
      } catch (error) {
        if (!isActive) {
          return;
        }
        setLoading(false);
      }
    })();

    return () => {
      isActive = false;
    };
  }, [id, page]);

  const editContact = (contactId) => {
    history.push({
      pathname: "/societe/" + id + "/contacts/" + contactId + "/edit",
      state: {
        nomSociete: nomSociete,
      },
    });
  };

  const columns = [
    {
      name: "uuid",
      label: "N°CONTACT",
      options: {
        display: false,
        setCellHeaderProps: () => ({ style: { padding: "2px 12px", textAlign: "center" } }),
        setCellProps: () => ({ style: { padding: "0px 12px", whiteSpace: "nowrap", textOverFlow: "ellipsis", overflow: "hidden" } }),
      },
    },
    {
      name: "titre",
      label: "TITRE",
      options: {
        setCellHeaderProps: () => ({ style: { padding: "2px 12px", textAlign: "center", width: "10%" } }),
        setCellProps: () => ({ style: { padding: "0px 12px", whiteSpace: "nowrap", textOverFlow: "ellipsis", overflow: "hidden", width: "10%" } }),
      },
    },
    {
      name: "prenom",
      label: "PRÉNOM",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <Link to={{ pathname: `/societe/${id}/contacts/${tableMeta.rowData[0]}/view` }}>
            {(value).charAt(0).toUpperCase() + (value).slice(1)}
          </Link>
        ),
        setCellHeaderProps: () => ({ style: { padding: "2px 12px", textAlign: "center", width: "30%" } }),
        setCellProps: () => ({ style: { padding: "0px 12px", whiteSpace: "nowrap", textOverFlow: "ellipsis", overflow: "hidden", width: "30%" } }),
      },
    },
    {
      name: "nom",
      label: "NOM",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => (
          <Link to={{ pathname: `/societe/${id}/contacts/${tableMeta.rowData[0]}/view` }}>
            {value}
          </Link>
        ),
        setCellHeaderProps: () => ({ style: { padding: "2px 12px", textAlign: "center", width: "25%" } }),
        setCellProps: () => ({ style: { padding: "0px 12px", whiteSpace: "nowrap", textOverFlow: "ellipsis", overflow: "hidden", width: "25%" } }),
      },
    },
    {
      name: "fonction",
      label: "FONCTION",
      options: {
        setCellHeaderProps: () => ({ style: { padding: "2px 12px", textAlign: "center", width: "30%" } }),
        setCellProps: () => ({ style: { padding: "0px 12px", whiteSpace: "nowrap", textOverFlow: "ellipsis", overflow: "hidden", width: "30%" } }),
      },
    },
    {
      name: "mobile",
      label: "MOBILE",
      options: {
        setCellHeaderProps: () => ({ style: { padding: "2px 12px", textAlign: "center", width: "30%" } }),
        setCellProps: () => ({ style: { padding: "0px 12px", whiteSpace: "nowrap", textOverFlow: "ellipsis", overflow: "hidden", width: "30%" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Box
              data-toggle="tooltip"
              title={value}
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                width: "150px",
              }}
            >
              {value ?? "-"}
            </Box>
          );
        },
      }
    },
    {
      name: "email",
      label: "EMAIL",
      options: {
        setCellHeaderProps: () => ({ style: { padding: "2px 12px", textAlign: "center", width: "30%" } }),
        setCellProps: () => ({ style: { padding: "0px 12px", whiteSpace: "nowrap", textOverFlow: "ellipsis", overflow: "hidden", width: "30%" } }),
      }
    },
    {
      name: "linkedIn",
      label: "LINKEDIN",
      options: {
        setCellHeaderProps: () => ({ style: { padding: "2px 12px", textAlign: "center", width: "30%" } }),
        setCellProps: () => ({ style: { padding: "0px 12px", whiteSpace: "nowrap", textOverFlow: "ellipsis", overflow: "hidden", width: "30%" } }),
        customBodyRender: (value) => (
          <a href={value} target="_blank" rel="noreferrer">
            <LinkedIn />
          </a>
        )
      },
    },
    // ... (outros campos)
    {
      name: "Action",
      label: "ACTIONS",
      options: {
        customBodyRender: (data, tableMeta, updateValue) => (
          <IconButton data-toggle="tooltip"
            title="Modifier" size="medium" onClick={() => editContact(tableMeta.rowData[0])} >
            <Edit style={{ color: "#C8D148" }} />
          </IconButton>
        ),
        setCellHeaderProps: () => ({ style: { padding: "2px 12px", textAlign: "center", width: "5%" } }),
        setCellProps: () => ({ style: { padding: "0px 12px", whiteSpace: "nowrap", textOverFlow: "ellipsis", overflow: "hidden", width: "5%" } }),
      },
    },
  ];
  const options = {
    tableBodyMaxHeight: "47vh",
    count: count,
    serverSide: true,
    rowsPerPage: 50,
    textLabels: {
      body: {
        noMatch: loading ? <CircularProgress /> : "Désolé, il n'y a aucun enregistrement dans la base de données pour le moment.",
      },
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case "changePage":
          handlePageChange(tableState.page);
          break;
        default:
      }
    },
    selectableRows: "none",
    draggableColumns: {
      enabled: true,
    },
    filterType: "dropdown",
    filter: false,
    print: false,
    columns: false,
    responsive: "standard",
    search: false,
    download: false,
    viewColumns: false,
  };

  return (
    <>
      <Button onClick={() => history.push(`/societe/${id}/contacts/add`, { nomSociete: nomSociete })} style={{ backgroundColor: "rgb(17 122 167)", color: "#fff", marginBottom: "1em" }} size="small" variant={"contained"}>
        Ajouter un contact
      </Button>
      <Card>
        <MUIDataTable data={contacts} columns={columns} options={options} />
      </Card>
    </>
  );
};

export default withRouter(CONTACT);
