import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Typography,
  Tooltip,
  IconButton,
  Chip,
  Link,
  CircularProgress,
  useTheme,
  useMediaQuery
} from "@mui/material";
import {
  RemoveRedEye as RemoveRedEyeIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import Table from "../../../components/Table/Table";
import { Link as RouterLink } from "react-router-dom";
import moment from "moment";
import "moment/locale/fr";
import { getRowData } from './../../../utils/FunctionHelpers.js'
moment.locale("fr");

const ActionButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(1),
  '&:hover': {
    backgroundColor: theme.palette.action.hover
  }
}));

const StyledChip = styled(Chip)(({ theme, color }) => ({
  borderRadius: theme.shape.borderRadius,
  fontWeight: 500,
  textTransform: 'uppercase',
  fontSize: '0.75rem',
  height: 24,
  ...(color === 'success' && {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.dark,
  }),
  ...(color === 'warning' && {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.dark,
  }),
  ...(color === 'error' && {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.dark,
  })
}));

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline'
  }
}));

const StyledTableCell = styled(Box)(({ theme }) => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  padding: theme.spacing(1),
  '& .MuiTypography-root': {
    fontSize: '0.875rem'
  }
}));

const MissionsTable = ({
  missionData,
  editMission,
  loading,
  error
}) => {
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const theme = useTheme();
  const [sorting, setSorting] = useState(() => {
    const savedSorting = localStorage.getItem('missionsTableSorting');
    return savedSorting ? JSON.parse(savedSorting) : { name: '', order: 'asc' };
  });

  useEffect(() => {
    const handleResize = () => {
      setViewportHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isSmall = useMediaQuery((theme) => theme.breakpoints.between(0, 767));
  const isMediumMode = useMediaQuery((theme) => theme.breakpoints.between(767, 1023));
  const isLargeMode = useMediaQuery((theme) => theme.breakpoints.between(1023, 2800));

  const tableBodyMaxHeight = `${isSmall ? viewportHeight - 320 : isMediumMode ? viewportHeight - 460 : isLargeMode ? viewportHeight - 460 : viewportHeight - 460}px`;

  const columns = [
    {
      name: "uuid",
      label: "N°",
      options: {
        display: false,
      },
    },
    {
      name: "societeId",
      label: "societeId",
      options: {
        display: false,
      },
    }, {
      name: "numMission",
      label: "N°",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          const rowData = getRowData(tableMeta, columns);
          return (
            <StyledTableCell sx={{ width: "3rem" }}>
              <StyledLink
                component={RouterLink}
                to={`/societe/${rowData.numMission}/missions/${rowData.uuid}/view`}
              >
                {value}
              </StyledLink>
            </StyledTableCell>
          );
        }
      },
    },
    {
      name: "client",
      label: "CLIENT",
      options: {
        filter: true,
        sort: true,
        sortDirection: sorting.name === 'client' ? sorting.order : undefined,
        customBodyRender: (value, tableMeta) => {
          const rowData = getRowData(tableMeta, columns);
          return (
            <StyledTableCell sx={{ width: "100px" }}>
              <StyledLink
                component={RouterLink}
                to={`/societe/${rowData.societeId}/view`}
              >
                {value || ""}
              </StyledLink>
            </StyledTableCell>
          );
        }
      },
    },
    {
      name: "nom_mission",
      label: "NOM MISSION",
      options: {
        filter: true,
        sort: true,
        sortDirection: sorting.name === 'nom_mission' ? sorting.order : undefined,
        setCellHeaderProps: () => ({ style: { whiteSpace: "nowrap" } }),
        customBodyRender: (value, tableMeta) => {
          const rowData = getRowData(tableMeta, columns);
          return (
            <StyledTableCell sx={{ width: "270px" }}>
              <StyledLink
                component={RouterLink}
                to={`/societe/${rowData.societeId}/missions/${rowData.uuid}/view`}
              >
                {value}
              </StyledLink>
            </StyledTableCell>
          );
        }
      },
    },
    {
      name: "CV",
      label: "CV EN COURS",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({ style: { whiteSpace: "nowrap" } }),
        customBodyRender: (value, tableMeta) => {
          const rowData = getRowData(tableMeta, columns);
          return (
              <StyledTableCell sx={{ width: "100px", display: "flex", justifyContent: "center" }}>
                <StyledLink
                    component={RouterLink}
                    to={{
                      pathname: `/candidats/missions/${rowData.uuid}/list`,
                      search: `?quantity=${value}`,
                    }}
                >
                  {value}
                </StyledLink>
              </StyledTableCell>
          );
        },
      },
    },
    {
      name: "localisation",
      label: "LOCALISATION",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => (
          <StyledTableCell sx={{ width: "150px" }}>
            <Typography variant="body2">
              {value === null || value === undefined || value === "" ? "-" : value}
            </Typography>
          </StyledTableCell>
        ),
      },
    },
    {
      name: "status",
      label: "STATUS",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => (
          <StyledChip label={value || "N/A"} color={getStatusColor(value)} />
        ),
      },
    },
    {
      name: "priorite",
      label: "PRIORITE",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => (
          <StyledTableCell sx={{ width: "150px" }}>
            <Typography variant="body2">
              {value ?? "-"}
            </Typography>
          </StyledTableCell>
        ),
      },
    },
    {
      name: "createdAt",
      label: "DATE DEBUT",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          const validDate = moment(value?.date);
          return (
            <StyledTableCell sx={{ width: "150px" }}>
              {validDate.isValid() ? validDate.format('DD/MM/YYYY') : "-"}
            </StyledTableCell>
          );
        },
        sortCompare: (order) => {
          return (obj1, obj2) => {
            const date1 = moment(obj1.data.date);
            const date2 = moment(obj2.data.date);

            if (!date1.isValid() && !date2.isValid()) return 0;
            if (!date1.isValid()) return order === 'asc' ? -1 : 1;
            if (!date2.isValid()) return order === 'asc' ? 1 : -1;

            return (date1 - date2) * (order === 'asc' ? 1 : -1);
          };
        },
      },
    },
    {
      name: "contact",
      label: "CONTACT",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          const rowData = getRowData(tableMeta, columns);
          return (
            <StyledTableCell sx={{ width: "100px" }}>
              <StyledLink
                component={RouterLink}
                to={`/societe/${rowData.societeId}/contacts/${rowData.contactId}/view`}
              >
                {value}
              </StyledLink>
            </StyledTableCell>
          );
        },
      },
    },
    {
      name: "sourceur",
      label: "SOURCEUR",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => (
          <StyledTableCell sx={{ width: "150px" }}>
            <Typography variant="body2">
              {value ?? "-"}
            </Typography>
          </StyledTableCell>
        ),
      },
    },
    {
      name: "consultant",
      label: "TALENT MANAGER",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({ style: { whiteSpace: "nowrap" } }),
      },
    },
    {
      name: "accountManager",
      label: "DELIVERY MANAGER",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({ style: { whiteSpace: "nowrap" } }),
      },
    },
    {
      name: "actions",
      label: "ACTION",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          const rowData = getRowData(tableMeta, columns);
          return (
            <StyledTableCell sx={{ width: "2rem" }}>
              <Tooltip title="Modifier">
                <ActionButton
                  onClick={() => editMission(rowData.uuid, rowData.societeId)}
                  sx={{ color: "#C8D148" }}
                >
                  <EditIcon />
                </ActionButton>
              </Tooltip>
            </StyledTableCell>
          );
        },
      },
    },
    {
      name: "contactId",
      label: "contact",
      options: {
        display: false,
      },
    },
  ];

  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case "en cours":
        return 'warning';
      case "terminée":
        return 'success';
      case "annulée":
        return 'error';
      default:
        return 'info';
    }
  };

  const options = {
    tableBodyMaxHeight,
    rowsPerPage: 50,
    textLabels: {
      body: {
        noMatch: loading ? <CircularProgress /> : "Désolé, il n'y a aucun enregistrement dans la base de données pour le moment.",
      },
    },
    selectableRows: "none",
    draggableColumns: {
      enabled: true,
    },
    download: true,
    print: true,
    viewColumns: true,
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    rowsPerPageOptions: [50, 100, 200],
    onTableChange: (action, tableState) => {
      switch (action) {
        case "sort":
            const { name, direction } = tableState.sortOrder;
            if (name) {
              setSorting({ name, order: direction });
              localStorage.setItem('missionsTableSorting', JSON.stringify({ name, order: direction }));
            }
          break;
        default:
      }
    },
    sortOrder: { name: sorting.name, direction: sorting.order},

  };

  return (
    <Table
      title="Liste des missions"
      data={missionData?.data || []}
      columns={columns}
      options={options}
      loading={loading}
      error={error}
    />
  );
};

export default MissionsTable;
