import { Box, styled } from '@mui/material';
import React from 'react'
import { FileUploader } from 'react-drag-drop-files'
import { withSnackbar } from '../../../../utils/SnackbarHOC'
import { FileMetaData } from '../../../Candidat/CandidatForm/Controls/InputFile/InputFile.styles';
import { PreviewContainer } from '../../../Candidat/VisualiserCandidat/Controls/previewfiles.styles';

const fileTypes = ["XLSX"];

const useStyles = styled((theme) => ({
    fileUploader: {
        maxWidth: 'initial !important'
    },
    asside: {
        marginTop: 'initial !important'
    },
    FileMetaData: {
        justifyContent: "space-between"
    }
}))

const Step2 = ({ importData, setImportData, snackbarShowMessage }) => {
    const classes = useStyles();
    
    const handleChange = (event) => {
        setImportData((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value
        }))
    }

    const handleCandidatsFileChange = (file) => {
        handleChange({
            target: {
                name: 'candidatsFile',
                value: file
            }
        })
    }

    return (
        <>
            <FileUploader
                handleChange={handleCandidatsFileChange}
                name="file"
                types={fileTypes}
                label={"IMPORTER LE FICHIER DES CANDIDATS"}
                classes={classes.fileUploader}
            />
            {
                importData?.candidatsFile
                ? <PreviewContainer>
                    <Box>
                        <FileMetaData className={classes.FileMetaData}>
                            <span>{importData?.candidatsFile.name}</span>
                        </FileMetaData>
                    </Box>
                </PreviewContainer>
                : ""
            }

        </>
    )
}

export default withSnackbar(Step2)