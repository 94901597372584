import { Button } from '@mui/material';
import { useEffect, useState } from "react";
import CustomAutoComplete from "../../../common/CustomAutoComplete";
import { updateObjectStateValue } from "../../../utils/FunctionHelpers";
import { addCandidatsToSelection, getAllCadidatSelections } from "../../Services/SelectionCandidatService";
import DialogDeleteCustom from "../../../components/DialogDeleteCustom/DialogDeleteCustom";
import Modal from "../../../components/Modal/Modal";

const ChooseSelectionModal = ({ children, selectedCandidats, snackbarShowMessage, history }) => {
    const [open, setOpen] = useState(false);
    const [selections, setSelections] = useState([]);
    const [selectedSelection, setSelectedSelection] = useState(null);
    const [loading, setLoading] = useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedSelection(null);
    };

    const handleSave = async () => {
        if (!selectedSelection) {
            snackbarShowMessage("Veuillez sélectionner une sélection", "error");
            return;
        }

        setLoading(true);
        try {
            await addCandidatsToSelection(selectedSelection.id, selectedCandidats);
            snackbarShowMessage("Candidats ajoutés avec succès", "success");
            handleClose();
            history.push("/selection/" + selectedSelection.id);
        } catch (error) {
            snackbarShowMessage("Erreur lors de l'ajout des candidats", "error");
        }
        setLoading(false);
    };

    const loadSelections = async () => {
        try {
            const response = await getAllCadidatSelections();
            setSelections(response.data);
        } catch (error) {
            snackbarShowMessage("Erreur lors du chargement des sélections", "error");
        }
    };

    useEffect(() => {
        if (open) loadSelections();
    }, [open]);

    const modalContent = (
        <div>
            <CustomAutoComplete
                options={selections}
                value={selectedSelection}
                onChange={(event, newValue) => {
                    setSelectedSelection(newValue);
                }}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                    <div ref={params.InputProps.ref}>
                        <input
                            type="text"
                            {...params.inputProps}
                            className="form-control"
                            placeholder="Sélectionner une sélection"
                        />
                    </div>
                )}
            />
        </div>
    );

    const modalActions = (
        <>
            <Button
                variant="contained"
                onClick={handleSave}
                disabled={loading}
                sx={{
                    backgroundColor: "rgb(66, 186, 150)",
                    color: "#FFF",
                    "&:hover": {
                        backgroundColor: "#389e7f",
                    }
                }}
            >
                Enregistrer
            </Button>
            <Button
                variant="contained"
                onClick={handleClose}
                sx={{
                    marginLeft: 1,
                    backgroundColor: "#d9e2ef",
                    color: "#1b2a4e",
                    "&:hover": {
                        backgroundColor: "#c1cfe0",
                    }
                }}
            >
                Annuler
            </Button>
        </>
    );

    return (
        <>
            <div onClick={handleOpen}>
                {children}
            </div>
            <Modal
                open={open}
                onClose={handleClose}
                title="Choisir une sélection"
                content={modalContent}
                actions={modalActions}
            />
            <DialogDeleteCustom
                open={openDeleteDialog}
                onClose={() => setOpenDeleteDialog(false)}
                onConfirm={() => {
                    setOpenDeleteDialog(false);
                    handleClose();
                }}
                title="Supprimer la sélection"
                content="Êtes-vous sûr de vouloir supprimer cette sélection ?"
            />
        </>
    );
};

export default ChooseSelectionModal;