import React, { useState } from "react";
import {
  Button,
  Card,
  CircularProgress,
  FormControlLabel,
  Switch
} from '@mui/material';
import MUIDataTable from "mui-datatables";
import { Link, useParams, withRouter } from "react-router-dom";
import moment from "moment";
import { getMissionsOfSociete } from "../../../Services/MissionService";
import { Box } from '@mui/material';
import { withSnackbar } from "../../../../utils/SnackbarHOC";
import { theme } from "../../../../utils/Theme";
import { useMediaQuery } from '@mui/material';
import { DoneAll } from "@mui/icons-material";


const Missions = ({ snackbarShowMessage, history }) => {
  const [societe, setSociete] = useState([]);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [missionsTermines, setMissionsTermines] = useState("");

  const handlePageChange = (page) => {
    setPage(page + 1);
  };

  React.useEffect(() => {
    let isActive = true;

    const fetchMissions = async () => {
      setLoading(true);

      try {
        const response = await getMissionsOfSociete(
          id,
          page,
          missionsTermines ? "passive" : "active"
        );

        if (!isActive) {
          return;
        }

        const tab = response.data.missions.map((value) => ({
          consultant: value.consultant,
          contact: {
            name: value.contact,
            id: value.contactId,
          },
          createdAt: value.createdAt,
          nom_mission: value.nom_mission,
          pitch_mission: value.pitch_mission,
          statut: value.statut,
          uuid: value.uuid,
          num_mission: value.numMission,
          CV: value.CV,
          selection: value.selection,
          idContact: value.idContact,
          priorite: value.priorite
        }));

        setSociete(tab);
        setCount(response.data.count);
        setLoading(false);
      } catch (error) {
        if (!isActive) {
          return;
        }
        snackbarShowMessage("Erreur de chargement des missions de la société.", "error", 4000);
        setLoading(false);
      }
    };

    fetchMissions();

    return () => {
      isActive = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, page, missionsTermines]);

  const isSmall = useMediaQuery(theme.breakpoints.between(0, 767));

  const columns = [
    {
      name: "uuid",
      label: "N°",
      options: {
        display: false,
        setCellHeaderProps: () => ({ style: { padding: "2px 12px", textAlign: "center" } }),
        setCellProps: () => ({ style: { whiteSpace: "nowrap", textOverFlow: "ellipsis", overflow: "hidden" } }),
      },
    },
    {
      name: "num_mission",
      label: "N° ",
      options: {
        setCellHeaderProps: () => ({ style: { padding: "2px 12px", textAlign: "center" } }),
        setCellProps: () => ({ style: { padding: "0px 12px", whiteSpace: "nowrap", textOverFlow: "ellipsis", overflow: "hidden" } }),
      },
    },
    {
      name: "nom_mission",
      label: "MISSION",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Link to={{ pathname: `/societe/${id}/missions/${tableMeta.rowData[0]}/view` }}>{value}</Link>
          );
        },
        setCellHeaderProps: () => ({ style: { padding: "2px 12px", textAlign: "center" } }),
        setCellProps: () => ({ style: { padding: "0px 12px", whiteSpace: "nowrap", textOverFlow: "ellipsis", overflow: "hidden" } }),
      },
    },
    {
      name: "priorite",
      label: "PRIORITE",
      options: {
        customBodyRender: (value) => {
          return (
              <>
                {value == null ? 0 : value}
              </>
          );
        },
        setCellHeaderProps: () => ({ style: { padding: "2px 12px", textAlign: "center", maxWidth: "100px" } }),
        setCellProps: () => ({ style: { padding: "0px 12px", whiteSpace: "nowrap", textOverFlow: "ellipsis", overflow: "hidden", maxWidth: "100px" } }),
      },
    },
    {
      name: "statut",
      label: "STATUT",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              {value}
            </>
          );
        },
        setCellHeaderProps: () => ({ style: { padding: "2px 12px", textAlign: "center", maxWidth: "100px" } }),
        setCellProps: () => ({ style: { padding: "0px 12px", whiteSpace: "nowrap", textOverFlow: "ellipsis", overflow: "hidden", maxWidth: "100px" } }),
      },
    },
    {
      name: "createdAt",
      label: "CRÉÉE LE",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>
            {value ? moment(value.date).format("DD/MM/yyyy") : "-"}
          </>;
        },
        setCellHeaderProps: () => ({ style: { padding: "2px 12px", whiteSpace: "nowrap", textOverFlow: "ellipsis", textAlign: "center" } }),
        setCellProps: () => ({ style: { padding: "0px 12px", whiteSpace: "nowrap", textOverFlow: "ellipsis", overflow: "hidden" } }),
      },
    },
    {
      name: "consultant",
      label: "TALENT MANAGER",
      options: {
        setCellHeaderProps: () => ({ style: { padding: "2px 12px", whiteSpace: "nowrap", textOverFlow: "ellipsis", textAlign: "center" } }),
        setCellProps: () => ({ style: { padding: "0px 12px", whiteSpace: "nowrap", textOverFlow: "ellipsis", overflow: "hidden" } }),
      },
    },
    {
      name: "sourceur",
      label: "SOURCEUR",
      options: {
        setCellHeaderProps: () => ({ style: { padding: "2px 12px", whiteSpace: "nowrap", textOverFlow: "ellipsis", textAlign: "center" } }),
        setCellProps: () => ({ style: { padding: "0px 12px", whiteSpace: "nowrap", textOverFlow: "ellipsis", overflow: "hidden" } }),
      },
    },
    {
      name: "CV",
      label: "CV EN COURS",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Box data-toggle="tooltip" title={value} style={{ whiteSpace: "nowrap", textOverFlow: "ellipsis", overflow: "hidden" }}>
              <Link to={{
                pathname: `/candidats/missions/${tableMeta.rowData[0]}/list`,
              }}>{value}</Link>
            </Box>
          );
        },
        setCellHeaderProps: () => ({ style: { padding: "2px 12px", whiteSpace: "nowrap", textOverFlow: "ellipsis", textAlign: "center" } }),
        setCellProps: () => ({ style: { padding: "0px 12px", whiteSpace: "nowrap", textOverFlow: "ellipsis", overflow: "hidden" } }),
      },
    },
    {
      name: "selection",
      label: "SELECTION",
      options: {
        setCellHeaderProps: () => ({ style: { padding: "2px 12px", whiteSpace: "nowrap", textOverFlow: "ellipsis", textAlign: "center" } }),
        setCellProps: () => ({ style: { padding: "0px 12px", whiteSpace: "nowrap", textOverFlow: "ellipsis", overflow: "hidden" } }),
      },
    },
    {
      name: "idContact",
      label: "N° Contact",
      options: {
        display: false,
        setCellHeaderProps: () => ({ style: { padding: "2px 12px", textAlign: "center" } }),
        setCellProps: () => ({ style: { padding: "0px 12px", whiteSpace: "nowrap", textOverFlow: "ellipsis", overflow: "hidden" } }),
      },
    },
  ];

  const options = {
    tableBodyMaxHeight: "44vh",
    count: count,
    serverSide: true,
    rowsPerPage: 50,
    textLabels: {
      body: {
        noMatch: loading ? <CircularProgress /> : "Désolé, il n'y a aucun enregistrement dans la base de données pour le moment.",
      },
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case "changePage":
          handlePageChange(tableState.page);
          break;
        default:
      }
    },
    selectableRows: "none",
    draggableColumns: {
      enabled: true,
    },
    filterType: "dropdown",
    filter: false,
    print: false,
    columns: false,
    responsive: "standard",
    search: false,
    download: false,
    viewColumns: false,
  };
  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Button onClick={() => history.push(`/societe/${id}/missions/add`)} style={{ backgroundColor: "rgb(17 122 167)", color: "#fff", marginBottom: "1em" }} size="small" variant={"contained"}>
          Ajouter une mission
        </Button>
        <Box>
          {!isSmall ? <>
            <label className="input-label" htmlFor="tachesTermines">
              LES MISSIONS TERMINÉES
            </label>
            <FormControlLabel
              control={
                <Switch
                  checked={missionsTermines}
                  onChange={(e) => setMissionsTermines(e.target.checked)}
                  color="primary"
                />
              }
              style={{
                marginLeft: '0.2em',
                marginRight: '2rem',
              }}
            />
          </>
            : <FormControlLabel
              control={
                <Switch
                  checked={missionsTermines}
                  onChange={(e) => setMissionsTermines(e.target.checked)}
                  name="tachesTermines"
                  color="primary"
                  checkedIcon={<DoneAll color="primary" />}
                  icon={<DoneAll />} />
              }
            />}
        </Box>
      </Box>
      <Card>
        <MUIDataTable
          data={societe}
          columns={columns}
          options={options}
        />
      </Card>
    </>
  );
};

export default withSnackbar(withRouter(Missions));
