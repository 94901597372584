import Modal from '@mui/material/Modal';
import React, { useRef, useState } from "react";
import { Backdrop, Box, Button, CircularProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';
import MUIDataTable from "mui-datatables";
import { getAll } from "../../../Services/MissionService";
import { addProcess } from "../../../Services/ProcessService";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "20px",
    width: "800px"
  },
  saveButton: {
    backgroundColor: "rgb(66, 186, 150)",
    color: "#FFF",
    fontWeight: 500,
    "&:hover, &:focus": {
      backgroundColor: "#389e7f",
      borderColor: "#359478",
    },
  },
  target: {
    fontWeight: 500,
    marginBottom: "0.2em",
  },
  buttonLabel: {
    paddingLeft: "0.4em",
    marginLeft: 0,
  },
  closeButton: {
    color: "#1b2a4e",
    backgroundColor: "#d9e2ef",
    borderColor: "#d9e2ef",
    marginLeft: "0.5em",
    fontWeight: "500",
  },
  actionBar: {
    marginTop: "1.5em",
    display: "flex",
    gap: "10px",
    justifyContent: "right"
  },
  modalTitle: {
    color: "rgb(17 122 167)",
    marginBottom: "1em",
  },
  buttonProgress: {
    color: "white",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  takeAppointment: {
    color: "rgb(17 122 167)",
  },
  modalBody: {
    height: "500px",
    padding: "0rem 1rem 1rem 1rem",
    overflowY: "scroll"
  }
}));

export default function MissionModal({ snackbarShowMessage, processes, reloadData, setReloadData }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [missions, setMissions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newSelectedMissions, setNewSelectedMissions] = useState([]);
  const [loadingToAdd, setLoadingToAdd] = useState(false);
  const { idCandidat } = useParams();
  const active = useRef(false);
  const [selectedMissions, setSelectedMissions] = useState([]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const buildParams = () => {
    const params = new URLSearchParams();
    params.append("statut", "910f357b-0b13-4f66-89a8-5c06964c1dcf");
    return params;
  };

  const addProcesses = () => {
    if (newSelectedMissions.length > 0) {
      (async () => {
        snackbarShowMessage('Ajout des process en cours...', 'warning')
        setLoadingToAdd(true);
        const data = {
          candidat: [idCandidat],
          missions: newSelectedMissions,
        };
        try {
          const response = await addProcess(data);
          if (!active.current) {
            return;
          }
          snackbarShowMessage(response.data.msg)
          setLoadingToAdd(false);
          handleClose();
          setReloadData(!reloadData);
        } catch (error) {
          if (!active.current) {
            return;
          }
          snackbarShowMessage(error.response.data.error.detail, 'error', 4000)
          setLoadingToAdd(false);
        }
      })();
    }
  };

  React.useEffect(() => {
    let isActive = true;
    active.current = true;
    if (open) {
      (async () => {
        setLoading(true);

        try {
          let reponse = await getAll(buildParams());
          if (!isActive) {
            return;
          }
          setMissions(reponse.data.missions);
          setSelectedMissions(
            processes.map((proces) => {
              return proces.missionId;
            })
          );
          setLoading(false);
        } catch (error) {
          if (!isActive) {
            return;
          }
          snackbarShowMessage(error.response.data.error.detail, 'error', 4000)
          setLoading(false);
        }
      })();
    }

    return () => {
      isActive = false;
      active.current = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const columns = [
    {
      name: "uuid",
      label: "N° MISSION",
      options: {
        display: false,
        setCellProps: () => ({ style: { whiteSpace: "nowrap", textOverFlow: "ellipsis", overflow: "hidden", maxWidth: "100px" } }),
      },
    },
    {
      name: "numMission",
      label: "N°",
      options: {
        setCellProps: () => ({ style: { whiteSpace: "nowrap", textOverFlow: "ellipsis", overflow: "hidden", maxWidth: "100px" } }),
      },
    },
    {
      name: "client",
      label: "CLIENT",
    },
    {
      name: "nom_mission",
      label: "NOM MISSION",
    },

    {
      name: "contact",
      label: "CONTACT",
    },
  ];

  const options = {
    rowsPerPage: 50,
    tableBodyMaxHeight: "400px",
    textLabels: {
      body: {
        noMatch: loading ? <CircularProgress /> : "Désolé, il n'y a aucun enregistrement dans la base de données pour le moment.",
      },
    },
    selectableRows: "multiple",
    draggableColumns: {
      enabled: true,
    },
    responsive: "standard",
    isRowSelectable: (dataIndex, selectedRows, data) => {
      return !selectedMissions.includes(missions[dataIndex].uuid);
    },
    onRowSelectionChange: (currentRowsSelected, allRowsSelected, rowsSelected) => {
      let temp = [];
      rowsSelected.forEach((element) => {
        temp.push(missions[element].uuid);
      });
      setNewSelectedMissions(temp);
    },
    download: false
  };

  const body = (
    <Box sx={{ display: "flex", width: "50%", flexDirection: "column", background: "white", padding: "10px", borderRadius: "8px" }}>
      <MUIDataTable data={missions} title="Liste des missions" columns={columns} options={options} />
      <div className={classes.actionBar}>
        <Button className={classes.closeButton} style={{backgroundColor: "blue", color: "white"}} onClick={handleClose}>
          Annuler
        </Button>
        <Button className={classes.saveButton} style={{backgroundColor: "green", color: "white"}} onClick={addProcesses} disabled={newSelectedMissions.length === 0}>
          Sélectionner les missions
          {loadingToAdd && <CircularProgress size={24} className={classes.buttonProgress} />}
        </Button>
      </div>
    </Box>
  );

  return (
    <>
      <Button
        style={{ backgroundColor: "rgb(17 122 167)", color: "#fff", marginBottom: "1em", height: "2.5em" }}
        type="submit"
        variant={"contained"}
        onClick={handleOpen}
        size="small"
      >
        <span className={classes.buttonLabel}>Liste des missions</span>
      </Button>
      <Modal
        className={classes.modal}
        closeAfterTransition
        open={open}
        onClose={handleClose}
      >
        {body}
      </Modal>
    </>
  );
}
