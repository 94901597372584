import React from 'react';
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
} from '@mui/lab';
import {
  Phone as PhoneIcon,
  Send as SendIcon,
  Assignment as AssignmentIcon,
  Business as BusinessIcon,
  Description as DescriptionIcon,
  Work as WorkIcon,
} from '@mui/icons-material';
import { Typography, Box } from '@mui/material';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

const formatDate = (dateObj) => {
  if (!dateObj) return null;
  // Si c'est déjà un objet Date
  if (dateObj instanceof Date) {
    return format(dateObj, 'dd/MM HH:mm', { locale: fr });
  }
  // Si c'est un objet avec une propriété date (cas de rvClosing)
  if (dateObj.date) {
    return format(new Date(dateObj.date), 'dd/MM HH:mm', { locale: fr });
  }
  return null;
};

const ProcessTimeline = ({ process }) => {
  const events = [
    {
      date: process?.dateAccrochage,
      icon: <PhoneIcon />,
      color: 'primary',
      label: 'Contact initial',
      description: process?.consultant
    },
    {
      date: process?.rvTech,
      icon: <AssignmentIcon />,
      color: 'secondary',
      label: 'Entretien technique',
    },
    {
      date: process?.sent,
      icon: <SendIcon />,
      color: 'info',
      label: 'CV envoyé',
    },
    {
      date: process?.rvClient,
      icon: <BusinessIcon />,
      color: 'warning',
      label: 'Entretien client',
      description: process?.nom_societe
    },
    {
      date: process?.lastAppointmentDatePropale,
      icon: <DescriptionIcon />,
      color: 'success',
      label: 'Propale envoyée',
    },
    {
      date: process?.lastAppointmentDateIntegration,
      icon: <WorkIcon />,
      color: 'success',
      label: 'Intégration',
    },
  ].filter(event => event.date)
    .sort((a, b) => new Date(b.date.date) - new Date(a.date.date));

  if (events.length === 0) return null;

  return (
    <Box sx={{
      backgroundColor: '#F8F9FA',
      borderRadius: 1,
      p: 2,
      mt: 2,
      height: '100%',
      display: 'flex',
      flexDirection: 'column'
    }}>
      <Timeline sx={{
        m: 0,
        p: 0,
        flex: 1,
        overflowY: 'auto',
        '& .MuiTimelineItem-root': {
          minHeight: 'auto',
          '&:before': { display: 'none' }
        },
        '& .MuiTimelineContent-root': {
          py: 1,
          px: 2
        },
        '& .MuiTimelineDot-root': {
          m: 0,
          p: 1,
          boxShadow: 'none',
          '& > svg': {
            fontSize: '1rem'
          }
        },
        '& .MuiTimelineConnector-root': {
          width: 2,
          backgroundColor: '#E9ECEF',
          ml: '5px'
        }
      }}>
        {events.map((event, index) => (
          <TimelineItem key={`timeline-${event.label}-${event.date?.date || index}`}>
            <TimelineSeparator>
              <TimelineDot color={event.color}>
                {event.icon}
              </TimelineDot>
              {index < events.length - 1 && <TimelineConnector />}
            </TimelineSeparator>
            <TimelineContent>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                <Box>
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: 500,
                      color: 'text.primary',
                      mb: 0.5
                    }}
                  >
                    {event.label}
                  </Typography>
                  {event.description && (
                    <Typography
                      variant="body2"
                      sx={{
                        color: 'text.secondary',
                        mb: 0.5
                      }}
                    >
                      {event.description}
                    </Typography>
                  )}
                </Box>
                {event.date && (
                  <Box>
                    <Typography 
                      variant="caption"
                      sx={{ 
                        color: 'text.secondary',
                        fontSize: '0.75rem',
                        ml: 2
                      }}
                    >
                      {formatDate(event.date)}
                    </Typography>
                  </Box>
                )}
              </Box>
            </TimelineContent>
          </TimelineItem>
        ))}
      </Timeline>
    </Box>
  );
};

export default ProcessTimeline;
