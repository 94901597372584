import { IconButton } from '@mui/material';
import { useState } from 'react';
import DialogDeleteCustom from '../components/DialogDeleteCustom/DialogDeleteCustom';

export default function DialogModal(props) {
  const [open, setOpen] = useState(false);
  const { label, description, title, loading, onConfirm, data } = props;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    onConfirm(data, handleClose);
  }

  const dialogDeleteProps = {
    handleClose: handleClose,
    open: open,
    handleConfirm: handleConfirm,
    loading: loading,
    label: description,
    title,
    confirmLabel: data?.status ? 'Activer' : 'Désactiver' , 
    cancelLabel: 'Annuler'
  };

  return (
    <div>
      <IconButton 
        onClick={handleClickOpen} 
        variant="contained" 
        data-toggle="tooltip"
        size="small"
      >
        {label}
      </IconButton>
      <DialogDeleteCustom {...dialogDeleteProps} />
    </div>
  );
}
