import Modal from '@mui/material/Modal';
import React, { useState } from "react";
import {
  Box,
  Button, Checkbox,
  Chip,
  Fade, FormControlLabel,
  IconButton, MenuItem, Select,
  TextField,
  Typography,
} from '@mui/material';
import EditorCustom from "../EditorCustorm/EditorCustom";
import LoadingButtonCustom from "../LoadingButtonCustom/LoadingButtonCustom";
import { useEffect } from "react";
import { AttachFile } from '@mui/icons-material';
import { useRef } from "react";
import { useSelector } from "react-redux";
import { sendEmailsToCandidatsOfSelection } from "../../pages/Services/SelectionCandidatService";
import { removeLineBreak } from "../../utils/EditorUtil";
import { withSnackbar } from "../../utils/SnackbarHOC";
import { useDispatch } from "react-redux";
import { setToggleMailing } from "../../redux/toggle/actions/toggleActions";
import { ClearListCandidatsSelectedForSelection } from "../../redux/candidats/actions/candidatsActions";
import {makeStyles} from "@mui/styles";
import {listEmailTypes} from "../../pages/Services/EmailTypeService";
import TagsInput from "../../utils/TagsInput";

const useStyles = makeStyles(( theme ) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4, 4, 3),
    borderRadius: "20px",
    width: "550px",
  },
  saveButton: {
    backgroundColor: "rgb(66, 186, 150)",
    color: "#FFF",
    fontWeight: 500,
    "&:hover, &:focus": {
      backgroundColor: "#389e7f",
      borderColor: "#359478",
    },
  },
  plus: {
    fontWeight: 500,
    marginBottom: "0.2em",
  },
  closeButton: {
    color: "#1b2a4e",
    backgroundColor: "#d9e2ef",
    borderColor: "#d9e2ef",
    marginLeft: "0.5em",
    fontWeight: "500",
  },
  modalTitle: {
    color: "rgb(17 122 167)",
  },
}));

function MailingModal({ snackbarShowMessage }) {
const classes = useStyles();
  const [emailTypes, setEmailTypes] = useState([]);
  const [messageBody, setMessageBody] = useState("");
  const [cc, setCc] = useState([]);
  const [cci, setCci] = useState([]);
  const [objet, setObjet] = useState("");
  const uploadRef = useRef(null);
  const [dataUpload, setDataUpload] = useState();
  const [loadingToSend, setLoadingToSend] = useState(false);
  const [errors, setErrors] = useState({});
  const selectedCandidatForSelection = useSelector(
    (state) => state.candidats.candidatsSelectedForSelection
  );
  const showMailingModal = useSelector((state) => state.toggle.showMailing);
  const dispatch = useDispatch();
  const decodeHtmlEntities = (text) => {
    const parser = new DOMParser();
    const decodedString = parser.parseFromString(text, "text/html");
    return decodedString.documentElement.textContent;
  };
  const handleSelectedCC = (ccs) => {
    setCc(ccs);
  }
  const handleSelectedCCI = (ccis) => {
    setCci(ccis);
  }

  useEffect(() => {
    setMessageBody("Bonjour [PRENOM],<br></br><p>Bien cordialement</p>");
    setErrors({});
    const fetchEmailTypes = async () => {
      try {
        const response = await listEmailTypes();
        setEmailTypes(response.data);
      } catch (error) {
        snackbarShowMessage("Erreur lors du chargement des types d'email.", "error", 4000);
      }
    };

    fetchEmailTypes()
  }, [showMailingModal]);

  const validate = ({ messageBody, objet }) => {
    let temp = {};
    temp.commentaire = !(messageBody?.replace(/<\/?[^>]+(>|$)/g, "") === "")
      ? ""
      : "Le champ 'MESSAGE' est obligatoire.";
    temp.objet = !(objet?.replace(/<\/?[^>]+(>|$)/g, "") === "")
      ? ""
      : "Le champ 'OBJET' est obligatoire.";
    setErrors({
      ...temp,
    });
    return !Object.values({ ...temp }).some((error) => error !== "");
  };

  const handleSubmit = ({ messageBody, objet, pj }) => {
    if (validate({ messageBody, objet })) {
      (async () => {
        setLoadingToSend(true);
        const formData = new FormData();
        if (cc.length > 0) {
          cc.forEach(email => formData.append('cc[]', email));
        }
        if (cci.length > 0) {
          cci.forEach(email => formData.append('cci[]', email));
        }
        formData.append("messageBody", removeLineBreak(messageBody));
        formData.append("objet", objet);
        formData.append("pj", pj);
        formData.append(
          "selectedCandidatToSendMail",
          selectedCandidatForSelection
        );
        try {
          snackbarShowMessage("Patientez svp!", "warning", null);
          const response = await sendEmailsToCandidatsOfSelection(formData);
          snackbarShowMessage(response.data);
          dispatch(ClearListCandidatsSelectedForSelection());
        } catch (e) {
          snackbarShowMessage(e.response.data.error.detail, "error", 4000);
        } finally {
          setLoadingToSend(false);
          dispatch(setToggleMailing(false));
        }
      })();
    }
  };

  const editorCustomProps = {
    value: messageBody,
    setValue: setMessageBody,
    handleSubmit,
    enableOnBlur: false,
  };

  const loadingButtonProps = {
    handleSubmit: () => handleSubmit({ messageBody, objet, pj: dataUpload }),
    loading: loadingToSend,
    title: "Envoyer",
  };

  const body = (
    <Box className={classes.paper}>
      <h4 className={classes.modalTitle}>Emailing</h4>
      <Box sx={{paddingBottom: "16px"}}>
        <label className="input-label" htmlFor="cci">
          CC
        </label>
        <TagsInput
            selectedTags={handleSelectedCC}
            fullWidth
            id="cc"
            name="cc"
            validation={/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/}
            placeholder="Ajouter des CC"
        />
        <label className="input-label" htmlFor="cci">
          CCI
        </label>
        <TagsInput
            selectedTags={handleSelectedCCI}
            fullWidth
            variant="outlined"
            id="cci"
            name="cci"
            placeholder="Ajouter des CCI"
        />
        <label className="input-label" htmlFor="emailType">
          Email Type
        </label>
        <Select
            onChange={(e) => {
              const selectedEmailType = emailTypes.find(
                  (type) => type.uuid === e.target.value
              );
              setObjet(selectedEmailType?.objet || "");
              setMessageBody(decodeHtmlEntities(selectedEmailType?.message || ""));
            }}
            value={emailTypes.find((type) => type.objet === objet)?.uuid || ""}
            fullWidth
            variant="outlined"
            displayEmpty
        >
          {
            [
              <MenuItem value="" key="-">
                -
              </MenuItem>,
            emailTypes
              .filter((type) => type.TypeMsg === "email")
              .map((type) => (
                  <MenuItem key={type.uuid} value={type.uuid}>
                    {type.msgName}
                  </MenuItem>
              ))]}
        </Select>
        <label className="input-label" htmlFor="prenom">
          Objet<span className="required-input">*</span>
        </label>
        <TextField
            onChange={(e) => setObjet(e.target.value)}
            name={objet}
            value={objet}
            placeholder="Objet du mail."
            margin="normal"
            variant="outlined"
            autoComplete="off"
            fullWidth
            {...(errors.objet && {
              error: true,
              label: errors.objet,
            })}
        />
        <Box className="input-label">
          Message <span className="required-input">*</span>
        </Box>
        <EditorCustom {...editorCustomProps} />
        {errors.commentaire && (
            <Typography variant="body2" color="error">
              {errors.commentaire}
            </Typography>
        )}
        {dataUpload && (
            <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  height: "100%",
                }}
                className="input-label"
            >
              <span>PJ:</span>
              <Chip
                  label={dataUpload.name}
                  variant="default"
                  onDelete={() => setDataUpload(null)}
                  size="small"
              />
            </Box>
        )}
      </Box>
      <Box
          sx={{
            display: "flex",
            gap: "10px",
            justifyContent: "end",
            width: "100%",
          }}
          className="modal-footer"
      >
        {!dataUpload && (
            <Box sx={{display: "flex"}}>
              <input
                  type="file"
                  onChange={(e) => setDataUpload(e.target.files[0])}
                  style={{display: "none"}}
                  ref={uploadRef}
              />
              <IconButton
                  // disabled={value.isMail || loading}
                  onClick={() => uploadRef.current.click()}
                  data-toggle="tooltip"
                  title="Ajouter PJ"
              >
                <AttachFile style={{fontSize: "20px"}} color="primary"/>
              </IconButton>
            </Box>
        )}

        <Button
            className={classes.closeButton}
            onClick={() => {
            setDataUpload(null);
            dispatch(setToggleMailing(false));
          }}
          disabled={loadingToSend}
        >
          Annuler
        </Button>
        <LoadingButtonCustom {...loadingButtonProps} />
      </Box>
    </Box>
  );

  return (
    <Modal
      className={classes.modal}
      open={showMailingModal}
      closeAfterTransition
    >
      <Fade in={showMailingModal}>{body}</Fade>
    </Modal>
  );
}

export default withSnackbar(MailingModal);
