import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import {
  Box,
  CircularProgress,
  FormControlLabel,
  IconButton,
  Switch,
  useMediaQuery,
} from '@mui/material';
import { Delete } from '@mui/icons-material';
import MUIDataTable from "mui-datatables";
import "./style.css";
import { TABLE } from "../../enums/tablesEnum";
import DialogDeleteCustom from "../DialogDeleteCustom/DialogDeleteCustom";
import FormTacheModal from "../FormTacheModal/FormTacheModal";
import {
  addTacheContact,
  editTacheContact,
} from "../../pages/Services/TacheContactService";
import {
  addTacheCandidat,
  editTacheCandidat,
} from "../../pages/Services/TacheCandidatService";
import ViewTacheModal from "../ViewTacheModal/ViewTacheModal";
import { DoneAll } from "@mui/icons-material";
import { withSnackbar } from "../../utils/SnackbarHOC";
import LabelReadMoreReadLess from "../LabelReadMoreReadLess/LabelReadMoreReadLess";
import { v4 } from "uuid";
import {getRowData} from "../../utils/FunctionHelpers";

function TabPanelTaches({
  snackbarShowMessage,
  deleteTache,
  getAllTachesFaites,
  getAll,
  tacheFrom,
  disableTableBodyMax = false,
  ...rest
}) {
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [taches, setTaches] = useState([]);
  const [tacheInfo, setTacheInfo] = useState({
    affecteA: "",
    tacheId: "",
  });
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [loadingToDelete, setLoadingToDelete] = useState(false);
  const [reloadingData, setReloadingData] = useState(false);
  const [tachesTermines, setTachesTermines] = useState(false);
  const parametters = useParams();
  const idCandidat = rest?.idCandidat ?? parametters.idCandidat;
  const idContact = (rest?.idContact || rest?.uuid) ?? parametters.idContact;
  const id = tacheFrom === TABLE.CONTACT ? idContact : idCandidat;
  const handleServices =
    tacheFrom === TABLE.CONTACT
      ? { id, editTache: editTacheContact, addTache: addTacheContact }
      : { id, editTache: editTacheCandidat, addTache: addTacheCandidat };

  const props = {
    reloadingData,
    setReloadingData,
    snackbarShowMessage,
    ...handleServices,
  };

  const handlePageChange = (page) => {
    setPage(page + 1);
  };

  const handleOpenDialog = (params) => {
    setTacheInfo({
      affecteA: params.rowData[3],
      tacheId: params.rowData[0],
      id: id,
    });
    setOpenDialogDelete(true);
  };

  const handleCloseDialog = () => {
    if (loadingToDelete) {
      return;
    }
    setOpenDialogDelete(false);
  };

  const handleDeleteTaches = () => {
    (async () => {
      setLoadingToDelete(true);
      try {
        snackbarShowMessage("Patientez svp!", "warning", null);
        const res = await deleteTache(tacheInfo.tacheId);
        snackbarShowMessage(res.data);
        setLoadingToDelete(false);
        handleCloseDialog();
        setReloadingData(!reloadingData);
      } catch (error) {
        snackbarShowMessage(
          "Erreur de suppression de la donnée, veuillez réessayer plus tard.",
          "error",
          4000
        );
        setLoadingToDelete(false);
      }
    })();
  };

  useEffect(() => {
    let active = true;
    let newTaches;

    (async () => {
      setLoading(true);
      setTaches([]);
      try {
        if (tachesTermines) {
          newTaches = await getAllTachesFaites(page, id);
        } else {
          newTaches = await getAll(page, id);
        }

        if (!active) {
          return;
        }
        const data =
          tacheFrom === TABLE.CONTACT
            ? newTaches.data.TachesContact
            : newTaches.data.TachesCandidat;

        setTaches(data);
        setCount(newTaches.data.count);
        setLoading(false);
      } catch (error) {
        if (!active) {
          return;
        }
        snackbarShowMessage("Erreur de chargement des tâches.", "error", 4000);
        setLoading(false);
      }
    })();

    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, reloadingData, tachesTermines, id]);

  const columns = [
    {
      name: "id",
      label: "N°TÂCHE",

      options: {
        display: false,
        filter: true,
        sort: false,
        setCellHeaderProps: () => ({
          style: {
            padding: "2px 12px",
            textAlign: "center",
            maxWidth: "100px",
          },
        }),
        setCellProps: () => ({
          style: {
            padding: "0px 12px",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
            width: "100px",
          },
        }),
      },
    },
    {
      name: "dateTime",
      label: "DATE/HEURE",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({ style: { padding: "2px 12px" } }),
        setCellProps: () => ({ style: { padding: "0px 12px" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{moment(value.date).format("DD/MM/yyyy HH:mm")}</>;
        },
      },
    },
    {
      name: "affectePar",
      label: "AFFECTÉE PAR",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({ style: { padding: "0px 12px" } }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            justifyContent: "center",
            padding: "2px 12px",
          },
        }),
      },
    },
    {
      name: "affecteA",
      label: "AFFECTÉE À",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({ style: { padding: "0px 12px" } }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            justifyContent: "center",
            padding: "2px 12px",
          },
        }),
      },
    },
    {
      name: "affecteAId",
      label: "AFFECTÉE À ID",
      options: {
        display: false,
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            justifyContent: "center",
            padding: "2px 12px",
          },
        }),
      },
    },
    {
      name: "statut",
      label: "STATUT",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({
          style: { justifyContent: "center", padding: "2px 12px" },
        }),
        setCellProps: () => ({ style: { padding: "0px 12px" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return <Box>{value ? "FAIT" : "À FAIRE"}</Box>;
        },
      },
    },
    {
      name: "commentaire",
      label: "COMMENTAIRE",
      options: {
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({
          style: { justifyContent: "center", padding: "2px 12px" },
        }),
        setCellProps: () => ({ style: { padding: "0px 12px" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return <LabelReadMoreReadLess data={value} enableCollapse={false} />;
        },
      },
    },
    {
      name: "actions",
      label: "ACTIONS",
      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellHeaderProps: () => ({
          style: {
            justifyContent: "center",
            minWidth: "200px",
            padding: "2px 12px",
          },
        }),
        setCellProps: () => ({ style: { padding: "0px 12px" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          const rowData = getRowData(tableMeta, columns)
          return (
            <>
              <ViewTacheModal
                affecteA={tableMeta.rowData[3]}
                affectePar={tableMeta.rowData[2]}
                commentaire={rowData.commentaire.replace(/<[^>]*>?/gm, '')}
                statut={tableMeta.rowData[5]}
                dateTime={tableMeta.rowData[1].date}
              />
              <FormTacheModal
                {...props}
                editMode={true}
                oldAffecteA={tableMeta.rowData[4]}
                oldStatut={tableMeta.rowData[5]}
                oldCommentaire={tableMeta.rowData[6]}
                oldDateTime={tableMeta.rowData[1].date}
                tacheId={tableMeta.rowData[0]}
              />
              <IconButton
                data-toggle="tooltip"
                title="Supprimer"
                onClick={() => handleOpenDialog(tableMeta)}
                style={{ color: "#EF6262" }}
              >
                <Delete />
              </IconButton>
            </>
          );
        },
      },
    },
  ];

  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setViewportHeight(window.innerHeight);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isSmall = useMediaQuery((theme) => theme.breakpoints.between(0, 767));
  const isMediumMode = useMediaQuery((theme) =>
    theme.breakpoints.between(767, 1023)
  );
  const isLargeMode = useMediaQuery((theme) =>
    theme.breakpoints.between(1023, 2800)
  );

  const tableBodyMaxHeight = !disableTableBodyMax
    ? `${
        isSmall
          ? viewportHeight - 292
          : isMediumMode
          ? viewportHeight - 478
          : isLargeMode
          ? viewportHeight - 685
          : viewportHeight - 685
      }px`
    : "44vh";

  const options = {
    serverSide: true,
    tableBodyMaxHeight,
    count: count,
    rowsPerPage: 50,
    textLabels: {
      body: {
        noMatch: loading ? (
          <CircularProgress />
        ) : (
          "Désolé, il n'y a aucun enregistrement dans la base de données pour le moment."
        ),
      },
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case "changePage":
          handlePageChange(tableState.page);
          break;
        default:
      }
    },
    selectableRows: "none",
    draggableColumns: {
      enabled: true,
    },
    filterType: "dropdown",
    filter: false,
    print: false,
    columns: false,
    responsive: "standard",
    search: false,
    download: false,
    viewColumns: false,
  };

  const dialogDeleteProps = {
    handleClose: handleCloseDialog,
    handleOpen: openDialogDelete,
    handleDelete: handleDeleteTaches,
    loading: loadingToDelete,
    label: `Voulez vous vraiment supprimer la tâche de ${tacheInfo.affecteA}?`,
    title: "Supprimer la tâche",
  };

  const switches = [
    {
      label: "LES TÂCHES TERMINÉES",
      checked: tachesTermines,
      onChange: (e) => setTachesTermines(e.target.checked),
      icon: <DoneAll />,
    },
  ];

  return (
    <>
      <DialogDeleteCustom {...dialogDeleteProps} />
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <FormTacheModal editMode={false} {...props} />
        <Box>
          {!isSmall ? (
            switches.map((item, index) => (
              <FormControlLabel
                key={v4()}
                control={
                  <Switch
                    checked={item.checked}
                    onChange={item.onChange}
                    color={item.color}
                  />
                }
                label={item.label}
                className="input-label"
                style={{
                  marginLeft: index === 0 ? "0.2em" : "0",
                  marginRight: index === 0 ? "2rem" : "0",
                }}
              />
            ))
          ) : (
            <FormControlLabel
              control={
                <Switch
                  checked={tachesTermines}
                  onChange={(e) => setTachesTermines(e.target.checked)}
                  name="tachesTermines"
                  color="primary"
                  checkedIcon={<DoneAll color="primary" />}
                  icon={<DoneAll />}
                />
              }
            />
          )}
        </Box>
      </Box>
      <MUIDataTable data={taches} columns={columns} options={options} />
    </>
  );
}

export default withSnackbar(TabPanelTaches);
