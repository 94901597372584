import React from "react";
import { Card, Grid, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import LabelHtmlToTextCustom from "../../../../components/LabelHtmlToTextCustom/LabelHtmlToTextCustom";


const Informations = ({ missionData }) => {
    return (
        <Grid container spacing={3}>
            <Grid item={true} xs={12}>
                <Card style={{ padding: 10, minHeight: 100, textAlign: "left" }}>
                    <TableContainer>
                        <Table className="table table-striped">
                            <TableBody component="tbody">
                                <TableRow>
                                    <TableCell scope="row">
                                        <strong>Description</strong>
                                    </TableCell>
                                    <TableCell style={{ width: "80%" }} align="left"><LabelHtmlToTextCustom data={missionData.description_societe ? missionData.description_societe : "-"} /></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell scope="row">
                                        <strong>Poste</strong>
                                    </TableCell>
                                    <TableCell style={{ width: "80%" }} align="left"><LabelHtmlToTextCustom data={missionData.poste ? missionData.poste : "-"} /></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Card>
            </Grid>
        </Grid>
    );
};

export default Informations;
