import React, { useState } from "react";
import {Link, useParams} from "react-router-dom";
import Informations from "./Tabs/Informations";
import InformationsMission from "./Tabs/informationMission";
import ProfileRecherche from "./Tabs/ProfileRecherche";
import { Card, Container, Typography, Button } from '@mui/material';
import { getDetails } from "../../Services/MissionService";
import "../FormMission.js";
import moment from "moment";
import "../Listermission";
import 'moment/locale/fr'
import {ArrowBack, Edit, KeyboardReturn} from '@mui/icons-material';
import { withSnackbar } from "../../../utils/SnackbarHOC";
import Loader from "../../../components/Loader/Loader";
import { Box, Fab, Grid } from '@mui/material';

const VisualiserMission = ({ history, snackbarShowMessage }) => {
  const [loading, setLoading] = useState(true);
  const [mission, setMission] = useState({});
  const { missionId } = useParams();
  const formatDate = (dateString) => {
    const formattedString = dateString.split(' ')[0]; // Get only the date part (YYYY-MM-DD)
    const date = new Date(formattedString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  React.useEffect(() => {
    let isActive = true;
    setLoading(true);
    (async () => {
      try {
        let missionData = await getDetails(missionId);
        if (!isActive) {
          return;
        }
        setMission(missionData.data);
        setLoading(false);
      } catch (error) {
        snackbarShowMessage("Erreur de chargement de la mission", "error", 4000);
        setLoading(false);
      }
    })();

    return () => {
      isActive = false;
    };
  }, [missionId]);

  const editMission = (missionId, societeId) => {
    history.push(`/societe/${societeId}/missions/${missionId}/update`, { numMission: mission.numMission });
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Box sx={{ position: "relative" }}>
          <Container>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Link
                    data-toggle="tooltip"
                    title="Retourner vers la page précedente"
                    onClick={(e) => {
                      e.preventDefault();
                      history.goBack();
                    }}
                    className="d-print-none font-sm"
                    style={{ textDecoration: "none" }}
                >
                  <ArrowBack />
                </Link>
                <Card style={{ padding: "1em 1em 1em" }}>
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline", color: "#117aa7" }}>
                    <Typography onClick={() => history.push(`/societe/${mission.societeId}/view`)} variant="h5"
                                style={{cursor: "pointer", fontWeight: 600, marginBottom: "0.5em"}}>
                      {mission.nom_societe + " -  "}
                      {mission.nom_mission + " - "}
                      N° {mission.numMission}<br/>
                      <span style={{textTransform: "capitalize"}}>{mission.accountManager + " -  " + mission.consultant } </span><br/>
                      <Typography variant="h5" style={{fontWeight: 600, fontSize: "17px", color: "black"}}>
                        {mission.updatedAt && mission.updatedAt !== ""
                            ? `Modifiée le: ${formatDate(mission.updatedAt.date)}`
                            : `Créée le: ${formatDate(mission.datecreation.date)}`}

                      </Typography>
                    </Typography>
                  </div>
                  <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "baseline", marginTop: "-60px" }}>
                    <Typography></Typography>
                    <Button data-toggle="tooltip"
                      title="Modifier" onClick={() => editMission(missionId, mission.societeId)} style={{ paddingRight: "1em", color: "#117aa7" }}>
                      <Edit />
                    </Button>
                  </Box>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card style={{ padding: "1em 1em 1em 1em" }}>
                  <div style={{ overflowY: "auto", height: 'calc(100vh - 220px)', scrollBehavior: "smooth", paddingLeft: "0.5rem", paddingTop: "0.5rem" }}>
                    <Typography style={{ fontWeight: 500, paddingTop: "1em" }}>INFORMATION</Typography>
                    <InformationsMission missionData={mission} />
                    <Typography style={{ fontWeight: 500, paddingTop: "1em" }}>SOCIÉTÉ</Typography>
                    <Informations missionData={mission} />
                    <Typography style={{ fontWeight: 500, paddingTop: "1em" }}>CRITÈRES DE CIBLAGE</Typography>
                    <ProfileRecherche missionData={mission} />
                  </div>
                </Card>
              </Grid>
            </Grid>
          </Container>
          <Fab variant="extended" data-toggle="tooltip" title="Retourner" onClick={() => history.goBack()} style={{ cursor: "pointer", position: "absolute", right: "1rem", bottom: "30px" }}>
            <KeyboardReturn />
          </Fab>
        </Box>
      )}
    </>
  );
};
export default withSnackbar(VisualiserMission);
