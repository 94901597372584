import { Box, IconButton } from '@mui/material';
import { CloseOutlined, LinkedIn } from '@mui/icons-material';
import MUIDataTable from 'mui-datatables'
import React from 'react'
import { updateObjectStateValue } from '../../../../utils/FunctionHelpers';

const Step3 = ({ importData, setImportData }) => {
    const handleDeleteCandidat = (data) => {
        const tmpCandidats = importData.candidatsToImport
        tmpCandidats.splice(data.rowIndex, 1)
        updateObjectStateValue(
            setImportData,
            { candidatsToImport: tmpCandidats }
        )
    }

    const columns = [
        {
            name: "firstName",
            label: "PRENOM",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Box style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", maxWidth: "100px" }} title={value}>{value}</Box>
                    );
                },
            }
        },
        {
            name: "lastName",
            label: "NOM",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Box style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", maxWidth: "100px" }} title={value}>{value}</Box>
                    );
                },
            }
        },
        {
            name: "occupation",
            label: "FONCTION",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Box style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", maxWidth: "300px" }} title={value}>{value}</Box>
                    );
                },
            }
        },
        {
            name: "linkedinEmail",
            label: "EMAIL",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Box style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", maxWidth: "100px" }} title={value}>{value ? value : "-"}</Box>
                    );
                },
            }
        },
        {
            name: "phoneNumbers",
            label: "TELEPHONE"
        },
        {
            name: "linkedinUrl",
            label: "LKD",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            <Box style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", width: "30px" }} title={value}>
                                <a href={value} target="_blank" rel="noreferrer">
                                    <LinkedIn />
                                </a>
                            </Box>
                        </>
                    );
                },
            },
        },
        {
            name: "company_name",
            label: "SOCIETE",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Box style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", maxWidth: "100px" }} title={value}>
                            {value}
                        </Box>
                    );
                },
            }
        },
        {
            name: "",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <IconButton data-toggle="tooltip"
                            title="Delete" size='small' onClick={() => handleDeleteCandidat(tableMeta)}>
                            <CloseOutlined color='error' />
                        </IconButton>
                    );
                },
            }
        },
    ];

    const options = {
        filterType: "dropdown",
        selectableRows: "none",
        responsive: "vertical",
    };

    return (
        <Box style={{ height: "500px" }}>
            <MUIDataTable title={"Candidats à importer"} data={importData.candidatsToImport} columns={columns} options={options} />
        </Box>
    )
}

export default Step3
