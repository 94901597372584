import React, { useState } from "react";
import { FilePreviewContainer, PreviewContainer, PreviewList, FileMetaData } from "./previewfiles.styles";
import { downloadFile } from "../../../Services/FileService";
import { GetApp } from '@mui/icons-material';
import { CircularProgress, styled } from '@mui/material';

const useStyles = styled(() => ({
  ellipsis: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    width: 280,
  },
  download: {
    cursor: "pointer",
  },
  buttonProgress: {
    color: "white",
  },
  filePreviewContainer: {
    cursor: "default",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    width: "100%"
  },
}));

export default function FilePreview({ files }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const handleDownload = (id, fileName) => {
    const FileDownload = require("js-file-download");
    (async () => {
      setLoading(true);
      let response = await downloadFile(id);
      FileDownload(response.data, fileName);
      setLoading(false);
    })();
  };

  return (
    <FilePreviewContainer>
      <PreviewList>
        {files.map((file) => {
          let fileName = file.imageName;
          let key = file.id;

          return (
            <PreviewContainer key={key}>
              <FileMetaData className={classes.filePreviewContainer} style={{ flexDirection: "row" }}>
                <span className={classes.ellipsis}>{fileName}</span>
                <span>
                  {loading ? (
                    <CircularProgress size={28} className={classes.buttonProgress} />
                  ) : (
                    <GetApp onClick={() => handleDownload(key, fileName)} className={classes.download} />
                  )}
                </span>
              </FileMetaData>
            </PreviewContainer>
          );
        })}
      </PreviewList>
    </FilePreviewContainer>
  );
}
