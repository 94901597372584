import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { Box, IconButton, Typography } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import DownloadButton from "./DownloadCv";

// Configuration du worker PDF.js
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const PdfPreview = ({ fileUrl, fileName }) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const changePage = (offset) => {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    };

    const previousPage = () => {
        changePage(-1);
    };

    const nextPage = () => {
        changePage(1);
    };

    return (
        <Box sx={{ 
            height: "600px", 
            border: "1px solid #e4e4e4",
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            p: 2
        }}>
            <Document
                file={fileUrl}
                onLoadSuccess={onDocumentLoadSuccess}
                loading={<Typography>Chargement du PDF...</Typography>}
                error={<Typography color="error">Erreur lors du chargement du PDF</Typography>}
            >
                <Page 
                    pageNumber={pageNumber} 
                    renderTextLayer={true}
                    renderAnnotationLayer={true}
                    scale={1.2}
                />
            </Document>
            
            <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                gap: 2,
                mt: 2
            }}>
                <IconButton 
                    onClick={previousPage} 
                    disabled={pageNumber <= 1}
                >
                    <ChevronLeft />
                </IconButton>
                
                <Typography>
                    Page {pageNumber} sur {numPages}
                </Typography>
                
                <IconButton 
                    onClick={nextPage} 
                    disabled={pageNumber >= numPages}
                >
                    <ChevronRight />
                </IconButton>
            </Box>
            
            <DownloadButton 
                fileUrl={fileUrl} 
                fileName={fileName} 
                style={{
                    marginTop: '16px',
                    textAlign: "center"
                }}
            />
        </Box>
    );
};

export default PdfPreview;
