import {ArrowBack, AssignmentOutlined, Description, Explore, NotesOutlined, Person} from '@mui/icons-material';
import { Box, Card, Grid, Tab, Tabs, Typography, useMediaQuery } from '@mui/material';
import 'moment/locale/fr';
import React, { useEffect, useState } from "react";
import { GiOnTarget } from "react-icons/gi";
import {
  ReflexContainer,
  ReflexElement,
  ReflexSplitter
} from 'react-reflex';
import { Link, useParams, useHistory, useLocation } from 'react-router-dom';
import { ContactButtonAndEditButton } from "../../../components/ContactButtonAndEditButton/ContactButtonAndEditButton";
import Loader from "../../../components/Loader/Loader";
import TabPanelTaches from "../../../components/TabPanelTaches/TabPanelTaches";
import { TABLE } from "../../../enums/tablesEnum";import { getUserData } from "../../../utils/FunctionHelpers";
import { theme } from "../../../utils/Theme";
import { getDetails } from "../../Services/ContactService";
import { deleteTacheContact, getAll, getAllTachesFaites } from "../../Services/TacheContactService";
import ContactInformationsTable from "./ContactInformationsTable";
import CandidatsAssocie from "./Tabs/CandidatsAssocie";
import MissionEnCours from "./Tabs/MissionEnCours";
import NotesContact from "./Tabs/NotesContact";
import PresentationSociete from "./Tabs/PresentationSociete";
import Prospection from "./Tabs/Prospection";
import "./VisualiserContact.css";
import VisualiserDate from "./VisualiserDate";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index &&
        <Box pt={2} px={1} pb={1}>
          {children}
        </Box>
      }
    </div>
  );
}
const VisualiserContact = ({ selected, snackbarShowMessage }) => {
  const history = useHistory();
  const location = useLocation();
  const [value, setValue] = useState(history.location.state?.tabIndex || 0);
  const [contact, setContact] = useState({});
  const [loading, setLoading] = useState(true);
  const { idContact, id } = useParams();
  const idCurrentUser = getUserData().uuid;
  const isSmMode = useMediaQuery(theme.breakpoints.between(0, 480));
  const isMdMode = useMediaQuery(theme.breakpoints.between(481, 992));
  const props = {
    nomSociete: contact.nom_societ,
    deleteTache: deleteTacheContact,
    getAll, getAllTachesFaites,
    tacheFrom: TABLE.CONTACT, ...contact,
    id
  };



  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        const { data } = await getDetails(idContact);
        if (isMounted) {
          setContact(data);
          setLoading(false);
        }
      } catch (error) {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [idContact]);

  useEffect(() => {
    const etat = new URLSearchParams(location.search).get('etat');
    if (etat === 'updated') {
      snackbarShowMessage("Contact mis à jour via l'extension", "warning", 4000);
    } else if (etat === 'created') {
      snackbarShowMessage("Contact ajouté via l'extension", "success", 4000);
    }
  }, [location.search]);

  const handleTabs = (event, newValue) => {
    setValue(newValue);
  };

  const editContact = () => {

    history.push({
      pathname: `/societe/${id}/contacts/${idContact}/edit`,
      state: { nomSociete: contact.nom_societe },
    });
  };

  const contactButtonAndEditButtonProps = {
    id: idContact,
    prenom: contact.prenom,
    nom: contact.nom,
    modalOpenSms: false,
    setModalOpenSms: () => { },
    uuid: contact.uuid,
    loadData: null,
    history,
    email: contact.email,
    setLoadData: () => { },
    edit: editContact,
    telephone: contact.telFix,
    props: { ...contact }
  }

  const tabs = [
    { name: "NOTES", icon: <NotesOutlined /> },
    { name: "TÂCHES", icon: <AssignmentOutlined /> },
    { name: "DESCRIPTION", icon: <Description /> },
    { name: "MISSIONS", icon: <GiOnTarget /> },
    { name: "CANDIDATS", icon: <Person /> },
    { name: "PROSPECTION", icon: <Explore /> },
  ]

  const tabPanelProps = [
    {
      index: 0,
      component: <NotesContact {...props} />,
    },
    {
      index: 1,
      component: <TabPanelTaches  {...props} />,
    },
    {
      index: 2,
      component: <PresentationSociete {...props} />,
    },
    {
      index: 3,
      component: <MissionEnCours {...props} />,
    },
    {
      index: 4,
      component: <CandidatsAssocie {...props} />,
    },
    {
      index: 5,
      component: <Prospection idCurrentUser={idCurrentUser} />,
    },
  ]

  return (
    <Box sx={{ width: '100%', height: "100%", padding: "0px 20px" }}>
      {loading ? (
        <Loader />
      ) : (
        <>

          <Card className="p-3">
            <Box sx={{ display: "flex", width: "100%", flexDirection: "column" }}>
              <Grid container>
                <Grid item xs={12} sm={4} md={3} lg={3}>

                  <Link to={{ pathname: `/societe/${contact.societeId}/view` }} className="d-print-none" style={{ textDecoration: "none" }}>
                    <Typography variant="h5" style={{ fontWeight: 600, fontSize: "1.5em", color: " rgb(103, 107, 132)", textTransform: "capitalize" }}>
                      <Link
                          data-toggle="tooltip"
                          title="Retourner vers la page précedente"
                          onClick={(e) => {
                            e.preventDefault();
                            history.goBack();
                          }}
                          className="d-print-none font-sm"
                          style={{ textDecoration: "none", paddingRight: "15px" }}
                      >
                        <ArrowBack />
                      </Link>
                      {contact.nom_societe}
                    </Typography>
                  </Link>
                </Grid>
                <Grid item xs={12} sm={4} md={5} lg={6}>
                  <Typography variant="h5" style={{ fontSize: "1.4rem", color: "#117aa7", textTransform: "capitalize", fontWeight: 600, }}>
                    {!(isSmMode || isMdMode) && contact.titre} {`${contact.prenom} ${contact.nom}`}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={3}>
                  <Box sx={{ display: "flex", justifyContent: !isSmMode ? "end" : "start", width: "100%" }}>
                    <ContactButtonAndEditButton {...contactButtonAndEditButtonProps} />
                  </Box>
                </Grid>
              </Grid>
              <VisualiserDate />
            </Box>
          </Card>
          <Box className="contentWithHead">
            <ReflexContainer orientation="horizontal">
              <ReflexElement className="left-pane" >
                <ContactInformationsTable {...props} />
              </ReflexElement>
              <ReflexSplitter style={{ cursor: "move" }}>
                <Box
                  sx={{
                    backgroundColor: "#117aa7",
                    color: "white",
                  }}>
                  <Tabs
                    value={value}
                    onChange={handleTabs}
                    orientation="horizontal"
                  >
                    {
                      tabs.map(tab => (<Tab key={tab.name} style={{ minWidth: isSmMode ? 50 : isMdMode ? 60 : 64 }} label={
                        <Box sx={{ display: "flex", gap: "20px", alignItems: "center", color: "white" }} >
                          {tab.icon}
                          {!(isSmMode || isMdMode) && <span>{tab.name}</span>}
                        </Box>} />))
                    }
                  </Tabs>
                </Box>
              </ReflexSplitter>
              <ReflexElement className="right-pane" maxSize={600} minSize={window.innerHeight - 510} style={{ overflow: "hidden" }}>
                <Box sx={{ backgroundColor: "#f4f7fc", marginTop: "50px" }}>
                  {tabPanelProps.map((tabData) => (
                    <TabPanel key={tabData.index + value} value={value} index={tabData.index}>
                      {tabData.component}
                    </TabPanel>
                  ))}
                </Box>
              </ReflexElement>
            </ReflexContainer>
          </Box>
        </>


      )
      }

    </Box >
  );
};

export default VisualiserContact;
