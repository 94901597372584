import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Fade,
  ListItem,
  ListItemIcon,
  Step,
  StepLabel,
  Stepper,
  Typography
} from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';
import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import {BsDownload, BsGear} from "react-icons/bs";
import { useHistory } from "react-router-dom";
import * as XLSX from 'xlsx';
import { updateObjectStateValue } from "../../../utils/FunctionHelpers";
import { withSnackbar } from "../../../utils/SnackbarHOC";
import { importCandidatsToSelection } from "../../Services/SelectionCandidatService";
import Step1 from "./Components/Step1";
import Step2 from "./Components/Step2";
import Step3 from "./Components/Step3";


function getModalStyle() {
  const top = 0;
  const left = 0;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "20px",
    maxWidth: "1200px"
  },
  saveButton: {
    backgroundColor: "rgb(66, 186, 150)",
    color: "#FFF",
    fontWeight: 500,
    "&:hover, &:focus": {
      backgroundColor: "#389e7f",
      borderColor: "#359478",
    },
  },
  closeButton: {
    color: "#1b2a4e",
    backgroundColor: "#d9e2ef",
    borderColor: "#d9e2ef",
    marginRight: "0.5em",
    fontWeight: "500",
  },
  modalTitle: {
    color: "rgb(17 122 167)",
  },
  buttonProgress: {
    color: "white",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  modalBody: {
    padding: "0rem 1rem",
  },
  modalBodyStep3: {
    padding: "0rem 1rem",
    overflowY: "auto"
  },
  nestedTexts: {
    fontSize: "12px",
    fontWeight: "bold",
    color: "rgb(103, 107, 132)",
    paddingLeft: "35px",
    textDecoration: "none",
    fontFamily: "myFirstFont",    
    letterSpacing: "1px",
    borderRadius: '4px',
    cursor: "pointer",
    "&:hover": {
      color: "rgb(103, 107, 132) !important",
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  nestedIcons: {
    minWidth: "25px",
  },
  progress: {
    display: 'flex',
    justifyContent: 'center',
    height: "100px"
  }
}));

const steps = [
  'Choisir une sélection',
  'Importer les candidats',
  'Confirmation des données',
];

const CandidatImport = ({ snackbarShowMessage, todos }) => {
  const classes = useStyles();
  const [importData, setImportData] = useState({})
  const [modalState, setModalState] = useState({ open: false });
  const [modalStyle] = useState(getModalStyle);
  const [currentStep, setCurrentStep] = useState(0);
  const [errors, setErrors] = useState({});
  const history = useHistory()

  const handleOpen = () => {
    setModalState({ open: true });
  };

  const resetStatesData = () => {
    setImportData({})
    setErrors({})
    setCurrentStep(0)
  }

  const handleClose = () => {
    setModalState({ open: false });
    resetStatesData()
  };

  const handleSubmit = () => {
    snackbarShowMessage("Importation des candidats...", "warning")
    updateObjectStateValue(
      setImportData,
      { isLoading: true }
    )
    const payload = {
      selection: importData.selection.id,
      candidatsToImport: importData.candidatsToImport,
    }
    importCandidatsToSelection(payload).then(() => {
      snackbarShowMessage("Candidat a été ajouté dans les sélections.")
      handleClose();
      history.push({
        pathname: `/selections/${importData.selection.id}/candidats/list`,
        search: `?selectionName=${importData.selection.selectionName}&consultant=${importData.selection.creator}`
      });
    }).catch(({ response }) => {
      snackbarShowMessage(response.data.error.detail, "error", 4000)
    }).finally(() => {
      updateObjectStateValue(
        setImportData,
        { isLoading: false }
      )
    })
  };

  const handleNextStep = async () => {
    if (await validate() && currentStep <= steps.length - 1) {
      setCurrentStep(currentStep + 1)
    }
  }

  const handlePreviousStep = () => {
    if (currentStep >= 0) {
      setCurrentStep(currentStep - 1)
    }
  }

  const getStepContent = () => {
    switch (currentStep) {
      case 0:
        return <Step1 {...{ importData, setImportData, errors }} />
      case 1:
        return <Step2 {...{ importData, setImportData }} />
      case 2:
        return <Step3 {...{ importData, setImportData }} />
      default:
        return "Etape inconnue."
    }
  };

  const xlsxFormatIsValid = async (candidatsFile) => {
    updateObjectStateValue(
      setImportData,
      { isImporting: true }
    )
    const data = await candidatsFile.arrayBuffer();
    const workbook = XLSX.read(data);
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const jsonData = XLSX.utils.sheet_to_json(worksheet, {
      header: 1,
      defval: "",
      blankrows: false
    });
    const formatData = jsonData.slice(1).map((r) => r.reduce((acc, x, i) => {
      if (jsonData[0][i] === "phoneNumbers") {
        acc[jsonData[0][i]] = x.replace(/HOME: |MOBILE: |WORK: | /gi, '');
      } else {
        acc[jsonData[0][i]] = x;
      }
      return acc;
    }, {}))
    updateObjectStateValue(
      setImportData,
      {
        isImporting: false,
        candidatsToImport: formatData,
      }
    )
    const mustBeInHeaders = ["firstName", "lastName", "occupation", "linkedinEmail", "phoneNumbers", "linkedinUrl", "company_name"]
    return mustBeInHeaders.every(header => {
      return jsonData[0].includes(header)
    })
  };

  const validate = async () => {
    let temp = {};
    switch (currentStep) {
      case 0:
        temp.selection = importData.selection ? "" : "Le champ 'NOM DE LA SÉLECTION' est obligatoire."
        break
      case 1:
        temp.candidatsFile = ""
        if (importData.candidatsFile) {
          temp.candidatsFile = await xlsxFormatIsValid(importData.candidatsFile) ? "" : "Format est invalide."
        } else {
          temp.candidatsFile = "Choisir un fichier"
        }
        break
      default:
        break;
    }
    setErrors({
      ...temp,
    });
    return Object.values(temp).every((x) => x === "")
  };

  const body = (
    <Box style={modalStyle} className={classes.paper}>
      <Box className="modal-header">
        <h4 className={classes.modalTitle}>IMPORTER DES CANDIDATS</h4>
      </Box>
      <Box className={currentStep !== 2 ? classes.modalBody : classes.modalBodyStep3}>
        <Stepper activeStep={currentStep} alternativeLabel>
          {steps.map((label, index) => {
            const labelProps = {};
            if (errors.candidatsFile && index === 1 && currentStep === 1) {
              labelProps.optional = (
                <Typography variant="caption" color="error">
                  {errors.candidatsFile}
                </Typography>
              );

              labelProps.error = true;
            }
            return (
              <Step key={label}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            )
          }
          )}
        </Stepper>
        {
          importData.isImporting
            ? (
              <Box className={classes.progress}>
                <CircularProgress />
              </Box>
            )
            : getStepContent()
        }
      </Box>
      <Box className="modal-footer">
        <Button className={classes.closeButton} onClick={currentStep > 0 ? handlePreviousStep : handleClose} disabled={importData.isLoading}>
          {currentStep > 0 ? "Retourner" : "Annuler"}
        </Button>
        {
          currentStep < steps.length - 1
            ? <Button
              variant="contained"
              onClick={async () => await handleNextStep()}
              color="primary"
            >
              {importData.isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
              Continuer
            </Button>
            : <Button className={classes.saveButton} onClick={handleSubmit} disabled={importData.isLoading}>
              Importer
              {importData.isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </Button>
        }
      </Box>
    </Box>
  );

  return (
      <>
        <ListItem onClick={handleOpen} className={classes.nestedTexts}>
          <ListItemIcon style={{marginLeft: !todos ? "14px": "-3px", padding: 0}}>
            <DownloadIcon />
          </ListItemIcon>
          <span style={{color: 'black', marginLeft: "-13px", display: todos ? 'none' : ''}}>IMPORTER DES CANDIDATS</span>
        </ListItem>
        <Modal
            className={classes.modal}
            open={modalState.open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
        >
          <Fade in={modalState.open}>{body}</Fade>
        </Modal>
      </>

  );
}
export default withSnackbar(CandidatImport)
