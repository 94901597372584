import React, { useState } from "react";
import { IconButton } from '@mui/material';
import { MdDescription } from "react-icons/md";
import LabelHtmlToTextCustom from "../components/LabelHtmlToTextCustom/LabelHtmlToTextCustom";
import Modal from "../components/Modal/Modal";

export default function SachezQueModal({ sachezQue, modalTitle }) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const modalContent = (
    <div style={{ maxHeight: "500px", overflowY: "auto" }}>
      <LabelHtmlToTextCustom text={sachezQue} />
    </div>
  );

  return (
    <>
      <IconButton
        size="small"
        onClick={handleOpen}
        color="primary"
      >
        <MdDescription />
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        title={modalTitle}
        content={modalContent}
      />
    </>
  );
}
