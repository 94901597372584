import React, { useState } from "react";
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import Alert from '@mui/lab/Alert';
import { Portal } from '@mui/material';

export const withSnackbar = WrappedComponent => {
  return props => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [duration, setDuration] = useState(2000);
    const [severity, setSeverity] = useState(
      "success"
    );

    const showMessage = (message, severity = "success", duration = 2000) => {
      setMessage(message);
      setSeverity(severity);
      setDuration(duration);
      setOpen(true);
    };

    const handleClose = (event, reason) => {
      if (reason === "clickaway") {
        return;
      }
      setOpen(false);
    };

    return (
      <>
        <WrappedComponent {...props} snackbarShowMessage={showMessage} />
        <Portal>
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center"
            }}
            autoHideDuration={duration}
            open={open}
            onClose={handleClose}
            TransitionComponent={Slide}
            sx={{
              position: "fixed",
              bottom: 0,
              zIndex: 10000,
            }}
          >
            <Alert variant="filled" severity={severity}>
              {message}
            </Alert>
          </Snackbar>
        </Portal>
      </>
    );
  };
};
