import {
  Box,
  Button,
  CircularProgress,
  useMediaQuery,
} from '@mui/material';
import moment from "moment";
import MUIDataTable from "mui-datatables";
import React, { useState } from "react";
import { deleteTacheContact, getAllMesTaches } from "../../Services/TacheContactService";
import qs from "query-string";
import { Delete } from '@mui/icons-material';
import { Link } from "react-router-dom";
import { withSnackbar } from "../../../utils/SnackbarHOC";
import { handleOnViewColumnsChange, displayColumn } from "../../../utils/viewColumns";
import DialogDeleteCustom from "../../../components/DialogDeleteCustom/DialogDeleteCustom";
import LabelHtmlToTextCustom from "../../../components/LabelHtmlToTextCustom/LabelHtmlToTextCustom";


function ContactTasksTable({ snackbarShowMessage, searchParams, reloadingData, setReloadingData }) {
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [tachesContact, setTachesContact] = useState([]);
  const [tacheContactInfo, setTacheContactInfo] = useState({ affectePar: "", tacheId: "" });
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [loadingToDelete, setLoadingToDelete] = useState(false);
  const [tempTachesContact, setTempTachesContact] = useState([]);
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const isSmall = useMediaQuery((theme) => theme.breakpoints.between(0, 767));
  const isMediumMode = useMediaQuery((theme) => theme.breakpoints.between(767, 1023));
  const isLargeMode = useMediaQuery((theme) => theme.breakpoints.between(1023, 2800));
  const tableBodyMaxHeight = `${isSmall ? viewportHeight - 260 : isMediumMode ? viewportHeight - 440 : isLargeMode ? viewportHeight - 420 : viewportHeight - 420}px`;

  React.useEffect(() => {
    const handleResize = () => {
      setViewportHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const handlePageChange = (page) => {
    setPage(page + 1);
  };

  const handleOpenDialog = (params) => {
    setTacheContactInfo({ affectePar: params.rowData[6], tacheId: params.rowData[0] });
    setOpenDialogDelete(true);
  };

  const handleCloseDialog = () => {
    if (loadingToDelete) {
      return;
    }
    setOpenDialogDelete(false);
  };

  const handleDeleteTask = () => {
    (async () => {
      setLoadingToDelete(true);
      try {
        snackbarShowMessage("Patientez svp!", "warning", null);
        await deleteTacheContact(tacheContactInfo.tacheId);
        snackbarShowMessage("Tâche du Contact bien supprimé!", "success", 4000);
        handleCloseDialog();
        setReloadingData(!reloadingData);
      } catch (error) {
        snackbarShowMessage("Erreur de suppression de la données: " + error.message, "error", 4000);
      } finally {
        setLoadingToDelete(false);
      }
    })();
  };

  React.useEffect(() => {
    let active = true;
    (async () => {
      setLoading(true);
      setTachesContact([]);
      try {
        const newTachesCandidat = await getAllMesTaches(page, qs.stringify(searchParams));
        if (!active) {
          return;
        }
        setTachesContact(newTachesCandidat.data.mesTachesContact);
        setCount(newTachesCandidat.data.count);
        if (tempTachesContact.length > 0) {
          setTempTachesContact([...tachesContact]);
        }
      } catch (error) {
        if (!active) {
          return;
        }
        snackbarShowMessage("Erreur de chargement des tâches contact: " + error.message, "error", 4000);
      }
      finally {
        setLoading(false);
      }
    })();

    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, reloadingData]);

  const tableName = "contactTasksColumnVisibility";

  const columns = [
    {
      name: "id",
      label: "N°TÂCHE",

      options: {
        display: false,
        filter: true,
        sort: false,
        setCellProps: () => ({ style: { whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", width: "100px" } }),
        setCellHeaderProps: () => ({ style: { textAlign: "center", maxWidth: "100px" } }),
        customBodyRender: (value) => {
          return (
            <>
              <Box data-toggle="tooltip" title={value}>
                {((value.substring(0, 30).replace(/\D/g, ""))).substring(1, 7)}
              </Box>
            </>
          );
        }
      },
    },
    {
      name: "createdAt",
      label: "CREE LE",
      options: {
        display: displayColumn("createdAt", tableName),
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({ style: { whiteSpace: "nowrap" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(new Date(value)).format("DD/MM/YYYY");
        },
      },
    },

    {
      name: "societe",
      label: "SOCIETE",
      options: {
        display: displayColumn("societe", tableName),
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Box data-toggle="tooltip" title={value} style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", width: "100px" }}>
                <Link to={{ pathname: `/societe/${tableMeta.rowData[9]}/view` }}>{value}</Link>
              </Box>
            </>
          );
        },
      },
    },
    {
      name: "status",
      label: "STATUT",
      options: {
        display: displayColumn("status", tableName),
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Box data-toggle="tooltip" title={value} style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", width: "100px" }}>{value !== null ? value : "---"}</Box>
            </>
          );
        },
      },
    },
    {
      name: "contact",
      label: "CLIENT",
      options: {
        display: displayColumn("contact", tableName),
        filter: true, sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Box data-toggle="tooltip" title={value} style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", width: "100px" }}>
                <Link to={{ pathname: `/societe/${tableMeta.rowData[9]}/contacts/${tableMeta.rowData[10]}/view` }}>{value}</Link>
              </Box>
            </>
          );
        },
      },
    },
    {
      name: "fonction",
      label: "FONCTION",
      options: {
        display: displayColumn("fonction", tableName),
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Box data-toggle="tooltip" title={value} style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", width: "100px" }}>{value !== "" ? value : "---"}</Box>
            </>
          );
        },
      },
    },
    {
      name: "commentaire",
      label: "NOTE",
      options: {
        display: displayColumn("commentaire", tableName),
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <LabelHtmlToTextCustom data-toggle="tooltip" title={value?.replace(/<\/?[^>]+(>|$)/g, "")} data={value} />
          );
        },
      },
    },

    {
      name: "dateTime",
      label: "DATE REALISÉE",
      options: {
        display: displayColumn("dateTime", tableName),
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({ style: { whiteSpace: "nowrap" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return moment(new Date(value)).format("DD/MM/YYYY");
        },
      },
    },
    {
      name: "actions",
      label: "ACTIONS",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Button data-toggle="tooltip"
              title="Supprimer" onClick={() => handleOpenDialog(tableMeta)} style={{ color: "#EF6262" }}>
              <Delete />
            </Button>
          );
        },
      },
    },
    {
      name: "societeId",
      label: "Id SOCIETE",
      options: {
        display: false,
        filter: true,
        sort: false,
        setCellProps: () => ({ style: { whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", width: "100px" } }),
        setCellHeaderProps: () => ({ style: { textAlign: "center", maxWidth: "100px" } }),
        customBodyRender: (value) => {
          return (
            <Box data-toggle="tooltip" title={value}>
              {((value.substring(0, 30).replace(/\D/g, ""))).substring(1, 7)}
            </Box>
          );
        }
      },
    },
    {
      name: "contactId",
      label: "Id CONTACT",
      options: {
        display: false,
        filter: true,
        sort: false,
        setCellProps: () => ({ style: { whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", width: "100px" } }),
        setCellHeaderProps: () => ({ style: { textAlign: "center", maxWidth: "100px" } }),
        customBodyRender: (value) => {
          return (
            <Box data-toggle="tooltip" title={value}>
              {((value.substring(0, 30).replace(/\D/g, ""))).substring(1, 7)}
            </Box>
          );
        }
      },
    },
  ];

  const options = {
    tableBodyMaxHeight: tableBodyMaxHeight,
    rowsPerPageOptions: [],
    serverSide: false,
    count: count,
    rowsPerPage: 15,
    textLabels: {
      body: {
        noMatch: loading ? <CircularProgress /> : "Désolé, il n'y a aucun enregistrement dans la base de données pour le moment.",
      },
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case "changePage":
          handlePageChange(tableState.page);
          break;
        default:
      }
    },
    onViewColumnsChange: (columnName, action) => {
      handleOnViewColumnsChange(columnName, action, tableName);
    },
    selectableRows: "none",
    draggableColumns: {
      enabled: true,
    },
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
  };

  const dialogDeleteProps = {
    handleClose: handleCloseDialog,
    open: openDialogDelete,
    handleConfirm: handleDeleteTask,
    loading: loadingToDelete,
    label: `Voulez vous vraiment supprimer cette tâche ?`,
    title: "Supprimer la tâche",
  };

  const gridData = tachesContact.map((row) => {
    const updatedRow = {
      ...row,
      createdAt: moment(row.createdAt.date, "YYYY-MM-DD").toDate().getTime(),
      dateTime: moment(row.dateTime.date, "YYYY-MM-DD").toDate().getTime()
    };
    return updatedRow;
  });

  return (
    <>
      <DialogDeleteCustom {...dialogDeleteProps} />
      <MUIDataTable data={gridData} columns={columns} options={options} />
    </>
  );
}


export default withSnackbar(ContactTasksTable)
