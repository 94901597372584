import Modal from '@mui/material/Modal';
import React, { useRef, useState } from "react";
import { Backdrop, CircularProgress, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import MUIDataTable from "mui-datatables";
import moment from "moment";
import { deleteAppointment, getAppointments } from "../../../../../Services/AppointmentService";
import SachezQueModal from "../../../../../../utils/SachezQueModal";
import { Clear } from '@mui/icons-material';
import DialogModal from "../../../../../../utils/DialogModal";

function getModalStyle() {
  const top = 0;
  const left = 0;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    height: "600px"
  };
}

const useStyles = styled((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "20px",
    width: "800px",
  },
  saveButton: {
    backgroundColor: "rgb(66, 186, 150)",
    color: "#FFF",
    fontWeight: 500,
    "&:hover, &:focus": {
      backgroundColor: "#389e7f",
      borderColor: "#359478",
    },
  },
  buttonLabel: {
    paddingLeft: "0.4em",
    marginLeft: 0,
  },
  closeButton: {
    color: "#1b2a4e",
    backgroundColor: "#d9e2ef",
    borderColor: "#d9e2ef",
    marginLeft: "0.5em",
    fontWeight: "500",
  },
  actionBar: {
    marginTop: "1.5em",
  },
  modalTitle: {
    color: "rgb(17 122 167)",
    marginBottom: "1em",
  },
  buttonProgress: {
    color: "white",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  takeAppointment: {
    color: "rgb(17 122 167)",
  },
  calendar: {
    marginRight: "0.5em",
  },
}));

const AppointmentsListModal = ({ proces, status, reloadProcess, snackbarShowMessage, ...otherProps }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [modalStyle] = useState(getModalStyle);
  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(false);
  const active = useRef(false);
  const IntegrationRegex = /[I-i]nt[e-é]gration/;
  const showDeleteButtonRegex = /RDV (\d+)|E(\d+) fix[e-é]/i;
  const [reload, setReload] = useState(false);

  const handleDeleteAppointment = ({ appointment }) => {
    setLoading(true);
    snackbarShowMessage("Suppression du rendez-vous...", "warning", null);
    deleteAppointment(appointment).then((response) => {
      snackbarShowMessage("Rendez-vous a été supprimé.", "success", 4000);
      setReload(!reload);
      reloadProcess(!reload);
    }).catch((error) => {
      snackbarShowMessage("Erreur de suppression du rendez-vous.", "error", 2000);
    }).finally(() => setLoading(false));
  }

  const handleShowDeleteButton = (step, appointment) => {
    const dialogProps = {
      label: <Clear color="error" />,
      description: "Voulez vous supprimer le rendez-vous?",
      title: "Supprimer le rendez-vous?",
      loading,
      onConfirm: handleDeleteAppointment,
      data: {
        appointment
      }
    }
    return (
      <>
        {
          showDeleteButtonRegex.test(step) && <DialogModal {...dialogProps} />
        }
      </>
    )
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    let isActive = true;
    active.current = true;
    if (open) {
      (async () => {
        setLoading(true);

        try {
          let reponse = await getAppointments(proces);
          if (!isActive) {
            return;
          }
          setAppointments(reponse.data.appointments);
          setLoading(false);
        } catch (error) {
          if (!isActive) {
            return;
          }
          otherProps.snackbarShowMessage("Erreur de chargement des rendez-vous.", "error", 4000);
          setLoading(false);
        }
      })();
    }

    return () => {
      setAppointments([]);
      isActive = false;
      active.current = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, reload]);

  const columns = [
    {
      name: "idRDV",
      label: "N° RENDEZ-VOUS",
      options: {
        display: false,
      },
    },
    {
      name: "statusRDV",
      label: "STEP",
      options: {
        setCellHeaderProps: () => ({ style: { justifyContent: "center" } }),
      },
    },
    {
      name: "dateRDV",
      label: "DATE",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>
            {tableMeta.rowData[1].match(IntegrationRegex) ? (
              moment(value).format("DD/MM/yyyy") + "-" + moment(tableMeta.rowData[4]).format("DD/MM/yyyy")
            ) : (
              value ? moment(value).format("DD/MM/yyyy") : "-"
            )}
          </>;
        },
        setCellHeaderProps: () => ({ style: { justifyContent: "center" } }),
      },
    },
    {
      name: "noteRDV",
      label: "NOTE",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid container>
              <Grid item xs={6}>
                <SachezQueModal sachezQue={value} modalTitle={"Commentaire "} />
              </Grid>
              <Grid item xs={6}>
                {handleShowDeleteButton(tableMeta.rowData[1], tableMeta.rowData[0])}
              </Grid>
            </Grid>
          );
        },
        setCellHeaderProps: () => ({ style: { justifyContent: "center" } }),
      },
    },
    { name: "dateIntegration", options: { display: false } }
  ];

  const options = {
    tableBodyMaxHeight: 400,
    textLabels: {
      body: {
        noMatch: loading ? <CircularProgress /> : "Désolé, il n'y a aucun enregistrement dans la base de données pour le moment.",
      },
    },
    selectableRows: "none",
    draggableColumns: {
      enabled: true,
    },
    filter: true,
    filterType: "dropdown",
    responsive: "simple",
  };

  const body = (
    <div style={modalStyle} >
      <MUIDataTable data={appointments} columns={columns} title="Liste des STEPS" options={options} />
    </div>
  );

  return (
    <>
      {status ? (
        <button className="btn btn-sm btn-link" style={{ textDecoration: "none" }} onClick={handleOpen}>
          {status}
        </button>
      ) : (
        "-"
      )}
      <Modal
        className={classes.modal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        open={open}
        onClose={handleClose}
      >
        {body}
      </Modal>
    </>
  );
}
export default AppointmentsListModal;