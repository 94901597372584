import React, { useState } from "react";
import { HeaderDesktop, HeaderMobile } from "./layout/appHeader/index";
import NavBar from "./layout/appNav/VerticalNavWrapper";
import {
  Login
} from "./pages/index";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./app.css";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import classNames from "classnames";
import { withSnackbar } from "./utils/SnackbarHOC";
import useOnlineStatus from "./utils/useOnlineStatus ";
import { Box, ThemeProvider, createTheme, useMediaQuery } from "@mui/material";
import { theme } from "./utils/Theme";
import ResponsiveLayout from "./layout/LayoutReponsive/LayoutResponsive";
import PrivatesRoutes from "./routes";
import { Provider } from "react-redux";
import store from "./redux/store/store";
import SwipeableDrawerSummary from "./components/SwipeableDrawerSummary";
import MailingModal from "./components/MailingModal/MailingModal";

function App({ snackbarShowMessage }) {
  const [sideBarCollapsed, setSideBarCollapsed] = useState(false);
  const isOnline = useOnlineStatus();

  const isMdMode = useMediaQuery(theme.breakpoints.down('md'));
  const isSmMode = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClick = () => {
    setSideBarCollapsed(!sideBarCollapsed);
  };

  const navBarProps = {
    sideBarCollapsed: sideBarCollapsed,
  }

  const getMuiTheme = () =>
    createTheme({
      components: {
        MuiTableCell: {
          styleOverrides: {
            root: {
              backgroundColor: "#FFF",
              padding: "8px",
            },
          },
        },
      },
    });

  return (
    <Provider store={store}>
      <ThemeProvider theme={getMuiTheme()}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <BrowserRouter>
            <Switch>
              <Route path="/login">
                <Login snackbarShowMessage={snackbarShowMessage} />
              </Route>
              <>
                <div
                  className={classNames({
                    main_content: true,
                    dashboard_part: true,
                  })}
                >
                  <Box className="box">
                    <Box className="box_container">
                      {!isMdMode &&
                        <Box className="box_sideBar">
                          <NavBar
                            {...navBarProps}
                            onClick={handleClick}
                          />
                        </Box>}
                      <Box className="box_main">
                        <Box className="box_header " sx={isSmMode ? { marginBottom: "4rem" } : {}}>
                          {isMdMode ? <HeaderMobile handleClick={handleClick} /> : <HeaderDesktop handleClick={handleClick} />}
                        </Box>
                        <ResponsiveLayout >
                          <PrivatesRoutes snackbarShowMessage={snackbarShowMessage}/>
                        </ResponsiveLayout>
                      </Box>
                    </Box>
                  </Box>
                  <MailingModal />
                  <SwipeableDrawerSummary />
                </div>
              </>
            </Switch>
          </BrowserRouter>
          {!isOnline && snackbarShowMessage("Vous êtes déconnecté...", "error", 4000)}
        </LocalizationProvider>
      </ThemeProvider>
    </Provider>
  );
}

export default withSnackbar(App);
