import {
  Backdrop,
  Fade,
  useMediaQuery
} from '@mui/material';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  styled,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from '@mui/material';
import { Autocomplete } from '@mui/lab';
import React, {useEffect, useRef, useState} from "react";
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { getDetails, edit, addContact, getMissionContact } from "../../Services/ContactService";
import { getConsultantsAndManagers } from "../../Services/UserService";
import 'react-phone-number-input/style.css'
import { getAllDirectionForSelect } from "../../Services/DirectionService";
import { getAll } from "../../Services/SocieteService";
import Modal from '@mui/material/Modal';
import Input from "react-phone-number-input";
import useDebounce from "../../../utils/useDebounce";
import { theme } from "../../../utils/Theme";
import EditorCustom from "../../../components/EditorCustorm/EditorCustom";
import Loader from "../../../components/Loader/Loader";
import { removeLineBreak } from "../../../utils/EditorUtil";
import LoadingButtonCustom from "../../../components/LoadingButtonCustom/LoadingButtonCustom";
import { withSnackbar } from "../../../utils/SnackbarHOC";

function getModalStyle() {
  const top = 0;
  const left = 0;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = styled((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "20px",
    width: "450px",
  },
  closeButton: {
    color: "#1b2a4e",
    backgroundColor: "#d9e2ef",
    borderColor: "#d9e2ef",
    marginLeft: "0.5em",
    fontWeight: "500",
  },
  modalTitle: {
    color: "rgb(17 122 167)",
  },

}));
const toInputUppercase = e => {
  e.target.value = ("" + e.target.value).toUpperCase();
};
const ContactForm = ({ editMode, snackbarShowMessage }) => {
  const history = useHistory();
  const classes = useStyles();
  const [newTitre, setNewTitre] = useState("");
  const [newNom, setNewNom] = useState("");
  const [newPrenom, setNewPrenom] = useState("");
  const [newFonction, setNewFonction] = useState("");
  const [newStatut, setNewStatut] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [newMobile, setNewMobile] = useState("");
  const [newTelFix, setNewTelFix] = useState("");
  const [newLinkedIn, setNewLinkedIn] = useState("");
  const [newSachezQue, setNewSachezQue] = useState("");
  const [newdatePremierContact, setNewdatePremierContact] = useState("");
  const [newdatederniercontact, setNewdatederniercontact] = useState("");
  const [newsource, setnewsource] = useState("");
  const [newstep, setnewstep] = useState("");
  const [newAccountManager, setNewAccountManager] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [loadingManagers, setLoadingManagers] = useState(true);
  const [managers, setManagers] = useState([]);
  const [loadData, setLoadData] = useState(true);
  const { id } = useParams();
  const { idContact } = useParams();
  const location = useLocation();
  const { nomSociete } = location?.state;
  let mounted = useRef(false);
  const [directions, setDirections] = useState([]);
  const [directionSociete, setDirectionSociete] = useState("");
  const [open, setOpen] = useState(false);
  const [ste, setSte] = useState([]);
  const [firstChar, setFirstChar] = useState("");
  const missionsTermines = false;
  const [missionsContact, setMissionsContact] = useState([]);
  const [modalStyle] = useState(getModalStyle);
  const [modalState, setModalState] = useState({ open: false });
  const [newEmployeur, setNewEmployeur] = useState("");
  const [datePhoning, setDatePhonning] = useState("");
  const [dmrLKD, setDmrLKD] = useState("");
  const [dmr, setDmr] = useState("");
  const [phone, setPhone] = useState("")
  const debouncedSearch = useDebounce(firstChar, 500)
  const [loadingSocietes, setLoadingSocietes] = useState(false);
  const isSmMode = useMediaQuery(theme.breakpoints.between(0, 480));



  const validate = () => {
    let temp = {};
    temp.titre = newTitre ? "" : "Le champ 'TITRE' est obligatoire.";
    temp.prenom = newPrenom ? "" : "Le champ 'PRENOM' est obligatoire.";
    temp.fonction = newFonction ? "" : "Le champ 'FONCTION' est obligatoire.";
    temp.nom = newNom ? "" : "Le champ 'NOM' est obligatoire.";
    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };


  useEffect(() => {
    let active = true;
    if (editMode) {
      (async () => {
        setLoadData(true);
        try {
          const res = await getDetails(idContact);
          if (!active) {

            return;

          }
          setNewTitre(res.data.titre);
          setNewPrenom(res.data.prenom);
          setNewNom(res.data.nom);
          setNewFonction(res.data.fonction);
          setNewStatut(res.data.statut);
          setNewAccountManager(res.data.accountManager);
          setNewEmail(res.data.email);
          setNewMobile(res.data.mobile);
          setNewTelFix(res.data.telFix);
          setNewLinkedIn(res.data.linkedIn);
          setNewSachezQue(res.data.sachezQue);
          setNewdatePremierContact(res.data.premiercontact);
          setNewdatederniercontact(res.data.derniercontact);
          setnewsource(res.data.source);
          setnewstep(res.data.step)
          setDirectionSociete(res.data.direction);
          setPhone(res.data.phoning);
          setDmr(res.data.dmr);
          setDmrLKD(res.data.dmrLKD);
          setDatePhonning(res.data.datePhoning);
          setLoadData(false);
        } catch (error) {
          if (!active) {
            return;
          }
          setLoadData(false);
        }
      })();

      return () => {
        active = false;
      };
    }
  }, [idContact, editMode]);

  useEffect(() => {
    (async () => {
      try {
        setLoadingSocietes(true);
        setSte([])
        if (firstChar) {
          const params = `?firstChar=${firstChar}`
          await getAll(params).then((data) => {
            setSte(data.data)
          });
        }
      } catch (err) {
        snackbarShowMessage("Erreur de chargement du des sociétés!", "error", 4000);
      } finally {
        setLoadingSocietes(false)
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);

  useEffect(() => {
    let active = true;
    mounted.current = true;
    (async () => {
      setLoadingManagers(true);
      try {
        const newUsers = await getConsultantsAndManagers();
        const directionResponse = await getAllDirectionForSelect();
        await getAll("").then((data) => {
          setSte(data.data)
        });
        if (!active) {
          return;
        }
        setManagers(newUsers.data.managers);
        setDirections(directionResponse.data);
      } catch (error) {
        if (!active) {
          return;
        }
      } finally {
        setLoadingManagers(false);
      }
    })();
    return () => {
      active = false;
      mounted.current = false;
    };
  }, []);


  const handleSubmit = () => {
    if (validate()) {
      setLoading(true);
      (async () => {
        const societe = newEmployeur ? newEmployeur : id;
        let data = {
          titre: newTitre,
          nom: newNom,
          prenom: newPrenom,
          fonction: newFonction,
          statut: newStatut,
          accountManager: newAccountManager,
          email: newEmail,
          mobile: newMobile,
          telFixDirect: newTelFix,
          linkedIn: newLinkedIn,
          sachezQue: removeLineBreak(newSachezQue),
          societe: societe,
          premiercontact: newdatePremierContact,
          derniercontact: newdatederniercontact,
          source: newsource,
          step: newstep,
          direction: directionSociete,
          phoning: phone,
          dmrLKD: dmrLKD,
          dmr: dmr,
          datePhoning: datePhoning
        };
        try {
          const response = await addContact(data);
          if (!mounted.current) {
            return;
          }
          setLoading(false);
          if (response.data.code === 400) {
            snackbarShowMessage(response.data.error);
            setLoading(false);
            return;
          }
          history.push({
            pathname: `/societe/${id}/contacts/${response.data.contactId}/view`,
            state: { snack: true, msg: "Le contact a été ajouté avec succès.", severity: "success" },
          });
        } catch (error) {
          if (!mounted.current) {
            return;
          }
          setLoading(false);
          snackbarShowMessage(error.response.data.error.detail, "error", 4000);
        }
      })();
    }
  };

  const handleUpdate = () => {
    if (validate()) {
      setLoading(true);
      (async () => {
        const societe = newEmployeur ? newEmployeur : id;
        const contact = {
          titre: newTitre,
          nom: newNom,
          prenom: newPrenom,
          fonction: newFonction,
          statut: newStatut,
          accountManager: newAccountManager,
          email: newEmail,
          mobile: newMobile,
          telFixDirect: newTelFix,
          linkedIn: newLinkedIn,
          sachezQue: removeLineBreak(newSachezQue),
          societe: societe,
          premiercontact: newdatePremierContact,
          derniercontact: newdatederniercontact,
          source: newsource,
          step: newstep,
          direction: directionSociete,
          phoning: phone,
          dmrLKD: dmrLKD,
          dmr: dmr,
          datePhoning: datePhoning
        };
        try {
          if (missionsContact.length !== 0 && editMode && firstChar !== "") {
            handleOpen();
            setLoading(false);
          } else {
            const response = await edit(contact, idContact);
            if (!mounted.current) {
              return;
            }
            setLoading(false);
            history.push({
              pathname: `/societe/${id}/contacts/${idContact}/view`,
              state: { snack: true, snackMsg: response.data, severity: "success" },
            });
          }
        } catch (error) {
          if (!mounted.current) {
            return;
          }
          snackbarShowMessage(error.response.data.error.detail, "error", 4000);
          setLoading(false);
        }
      })();
    }
  };

  useEffect(() => {
    if (idContact !== null) {
      let isMounted = true;

      const fetchData = async () => {
        setLoading(true);
        setMissionsContact([]);

        try {
          const newMissionsContact = await getMissionContact(idContact, 1, 50, missionsTermines ? "passive" : "active");
          if (isMounted) {
            setMissionsContact(newMissionsContact.data.missionsContact);
          }
        } catch (error) {
          // Tratar o erro, se necessário
        } finally {
          if (isMounted) {
            setLoading(false);
          }
        }
      };

      fetchData();

      return () => {
        isMounted = false;
      };
    }
  }, [idContact, missionsTermines]);


  const handleOpen = () => {
    setModalState({ open: true });
  };
  const handleChangeSociete = (event) => {
    setFirstChar(event.target.value);
  }
  const handleSelectSociete = (event, values) => {
    setNewEmployeur(values?.uuid);
  }
  const handleClose = () => {
    if (loading) {
      return;
    }
    setModalState({ open: false });
  };
  const body = (
    <div style={modalStyle} className={classes.paper}>
      <div className="modal-header">
        <h4 className={classes.modalTitle}>Modifier une Contact</h4>
      </div>
      <div className="modal-body">
        <label className="input-label" htmlFor="titre">
          Impossible de modifier le champs société tant que
          ce contact est Destinataire principal sur une
          mission en cours. <br />
          Merci de le modifier d'abord
        </label>
      </div>
      <div className="modal-footer">
        <Button className={classes.closeButton} onClick={handleClose}>
          Close
        </Button>
      </div>
    </div>
  );
  const editorCustomProps = {
    value: newSachezQue,
    setValue: setNewSachezQue,
    enableOnBlur: false
  };

  const loadingButtonProps = {
    loading,
    editMode,
    handleUpdate,
    handleSubmit,
  }

  return (

    <Box sx={{ padding: "0 20px " }}>
      {loadData & editMode ? (
        <Loader />
      ) : (
        <>
          <Box className="candidatedit_header">
            <Grid container spacing={1} direction={isSmMode ? "column-reverse" : ""} >
              <Grid item xs={12} sm={6} md={6} xl={6}>
                <h4> {`${newTitre}  ${newPrenom}  ${newNom}`}</h4>
              </Grid>
              <Grid item xs={12} sm={6} md={6} xl={6}>
                <Box sx={{ display: "flex", justifyContent: "end", gap: "10px", width: "100%" }}>
                  <Button
                    onClick={() => history.goBack()}
                    variant="outlined"
                    disabled={loading}
                    sx={{
                      color: "#1b2a4e",
                      backgroundColor: "#d9e2ef",
                      borderColor: "#d9e2ef",
                    }}
                  >
                    Annuler
                  </Button>
                  <LoadingButtonCustom {...loadingButtonProps} />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box className="candidatedit_container">
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <Card style={{ padding: "2em", height: "100%" }}>
                  <label className="input-label" htmlFor="titre">
                    TITRE <span className="required-input">*</span>
                  </label>
                  <FormControl variant="outlined" fullWidth margin="normal">
                    <InputLabel
                      {...(errors.titre && {
                        error: true,
                      })}
                      shrink
                    >
                      {errors.titre}
                    </InputLabel>
                    <Select
                      value={newTitre}
                      name="titre"
                      onChange={(e) => setNewTitre(e.target.value)}
                      {...(errors.titre && {
                        error: true,
                      })}
                      input={
                        <OutlinedInput
                          name="titre"
                          {...(errors.titre && {
                            labelWidth: 230,
                          })}
                          notched
                        ></OutlinedInput>
                      }
                    >
                      <MenuItem value="">
                        <em>-</em>
                      </MenuItem>
                      <MenuItem value={"Mr "}>Mr</MenuItem>
                      <MenuItem value={"Mme "}>Mme</MenuItem>
                    </Select>
                  </FormControl>
                  <label className="input-label" htmlFor="prenom">
                    PRÉNOM<span className="required-input">*</span>
                  </label>
                  <TextField
                    onChange={(e) => setNewPrenom(e.target.value.toLowerCase())}
                    name="prenom"
                    value={newPrenom}
                    placeholder="Prenom du contact."
                    margin="normal"
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    {...(errors.prenom && {
                      error: true,
                      label: errors.prenom,
                    })}
                  />
                  <label className="input-label">
                    NOM<span className="required-input">*</span>
                  </label>
                  <TextField
                    name="nom"
                    value={newNom}
                    onChange={(e) => setNewNom(e.target.value.toUpperCase())}
                    margin="normal"
                    variant="outlined"
                    autoComplete="off"
                    onInput={toInputUppercase}
                    fullWidth
                    placeholder="Nom du contact."
                    {...(errors.nom && {
                      error: true,
                      label: errors.nom,
                    })}
                  />
                  <label className="input-label">FONCTION
                    <span className="required-input">*</span>
                  </label>
                  <TextField
                    name="fonction"
                    value={newFonction}
                    onChange={(e) => setNewFonction(e.target.value)}
                    margin="normal"
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    placeholder="Fonction du contact."
                    {...(errors.fonction && {
                      error: true,
                      label: errors.fonction,
                    })}
                  />
                  <label className="input-label" htmlFor="direction">
                    DIRECTION
                  </label>
                  <FormControl variant="outlined" fullWidth margin="normal">
                    <InputLabel
                      {...(errors.direction && {
                        error: true,
                      })}
                      shrink
                      htmlFor="direction"
                    >
                      {errors.direction}
                    </InputLabel>
                    <Select
                      value={directionSociete}
                      name="direction"
                      onChange={(e) => setDirectionSociete(e.target.value)}
                      {...(errors.direction && {
                        error: true,
                      })}
                      input={
                        <OutlinedInput
                          name="direction"
                          notched
                          {...(errors.direction && {
                            labelWidth: 340,
                          })}
                        >
                        </OutlinedInput>
                      }
                    >
                      {directions.map((dir) => (
                        <MenuItem value={dir.uuid} key={dir.uuid}>
                          {dir.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <label className="input-label" htmlFor="statut">
                    STATUT
                  </label>
                  <FormControl variant="outlined" fullWidth margin="normal">
                    <InputLabel
                      {...(errors.statut && {
                        error: true,
                      })}
                      shrink
                      htmlFor="statut"
                    >
                      {errors.statut}
                    </InputLabel>
                    <Select
                      value={newStatut}
                      name="statut"
                      id="statut"
                      onChange={(e) => setNewStatut(e.target.value)}
                      {...(errors.statut && {
                        error: true,
                      })}
                      input={
                        <OutlinedInput
                          name="statut"
                          notched
                          {...(errors.statut && {
                            labelWidth: 240,
                          })}
                        ></OutlinedInput>
                      }
                    >
                      <MenuItem value="Client">Client</MenuItem>
                      <MenuItem value="Prospect">Prospect</MenuItem>
                    </Select>
                  </FormControl>
                  <label className="input-label" htmlFor="accountManager">
                    DELIVERY MANAGER
                  </label>
                  <FormControl variant="outlined" fullWidth margin="normal">
                    <InputLabel
                      {...(errors.accountManager && {
                        error: true,
                      })}
                      shrink
                      htmlFor="accountManager"
                    >
                      {errors.accountManager}
                    </InputLabel>
                    {loadingManagers ? (
                      <Box display="flex" justifyContent="center">
                        <CircularProgress />
                      </Box>
                    ) : (
                      <Select
                        value={newAccountManager}
                        name="accountmanager"
                        onChange={(e) => setNewAccountManager(e.target.value)}
                        {...(errors.accountManager && {
                          error: true,
                        })}
                        input={
                          <OutlinedInput
                            name="accountManager"
                            notched
                            {...(errors.accountManager && {
                              labelWidth: 340,
                            })}
                          ></OutlinedInput>
                        }
                      >
                        {managers.map((manager) => (
                          <MenuItem key={manager.uuid} value={manager.uuid}>
                            <span style={{ textTransform: "capitalize" }}> {manager.user} </span>
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  </FormControl>
                </Card>
              </Grid>
              <Grid item xs={12} md={6} className={classes.secondCard}>
                <Card style={{ padding: "2em", height: "100%" }}>
                  <label className="input-label" htmlFor="idCandidat">
                    SOCIÉTÉ
                  </label>
                  <Autocomplete
                    open={open}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    getOptionSelected={(option, value) => option.uuid === value.uuid}
                    getOptionLabel={(option) => option?.nomSociete}
                    onChange={handleSelectSociete}
                    options={ste}
                    loading={loadingSocietes}
                    loadingText="Chargement des sociétés"
                    filterOptions={(options, state) => options}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        onChange={handleChangeSociete}
                        placeholder={nomSociete ? nomSociete : ""}
                        {...(errors.employeur && {
                          error: true,
                          label: errors.employeur,
                        })}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loadingSocietes ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                  <label className="input-label">
                    EMAIL PRO<span className="required-input"></span>
                  </label>
                  <TextField
                    name="email"
                    value={newEmail}
                    onChange={(e) => setNewEmail(e.target.value)}
                    margin="normal"
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    placeholder="Email du contact."
                    {...(errors.email && {
                      error: true,
                      label: errors.email,
                    })}
                  />
                  <label className="input-label">MOBILE</label>
                  <Input
                    defaultCountry="FR"
                    onChange={setNewMobile}
                    name="mobile"
                    value={newMobile}
                    margin="normal"
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    {...(errors.newMobile && {
                      error: true,
                      label: errors.newMobile,
                    })}
                  />
                  <label className="input-label">TÉL. FIXE DIRECT</label>
                  <Input
                    defaultCountry="FR"
                    onChange={setNewTelFix}
                    name="telFixDirect"
                    value={newTelFix}
                    margin="normal"
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    {...(errors.newTelFix && {
                      error: true,
                      label: errors.newTelFix,
                    })}
                  />
                  <label className="input-label">LINKEDIN
                  </label>
                  <TextField
                    name="linkedin"
                    value={newLinkedIn}
                    onChange={(e) => setNewLinkedIn(e.target.value)}
                    margin="normal"
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    placeholder="https://linkedin.com/in/profile."
                    style={{ marginBottom: "1.2em" }}
                    {...(errors.linkedIn && {
                      error: true,
                      label: errors.linkedIn,
                    })}
                  />
                  <label className="input-label">SACHEZ QUE</label>
                  <EditorCustom {...editorCustomProps} />
                </Card>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
      <Modal
        className={classes.modal}
        open={modalState.open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalState.open}>{body}</Fade>
      </Modal>
    </Box>
  );
};
export default ContactForm;
