import React from "react";
import FilePreview from "../Controls/FilePreviews";
import { Card, styled, Grid, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import LabelHtmlToTextCustom from "../../../../components/LabelHtmlToTextCustom/LabelHtmlToTextCustom";
import { v4 } from "uuid";
const useStyles = styled({
  cardSynthese: {
    padding: "15px",
    minHeight: "200px",
  },
  candidatFiles: {
    height: "70px",
  },
  titles: {
    margin: "1em 1em 0.1em 1em",
  },
  backtable: {
    backgroundColor: "rgb(17 72 167 / 4%)",
    width: "100%"
  },
  cards: {
    height: "auto",
  },

});

export default function SyntheseConsultant({ commentaire, softskills, pointsforts, uploadedFiles }) {
  const classes = useStyles();
  let cvs = uploadedFiles
    ? uploadedFiles.filter(function (file) {
      if (file.categorie === "cv") {
        return true;
      }
      return false;
    })
    : [];
  let cvsFormates = uploadedFiles
    ? uploadedFiles.filter(function (file) {
      if (file.categorie === "cvFormate") {
        return true;
      }
      return false;
    })
    : [];
  let autresPieces = uploadedFiles
    ? uploadedFiles.filter(function (file) {
      if (file.categorie === "autrePiece") {
        return true;
      }
      return false;
    })
    : [];

  return (
    <>
      {[
        {
          title: "POINTS FORTS",
          content: pointsforts,
        },
        {
          title: "SOFT SKILLS",
          content: softskills,
        },
        {
          title: "COMMENTAIRES",
          content: commentaire,
        },
      ].map((section, index) => (
        <Card key={v4()} className={(classes.cards, classes.backtable)}>
          <TableContainer className={classes.table}>
            <Table className="table table-striped">
              <TableBody component="tbody">
                <TableRow>
                  <TableCell scope="row" style={{ fontSize: "0.8rem", width: "20%" }}>
                    <strong>{section.title}</strong>
                  </TableCell>
                  <TableCell align="left" style={{ fontSize: "0.8rem" }}>
                    {section.content?.split('\n').map((item, index) => (
                      <LabelHtmlToTextCustom key={index + item} data={item} />
                    ))}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      ))}

      <Grid container spacing={2} style={{ padding: "10px" }}>
        {[
          {
            title: "CV",
            files: cvs,
          },
          {
            title: "CV FORMATÉ",
            files: cvsFormates,
          },
          {
            title: "AUTRES",
            files: autresPieces,
          },
        ].map((section, index) => (
          <Grid key={v4()} item xs={12} sm={4} md={4} lg={4}>
            <h3 className={classes.titles} style={{ marginTop: "1rem", textAlign: "center" }}>{section.title}</h3>
            <Card className={classes.candidatFiles}>
              <FilePreview files={section.files} />
            </Card>
          </Grid>
        ))}
      </Grid>
    </>

  );
}
