import React, { useState, useRef } from "react";
import { Button, TextField, Link, Grid, Container, CircularProgress, Card } from '@mui/material';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { loginUser, getRedirectUrl } from "../Services/AuthentificationService";
import useToken from "../../utils/useToken";
import { Redirect, useLocation } from "react-router-dom";

const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '& .logo': {
    width: '200px',
    marginBottom: theme.spacing(2)
  },
  '& form': {
    width: '100%',
    marginTop: theme.spacing(1)
  }
}));

export default function Login({ snackbarShowMessage }) {
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [loading, setLoading] = useState(false);
  const mounted = useRef(true);
  const {setToken} = useToken();
  const [redirectToReferrer, setRedirectToReferrer] = useState(localStorage.getItem("token") ? true : false);
  const {state} = useLocation();
  const [errors, setErrors] = useState({});

  const validate = () => {
    let temp = {};
    temp.username = username ? "" : "'ADRESSE EMAIL' est obligatoire.";
    temp.password = password ? "" : "'MOT DE PASSE' est obligatoire.";
    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      try {
        const response = await loginUser({
          username,
          password,
        });

        if (mounted.current) {
          // Stocker le token et refresh token
          setToken(response.data);
          localStorage.setItem("refresh_token", response.data.refresh_token);
          
          // Stocker les données utilisateur
          const userData = response.data.data;
          localStorage.setItem("userData", JSON.stringify(userData));
          
          // Stocker le rôle principal de l'utilisateur
          localStorage.setItem("rolecurrentuser", userData.role[0]);
          
          snackbarShowMessage("Connexion réussie", "success", 4000);
          setRedirectToReferrer(true);
        }
      } catch (err) {
        if (mounted.current) {
          snackbarShowMessage(
            err.response?.data?.message || "Une erreur s'est produite lors de la connexion",
            "error",
            4000
          );
        }
      } finally {
        if (mounted.current) {
          setLoading(false);
        }
      }
    }
  };

  if (redirectToReferrer) {
    const redirectUrl = getRedirectUrl();
    return <Redirect to={redirectUrl} />;
  }

  return (
    <Container component="main" maxWidth="xs" sx={{ marginTop: "16em" }}>
      <CssBaseline />
      <StyledCard>
        <div className="paper">
          <img
            src={process.env.REACT_APP_LOGO_URL}
            alt="Go & Dev Logo"
            style={{width: "200px", marginBottom: "20px", position: "relative", left: "60px"}}
            className="logo"
          />
          <form className="form" noValidate onSubmit={handleSubmit}>
            <div style={{marginBottom: "10px"}}>
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Adresse email"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={(e) => setUsername(e.target.value)}
                {...(errors.username && {
                  error: true,
                  label: errors.username,
                })}
            />
            </div>
            <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Mot de passe"
                type="password"
                autoComplete="current-password"
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={handleKeyPress}
                {...(errors.password && {
                  error: true,
                  label: errors.password,
                })}
            />

            <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Se souvenir de moi"
                sx={{ marginTop: 3 }}
            />


            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className="submit"
              disabled={loading}
              sx={{ mt: 3, mb: 2 }}
            >
              {loading ? <CircularProgress size={16} /> : "Se Connecter"}
            </Button>
            <Grid container>
              <Grid item>
                <Link href="#" variant="body2">
                  Mot de passe oublié?
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
      </StyledCard>
    </Container>
  );
}
