import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import {
  Box,
  Card,
  CardContent,
  Typography,
  IconButton,
  Tooltip,
  CircularProgress,
  Grid
} from '@mui/material';
import { RemoveRedEye as RemoveRedEyeIcon } from '@mui/icons-material';
import { Link as RouterLink } from 'react-router-dom';
import MUIDataTable from "mui-datatables";
import moment from 'moment';
import { useParams, useHistory } from "react-router-dom";
import { getAllCandidatsForSociete } from "../../../Services/CandidatService";
import { withSnackbar } from "../../../../utils/SnackbarHOC";
import { displaySwipeableDrawerSummaryCandidat } from "../../../../redux/SwipeableDrawer/actions/swipeableDrawerActions";
import { useDispatch } from "react-redux";

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[1],
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(2),
  '& .MuiCardContent-root': {
    padding: theme.spacing(2)
  }
}));

const StyledTableCell = styled(Box)(({ theme }) => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  padding: theme.spacing(1),
  '& .MuiTypography-root': {
    fontSize: '0.875rem'
  }
}));

const ActionButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(1),
  '&:hover': {
    backgroundColor: theme.palette.action.hover
  }
}));

const CandidatsSociete = ({ snackbarShowMessage }) => {
  const history = useHistory();
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [candidatsSociete, setCandidatsSociete] = useState([]);
  const { id } = useParams();
  const dispatch = useDispatch()
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setViewportHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setLoading(true);
    getAllCandidatsForSociete(id).then((data) => {
      setCandidatsSociete(data.data.candidats);
      setCount(data.data.count);
    }).catch((error) => {
      snackbarShowMessage("Erreur de chargement des candidats.", "error", 2000);
    }).finally(() => {
      setLoading(false);
    });
  }, [id]);

  const viewCandidat = (candidatId, tabIndex) => {
    let candidatsIds = [];

    candidatsSociete.forEach((candidat) => {
      candidatsIds.push(candidat.id);
    });

    history.push({
      pathname: `/candidats/${candidatId}/view`,
      state: {
        tabIndex: tabIndex,
        candidatsInList: candidatsIds,
        candidatIndex: candidatsIds.indexOf(candidatId),
        numbersTotalOfSelections: candidatsSociete.length
      },
    });
  };

  const columns = [
    {
      name: "id",
      label: "N°",
      options: {
        filter: true,
        sort: true,
        display: false,
        customBodyRender: (value) => (
          <StyledTableCell>
            <Typography variant="body2">
              {value}
            </Typography>
          </StyledTableCell>
        ),
      },
    },
    {
      name: "prenom",
      label: "PRÉNOM",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => (
          <StyledTableCell>
            <Typography variant="body2">
              <RouterLink to={`/candidats/${tableMeta.rowData[0]}/view`} onClick={(e) => {
                e.preventDefault()
                viewCandidat(tableMeta.rowData[0], 0)
              }}>
                {value}
              </RouterLink>
            </Typography>
          </StyledTableCell>
        ),
      },
    },
    {
      name: "nom",
      label: "NOM",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => (
          <StyledTableCell>
            <Typography variant="body2">
              <RouterLink to={`/candidats/${tableMeta.rowData[0]}/view`} onClick={(e) => {
                e.preventDefault()
                viewCandidat(tableMeta.rowData[0], 0)
              }}>
                {value}
              </RouterLink>
            </Typography>
          </StyledTableCell>
        ),
      },
    },
    {
      name: "fonction",
      label: "FONCTION",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => (
          <StyledTableCell>
            <Typography variant="body2">
              {value || "-"}
            </Typography>
          </StyledTableCell>
        ),
      },
    },
    {
      name: "linkedin",
      label: "LINKEDIN",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => (
          <StyledTableCell>
            <Typography variant="body2">
              <a href={value} target="_blank" rel="noreferrer">
                <RemoveRedEyeIcon />
              </a>
            </Typography>
          </StyledTableCell>
        ),
      },
    },
    {
      name: "seniorite",
      label: "SÉNIORITÉ",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => (
          <StyledTableCell>
            <Typography variant="body2">
              {value}
            </Typography>
          </StyledTableCell>
        ),
      },
    },
    {
      name: "actions",
      label: "ACTIONS",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => (
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Tooltip title="Voir">
              <ActionButton
                component={RouterLink}
                to={`/candidats/${tableMeta.rowData[0]}/view`}
                size="small"
              >
                <RemoveRedEyeIcon />
              </ActionButton>
            </Tooltip>
            <Tooltip title="Voir resumé">
              <ActionButton
                onClick={() => dispatch(displaySwipeableDrawerSummaryCandidat({ show: true, id: tableMeta.rowData[0] }))}
                size="small"
              >
                <RemoveRedEyeIcon />
              </ActionButton>
            </Tooltip>
          </Box>
        ),
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    responsive: 'standard',
    selectableRows: 'none',
    download: true,
    print: true,
    viewColumns: true,
    filter: true,
    rowsPerPage: 50,
    rowsPerPageOptions: [50, 100, 200],
    tableBodyHeight: `${viewportHeight - 300}px`,
    textLabels: {
      body: {
        noMatch: loading ? (
          <CircularProgress />
        ) : (
          "Aucun candidat trouvé"
        ),
      },
    },
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <StyledCard>
          <CardContent>
            <MUIDataTable
              title="Candidats"
              data={candidatsSociete}
              columns={columns}
              options={options}
            />
          </CardContent>
        </StyledCard>
      </Grid>
    </Grid>
  );
};

export default withSnackbar(CandidatsSociete);
