import api from "../../utils/API";

const getAll = (page, uuid) => {
  return api.get(`/candidatsmanagement/candidat/${uuid}/notescandidat/list?page=${page}&limit=${100}`);
};
const deleteNoteCandidat = (uuid) => {
  return api.delete(`/candidatsmanagement/notecandidat/${uuid}/delete`);
};

const addNoteCandidat = (data) => {
  return api.post(`/candidatsmanagement/notecandidat/add`, data, {headers: { 'Content-Type': 'multipart/form-data'}});
};

const editNoteCandidat = (data, uuid) => {
  return api.post(`/candidatsmanagement/notecandidat/${uuid}/edit`, data);
};

const downloadNoteFile = (noteId, fileName) => {
  return api.get(`/candidatsmanagement/notescandidat/${noteId}/file/${fileName}/download`,{
  responseType: "blob",
});
};

export {getAll, editNoteCandidat, deleteNoteCandidat, addNoteCandidat, downloadNoteFile};
