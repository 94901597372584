import { Add } from '@mui/icons-material';
import {
    Box, Button,
    Chip,
    CircularProgress,
    FormControl,
    IconButton,
    ListItemText,
    MenuItem,
    Select,
} from '@mui/material';
import { Card, Checkbox } from '@mui/material';
import { fr } from "date-fns/locale";
import { DateRange } from "react-date-range";
import React, { useState } from "react";
import {arrayColumn, getUserData, truncatedText} from "../../../../utils/FunctionHelpers";
import { withSnackbar } from "../../../../utils/SnackbarHOC";
import { v4 } from "uuid";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  AddFilterKPIConsultants,
  AddSelectedConsultantsFilterKPIConsultants,
} from "../../../../redux/pilotage/actions/filterKPIConsultantsActions";
import {
  AddFilterKPIMissions,
  AddSelectedMissionsFilterKPIMissions,
} from "../../../../redux/pilotage/actions/filterKPIMissionsActions";
import fileDownload from "js-file-download";
import {exportPilotageCommercial} from "../../../Services/PilotageService";
function FilterPilote({ consultants, indexTab, missionData, snackbarShowMessage }) {
  const { filters } = useSelector((state) => state.filtersKPIConsultants);
  const filtersMissions = useSelector(
    (state) => state.filtersKPIMissions.filters
  );
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [openKPIClientSelect, setOpenKPIClientSelect] = useState(false);
  const [openKPIConsultantSelect, setOpenKPIConsultantSelect] = useState(false);
  const [openKPITmsSelect, setOpenKPITmsSelect] = useState(false);
  const [panel, setPanel] = useState(indexTab);
  const [plagueDate, setPlagueDate] = useState([
    {
      startDate: filters?.startDate
        ? new Date(filters.startDate)
        : new Date(new Date().setDate(new Date().getDate() - 7)),
      endDate: filters?.endDate
        ? new Date(filters.endDate)
        : new Date(new Date().setDate(new Date().getDate() - 1)),
      key: "selection",
    },
  ]);

  React.useEffect(() => {
    setPanel(indexTab);
  }, [indexTab]);

    React.useEffect(() => {
        if (indexTab === 0) {
            dispatch(
                AddFilterKPIConsultants({
                    selectedConsultants: [],
                    startDate: plagueDate[0].startDate.getTime(),
                    endDate: plagueDate[0].endDate.getTime(),
                })
            );
        } else if (indexTab === 2) {
            dispatch(
                AddFilterKPIMissions({
                    selectedMissions: [],
                    startDate: plagueDate[0].startDate.getTime(),
                    endDate: plagueDate[0].endDate.getTime(),
                })
            );
        }
    }, [indexTab, dispatch, plagueDate]);


  const handleFilterConsultants = (value) => {
    if (value[value.length - 1] === "all") {
      dispatch(
        AddSelectedConsultantsFilterKPIConsultants(
          filters.selectedConsultants.length === consultants.length
            ? []
            : arrayColumn(consultants, "uuid")
        )
      );
      return;
    }
    dispatch(AddSelectedConsultantsFilterKPIConsultants(value));
  };

  const handleFilterMissions = (value) => {
    if (value[value.length - 1] === "all") {
      dispatch(
        AddSelectedMissionsFilterKPIMissions(
          filtersMissions.selectedMissions.length === missionData.length
            ? []
            : arrayColumn(missionData, "uuid")
        )
      );
      return;
    }
    dispatch(AddSelectedMissionsFilterKPIMissions(value));
  };

    const handleExportToExcel = async () => {
        try {
            const startDate = plagueDate[0]?.startDate.toISOString().split("T")[0];
            const endDate = plagueDate[0]?.endDate.toISOString().split("T")[0];
            setLoading(true);
            snackbarShowMessage("Exportation en cours....", "warning", 2000)
            const response = await exportPilotageCommercial(startDate, endDate)
            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `Pilotage-Commercial-${startDate}-au-${endDate}`;
            link.click();
            snackbarShowMessage("Exportation avec success", "success", 3000)
        } catch (e) {
            snackbarShowMessage("Erreur d'exportation du pilotage commercial", "error", 2000)
        } finally {
            setLoading(false)
        }
    };

    const handleClose = () => {
    setOpenKPIClientSelect(false);
  };

  return (
    <Box>
      <Card
        style={{
          padding: "20px",
          display: "flex",
          width: "400px",
          flexDirection: "column",
          height: "99%",
        }}
      >
        <Box
          sx={{
            marginBottom: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <strong>SELECTIONNER LA PÉRIODE</strong>
        </Box>
        <Box>
          <DateRange
            onChange={(item) => {
              setPlagueDate([item.selection]);
              dispatch(
                AddFilterKPIConsultants({
                  selectedConsultants: filters.selectedConsultants,
                  startDate: item.selection.startDate.getTime(),
                  endDate: item.selection.endDate.getTime(),
                })
              );
            }}
            moveRangeOnFirstSelection={false}
            ranges={plagueDate}
            locale={fr}
          />
        </Box>
        <Box
          sx={{
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            display: !(panel === 0) ? "none" : "flex",
          }}
        >
          <Box
            sx={{
              marginBottom: "10px",
            }}
          >
            <strong>SELECTIONNER LE(S) GESTIONNAIRE(S) DE COMPTES</strong>
          </Box>
          <Box
            sx={{
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              display: consultants.length > 1 ? "none" : "flex",
            }}
          >
            <CircularProgress />
          </Box>
          <Box
            sx={{
              justifyContent: "center",
              alignItems: "center",
              padding: "10px",
              gap: "10px",
              flexDirection: "column",
              display: "flex",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px",
                flexWrap: "wrap",
                gap: "10px",
                maxHeight: "300px",
                overflowY: "auto",
                scrollBehavior: "smooth",
              }}
            >
              {filters.selectedConsultants.map((item, index) => {
                const consultant = consultants.find((c) => c.uuid === item);
                return (
                    <span key={v4()} style={{cursor: "pointer"}}>
                    <Chip
                      label={
                        consultant ? consultant?.user : "Sans nom"
                      }
                      data-toggle="tooltip"
                      title={consultant?.user}
                      variant="default"
                      onDelete={() => {
                        const response = filters.selectedConsultants.filter(
                          (id) => id !== consultant.uuid
                        );
                        dispatch(
                          AddFilterKPIConsultants({
                            selectedConsultants: response,
                            startDate: plagueDate[0].startDate.getTime(),
                            endDate: plagueDate[0].endDate.getTime(),
                          })
                        );
                        return response;
                      }}
                    />
                  </span>
                );
              })}
            </Box>

            {openKPITmsSelect && (
              <FormControl
                variant="outlined"
                margin="normal"
                style={{ visibility: "hidden", width: " 200px" }}
              >
                <Select
                  open={openKPITmsSelect}
                  multiple
                  onClose={() => setOpenKPITmsSelect(false)}
                  name="selectedConsultants"
                  value={filters.selectedConsultants}
                  onChange={(e) => handleFilterConsultants(e.target.value)}
                >
                  <MenuItem value={"all"}>
                    <Checkbox
                      checked={
                        consultants.length > 0 &&
                        filters.selectedConsultants.length ===
                          consultants.length
                      }
                      indeterminate={
                        filters.selectedConsultants.length > 0 &&
                        filters.selectedConsultants.length < consultants.length
                      }
                    />
                    <ListItemText primary={"TOUS"} />
                  </MenuItem>
                  {consultants.map((consultant) => (
                    <MenuItem value={consultant.uuid} key={consultant.uuid}>
                      <Checkbox
                        checked={
                          filters.selectedConsultants.indexOf(consultant.uuid) >
                          -1
                        }
                      />
                      <ListItemText primary={consultant.user} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {filters.selectedConsultants.length !== consultants.length && (
              <IconButton
                aria-label="Ajouter Un Talent Manager"
                data-toggle="tooltip"
                title="Ajouter Un Talent Manager"
                onClick={() =>
                    setOpenKPITmsSelect(!openKPITmsSelect)
                }
              >
                <Add />
              </IconButton>
            )}
              <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleExportToExcel()}
                  disabled={loading}
              >
                  Exportation Excel
              </Button>
          </Box>
        </Box>
          {openKPIConsultantSelect && (
              <FormControl
                  variant="outlined"
                  margin="normal"
                  style={{ visibility: "hidden", width: " 200px" }}
              >
                  <Select
                      open={openKPIConsultantSelect}
                      multiple
                      onClose={() => setOpenKPIConsultantSelect(false)}
                      name="selectedConsultants"
                      value={filters.selectedConsultants}
                      onChange={(e) => handleFilterConsultants(e.target.value)}
                  >
                      <MenuItem value={"all"}>
                          <Checkbox
                              checked={
                                  consultants.length > 0 &&
                                  filters.selectedConsultants.length ===
                                  consultants.length
                              }
                              indeterminate={
                                  filters.selectedConsultants.length > 0 &&
                                  filters.selectedConsultants.length < consultants.length
                              }
                          />
                          <ListItemText primary={"TOUS"} />
                      </MenuItem>
                      {consultants.map((consultant) => (
                          <MenuItem value={consultant.uuid} key={consultant.uuid}>
                              <Checkbox
                                  checked={
                                      filters.selectedConsultants.indexOf(consultant.uuid) >
                                      -1
                                  }
                              />
                              <ListItemText primary={consultant.user} />
                          </MenuItem>
                      ))}
                  </Select>
              </FormControl>
          )}
          <Box
          sx={{
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            display: !(panel === 1) ? "none" : "flex",
          }}
        >
          <Box
            sx={{
              marginBottom: "10px",
            }}
          >
            <strong>SELECTIONNER LE(S) CONSULTANT(S)</strong>
          </Box>
            <Box
                sx={{
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
              display: consultants.length > 1 ? "none" : "flex",
            }}
          >
            <CircularProgress />
          </Box>
          <Box
            sx={{
              justifyContent: "center",
              alignItems: "center",
              padding: "10px",
              gap: "10px",
              flexDirection: "column",
              display: "flex",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px",
                flexWrap: "wrap",
                gap: "10px",
                maxHeight: "300px",
                overflowY: "auto",
                scrollBehavior: "smooth",
              }}
            >
              {filters.selectedConsultants.map((item, index) => {
                const consultant = consultants?.find((c) => c.uuid === item);
                return (
                  <span key={v4()} style={{ cursor: "pointer" }}>
                    <Chip
                      label={
                        consultant ? consultant?.user : "Sans nom"
                      }
                      data-toggle="tooltip"
                      title={consultant?.user}
                      variant="default"
                      onDelete={() => {
                        const response = filters.selectedConsultants.filter(
                          (id) => id !== consultant.uuid
                        );
                        dispatch(
                          AddFilterKPIConsultants({
                            selectedConsultants: response,
                            startDate: plagueDate[0].startDate.getTime(),
                            endDate: plagueDate[0].endDate.getTime(),
                          })
                        );
                        return response;
                      }}
                    />
                  </span>
                );
              })}
            </Box>
            {filters.selectedConsultants.length !== consultants.length && (
              <IconButton
                aria-label="Ajouter Consultant"
                data-toggle="tooltip"
                title="Ajouter Consultant"
                onClick={() =>
                  setOpenKPIConsultantSelect(!openKPIConsultantSelect)
                }
              >
                <Add />
              </IconButton>
            )}
          </Box>
        </Box>

        <Box
          sx={{
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            display: !(panel === 2) ? "none" : "flex",
          }}
        >
          <Box
            sx={{
              marginBottom: "10px",
            }}
          >
            <strong>SELECTIONNER LE(S) MISSION(S) - CLIENTS</strong>
          </Box>
          <Box
            sx={{
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              display: missionData.length > 1 ? "none" : "flex",
            }}
          >
            <CircularProgress />
          </Box>
          <Box
            sx={{
              justifyContent: "center",
              alignItems: "center",
              padding: "10px",
              gap: "10px",
              flexDirection: "column",
              display: "flex",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "10px",
                flexWrap: "wrap",
                gap: "10px",
                maxHeight: "300px",
                overflowY: "auto",
                scrollBehavior: "smooth",
              }}
            >
              {filtersMissions.selectedMissions?.map((item, index) => {
                const mission = missionData.find((c) => c.uuid === item);
                return (
                  <span key={v4()} style={{ cursor: "pointer" }}>
                    <Chip
                      label={
                        mission
                          ? truncatedText(mission.nom_mission, 40)
                          : "Sans nom"
                      }
                      variant="default"
                      data-toggle="tooltip"
                      title={mission.nom_mission + " - " + mission.client}
                      onDelete={() => {
                        const response =
                          filtersMissions.selectedMissions.filter(
                            (id) => id !== mission.uuid
                          );
                        dispatch(
                          AddFilterKPIMissions({
                            selectedMissions: response,
                            startDate: plagueDate[0].startDate.getTime(),
                            endDate: plagueDate[0].endDate.getTime(),
                          })
                        );
                        return response;
                      }}
                    />
                  </span>
                );
              })}
            </Box>

            {openKPIClientSelect && (
              <FormControl
                variant="outlined"
                margin="normal"
                style={{ visibility: "hidden", width: " 200px" }}
              >
                <Select
                  open={openKPIClientSelect}
                  multiple
                  onClose={() => handleClose()}
                  name="selectedMissions"
                  value={filtersMissions.selectedMissions}
                  onChange={(e) => handleFilterMissions(e.target.value)}
                >
                  <MenuItem value={"all"}>
                    <Checkbox
                      checked={
                        missionData.length > 0 &&
                        filtersMissions.selectedMissions.length ===
                          missionData.length
                      }
                      indeterminate={
                        filtersMissions.selectedMissions.length > 0 &&
                        filtersMissions.selectedMissions.length <
                          missionData.length
                      }
                    />
                    <ListItemText primary={"TOUS"} />
                  </MenuItem>
                  {missionData?.map((mission) => (
                    <MenuItem value={mission.uuid} key={mission.uuid}>
                      <Checkbox
                        checked={
                          filtersMissions.selectedMissions.indexOf(
                            mission.uuid
                          ) > -1
                        }
                      />
                      <ListItemText
                        primary={mission.nom_mission + " - " + mission.client}
                      />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {filtersMissions.selectedMissions.length !== missionData.length && (
              <IconButton
                aria-label="Ajouter Consultant"
                data-toggle="tooltip"
                title="Ajouter Consultant"
                onClick={() => setOpenKPIClientSelect(!openKPIClientSelect)}
              >
                <Add />
              </IconButton>
            )}
          </Box>
        </Box>
      </Card>
    </Box>
  );
}

export default withSnackbar(FilterPilote);
