import React, { useState } from "react";
import { detailsSms } from "../../Services/EmailTypeService";
import "../../../components/EmailModal/Modal.css";
import { getConsultantsAndManagers } from "../../Services/UserService";
import { getUserData } from "../../../utils/FunctionHelpers";
import {
  CircularProgress,
  Button,
  TextField,
  Fade,
  Backdrop,
  Modal,
} from '@mui/material';
import { Autocomplete } from '@mui/lab';
import { BiMessage } from "react-icons/bi";
import { FiSend } from "react-icons/fi";
import { withSnackbar } from "../../../utils/SnackbarHOC";
import { styled } from '@mui/material/styles';
import { sendSmsToCandidat } from "../../Services/CandidatService";

const useStyles = styled((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const SmsCandidat = ({ candidatTel, candidatFullName, candidatFirstName, snackbarShowMessage, candidatId, ...props }) => {
  const classes = useStyles();
  const [errors, setErrors] = useState({});
  const [options, setOptions] = useState([]);
  const [optionsUser, setOptionsUser] = useState([]);
  const [open, setOpen] = useState(false);
  const [openUser, setOpenUser] = useState(false);
  const [de, setDe] = useState(getUserData().uuid);
  const [Messages, setMessages] = useState("");
  const [message, Setmessage] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [messageCounter, setMessageCounter] = useState(160 - message.length);
  const [userName, setUserName] = useState(`${getUserData()?.lastname} ${getUserData()?.firstname}`);

  //Input validation
  const validate = () => {
    let temp = {};
    temp.de = de ? "" : "Le champ 'De' est obligatoire.";
    temp.message = message ? "" : "Le champ 'Message' est obligatoire.";
    setErrors({
      ...temp,
    });
    return Object.values(temp).every((x) => x === "");
  };

  React.useEffect(() => {
    if (openModal) {
      setLoading(true);
      (async () => {
        try {
          const emailTypes = await detailsSms(1);
          const activeUsers = await getConsultantsAndManagers();
          setOptionsUser(activeUsers.data.managers);
          setOptions(emailTypes.data.emailType);
        } catch (err) {
          snackbarShowMessage("Erreur de chargement des données", "error");
        } finally {
          setLoading(false);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  const handleChangeMessageType = (value) => {
    const replacements = [
      ["[Prenom_CDT]", candidatFirstName],
      ["Prenom_CDT", candidatFirstName],
      ["[Nom_Account_Manager]", userName],
      ["[Prenom_Nom_CDT]", candidatFullName],
      ["[Prenom_Consultant]", userName.split(" ")[0]],
    ];

    const msgTxt = value || "-";
    const updatedMsgTxt = replacements.reduce((txt, [search, replacement]) => {
      const formattedReplacement = replacement.charAt(0).toUpperCase() + replacement.slice(1);
      return txt.replace(search, formattedReplacement);
    }, msgTxt);

    handleMessage(updatedMsgTxt);
  }

  const handleSend = async () => {
    if (validate()) {
      try {
        setLoading(true)
        const payload = {
          candidatId,
          consultantId: de,
          content: message
        }
        await sendSmsToCandidat(payload);
        snackbarShowMessage(`SMS a été envoyé à ${candidatFullName} avec succès !`);
        props.setLoadData(!props.loadData);
        handleCloseModal();
      } catch {
        snackbarShowMessage("Erreur d'envoi du SMS", "error");
      } finally {
        setLoading(false);
      }
    }
  };

  const handleCloseModal = () => {
    if (!loading) {
      setOpenModal(false);
    }
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleMessage = (value) => {
    if (value.length > 160) {
      Setmessage(value.slice(0, 160))
      setMessageCounter(160 - value.slice(0, 160).length)
    } else {
      Setmessage(value)
      setMessageCounter(160 - value.length)
    }
  }

  const body = (
    <>
      <div className="modalBackground">
        <div className="modalContainer ">
          <div>
            <div>
              <label className="input-label" htmlFor="A">
                À : {candidatFullName} {"<" + candidatTel + ">"}
              </label>
            </div>
            <div>
              <label className="input-label" htmlFor="messages">
                De <span className="required-input">*</span>
              </label>
              <Autocomplete
                open={openUser}
                onOpen={() => setOpenUser(true)}
                onClose={() => setOpenUser(false)}
                getOptionSelected={(option, value) =>
                  option.uuid === value.uuid
                }
                getOptionLabel={(option) => option.user}
                onChange={(event, values) => {
                  setDe(values.uuid);
                  setUserName(values.user);
                  handleChangeMessageType(Messages.message)
                }}
                options={optionsUser}
                loading={loading}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    placeholder={`${getUserData()?.lastname} ${getUserData()?.firstname}`}
                    {...(errors.users && {
                      error: true,
                      label: errors.users,
                    })}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </div>
            <div>
              <label className="input-label" htmlFor="messages">
                Message Type
              </label>
              <Autocomplete
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                getOptionSelected={(option, value) =>
                  option.msgName === value.msgName
                }
                getOptionLabel={(option) => option.msgName}
                onChange={(event, values) => {
                  setMessages(values);
                  handleChangeMessageType(values.message)
                }}
                options={options}
                loading={loading}
                disableClearable
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={Messages ? Messages.msgName : ""}
                    placeholder={Messages ? Messages.msgName : ""}
                    {...(errors.Messages && {
                      error: true,
                      label: errors.Messages,
                    })}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </div>
            <div>
              <label className="input-label" htmlFor="message">
                Message <span className="required-input"> *</span>
              </label>
              <TextField
                multiline
                minRows={6}
                id="message"
                value={message}
                onChange={(e) => handleMessage(e.target.value)}
                placeholder="message..."
                margin="normal"
                variant="outlined"
                autoComplete="off"
                inputProps={{ maxLength: 160 }}
                fullWidth
                {...(errors.message && {
                  error: true,
                  label: errors.message,
                })}
              />
              {messageCounter}
            </div>
          </div>
          <div className="footer" style={{ float: "right", padding: "20px 0px 0px 0px" }}>
            <Button
              style={{
                color: "#1b2a4e",
                backgroundColor: "#d9e2ef",
                borderColor: "#d9e2ef",
                marginRight: "0.5em",
                fontWeight: "500",
              }}
              onClick={handleCloseModal}
              disabled={loading}
            >
              Annuler
            </Button>
            <Button
              startIcon={<FiSend />}
              style={{
                backgroundColor: "rgb(66, 186, 150)",
                color: "#FFF",
                fontWeight: 500,
                "&:hover, &:focus": {
                  backgroundColor: "#389e7f",
                  borderColor: "#359478",
                },
              }}
              disabled={loading}
              onClick={handleSend}
            >
              Envoyer
            </Button>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <>
      <Button
        startIcon={<BiMessage color="117aa7" />}
        data-toggle="tooltip"
        title="SMS"
        onClick={handleOpenModal}
      />
      <Modal
        className={classes.modal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        open={openModal}
        onClose={handleCloseModal}
      >
        <Fade in={openModal}>{body}</Fade>
      </Modal>
    </>
  );
};

export default withSnackbar(SmsCandidat);
