import { CircularProgress, styled, Box } from '@mui/material';
import { Card } from "react-bootstrap";
import LabelReadMoreReadLess from "../LabelReadMoreReadLess/LabelReadMoreReadLess";

const useStyles = styled({
  title: {
    color: "rgb(17, 122, 167)",
  },
});

export default function DescriptionSociete({ loading, description }) {
  const classes = useStyles();
  return (
    <Card style={{ padding: 30, width: "100%", maxHeight: "44vh" }}>
      <h5 className={classes.title}>Description de la société: </h5>
      {loading ? (
        <Box
          display="flex"
          height={150}
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <LabelReadMoreReadLess
          data={description !== null ? description : "(vide)"}
        />
      )}
    </Card>
  );
}
