import React from "react";
import { styled } from '@mui/material/styles';
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterIcon from "@mui/icons-material/FilterList";
import UploadFileIcon from "@mui/icons-material/UploadFile";

const StyledDiv = styled('div')(({ theme }) => ({
  '& .iconButton': {
    marginRight: '24px',
    top: '50%',
    display: 'inline-block',
    position: 'relative',
    transform: 'translateY(-50%)'
  }
}));

const CustomToolbarSelect = props => {
  return (
    <StyledDiv className="custom-toolbar-select">
      <Tooltip title={"Delete"}>
        <IconButton className="iconButton" onClick={props.onDelete}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={"Filter Table"}>
        <IconButton className="iconButton" onClick={props.onFilter}>
          <FilterIcon />
        </IconButton>
      </Tooltip>
    </StyledDiv>
  );
};

export default CustomToolbarSelect;
