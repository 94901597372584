import Modal from '@mui/material/Modal';
import React, { useState } from "react";
import { Backdrop, Button, CircularProgress, Fade, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { AiOutlinePlus } from "react-icons/ai";
import { addEcole } from "../../../Services/EcoleService";
import { withSnackbar } from "../../../../utils/SnackbarHOC";

function getModalStyle() {
  const top = 0;
  const left = 0;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = styled((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "20px",
  },
  saveButton: {
    backgroundColor: "rgb(66, 186, 150)",
    color: "#FFF",
    fontWeight: 500,
    "&:hover, &:focus": {
      backgroundColor: "#389e7f",
      borderColor: "#359478",
    },
  },
  plus: {
    fontWeight: 500,
    marginBottom: "0.2em",
  },
  buttonLabel: {
    paddingLeft: "0.4em",
    marginLeft: 0,
  },
  closeButton: {
    color: "#1b2a4e",
    backgroundColor: "#d9e2ef",
    borderColor: "#d9e2ef",
    marginLeft: "0.5em",
    fontWeight: "500",
  },
  modalTitle: {
    color: "rgb(17 122 167)",
  },
  buttonProgress: {
    color: "white",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

function EmployeurModal({ errors, setErrors, snackbarShowMessage, setReloadData,
  reloadData }) {
  const classes = useStyles();
  const [modalState, setModalState] = useState({ open: false });
  const [newEcole, setNewEcole] = useState("");
  const [modalStyle] = useState(getModalStyle);
  const [loading, setLoading] = useState(false);

  const validate = () => {
    let temp = {};
    temp.newEcole = newEcole ? "" : "Le champ 'NOM DE DIPLÔME PRINCIPAL est obligatoire.";
    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const handleOpen = () => {
    setModalState({ open: true });
  };

  const handleClose = () => {
    setModalState({ open: false });
  };

  const handleSubmit = () => {
    if (validate()) {
      setLoading(true);
      addEcole({ name: newEcole }).then((response) => {
        snackbarShowMessage("L'ecole a été ajoutée avec succès.", "success");
        setLoading(false);
        handleClose();
        setReloadData(!reloadData)
      }).catch((error) => {
        snackbarShowMessage("Erreur d'ajout de l'ecole.", "error", 4000);
        setLoading(false);
      })
    }
  };

  React.useEffect(() => {
    return () => {
      setNewEcole("");
    };
  }, []);

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <div className="modal-header">
        <h4 className={classes.modalTitle}>Ajouter un diplôme principal </h4>
      </div>
      <div className="modal-body">
        <label className="input-label" htmlFor="nomEcole">
          NOM DE DIPLÔME PRINCIPAL  <span className="required-input">*</span>
        </label>
        <TextField
          id="nomEcole"
          value={newEcole}
          onChange={(e) => setNewEcole(e.target.value)}
          placeholder="Nom de le Diplôme(s)."
          margin="normal"
          variant="outlined"
          autoComplete="off"
          fullWidth
          {...(errors.newEcole && {
            error: true,
            label: errors.newEcole,
          })}
        />
      </div>
      <div className="modal-footer">
        <Button className={classes.saveButton} onClick={handleSubmit} disabled={loading}>
          Sauvegarder
          {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </Button>
        <Button className={classes.closeButton} onClick={handleClose} disabled={loading}>
          Annuler
        </Button>
      </div>
    </div>
  );

  return (
    <>
      <button className="btn btn-sm btn-link float-right" style={{ textDecoration: "none" }} onClick={handleOpen}>
        <AiOutlinePlus className={classes.plus} />
        Ajouter
      </button>
      <Modal
        className={classes.modal}
        open={modalState.open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalState.open}>{body}</Fade>
      </Modal>
    </>
  );
}

export default withSnackbar(EmployeurModal)