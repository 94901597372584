import { List, ListItem, ListItemText, Modal, IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Visibility } from '@mui/icons-material';
import React, { useState } from "react";
import { MdCancel } from "react-icons/md";
import '../Dashboard/dashboardStyle.css'
import LabelHtmlToTextCustom from "../../components/LabelHtmlToTextCustom/LabelHtmlToTextCustom";


function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    paddingTop: "-2rem",
    marginTop: "30px",
    height: "62%",
    marginBottom: "100px",
    width: "613px",

  },
  target: {
    fontWeight: 500,
    marginBottom: "0.2em",
  },
  buttonLabel: {
    paddingLeft: "0.4em",
    marginLeft: 0,
  },
  closeButton: {
    float: "right",
    color: "#2284ad",
    fontSize: "30px",
    marginRight: "4%",
    marginTop: "1%",
    "&:hover": {
      animation: "mymove 1s",
      color: "#ad2222",
    }
  },

  modalTitle: {
    color: "white",
    paddingTop: "2%",
    paddingLeft: "1rem",
    fontFamily: 'myFirstFont',
    fontSize: "33px",
    fontWeight: "600",
    background: " linear-gradient(45deg, #2284ad, #2284ad,#2284ad,white,white,white,white,transparent)",
    paddingBottom: "2%"
  },

}));

const EmailView = ({ activite, msgName, objet, message, TypeMsg }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [modalStyle] = useState(getModalStyle);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <h4 className={classes.modalTitle}>
        Type Email
        < MdCancel className={classes.closeButton} onClick={handleClose} />
      </h4>
      <div className="modal-content" style={{ overflow: "auto", height: "78%", border: "none", alignItems: "normal", }}>
        <List className={classes.listContenu}>
          <ListItem>
            <ListItemText primary="TYPE DE MESSAGE" secondary={TypeMsg} style={{ textAlign: "justify", textTransform: "uppercase" }} />
          </ListItem>
          <ListItem>
            <ListItemText primary="ACTIVITE" secondary={activite} />
          </ListItem>
          <ListItem>
            <ListItemText primary="INTITULE DU MESSAGE" secondary={msgName} />
          </ListItem>
          <ListItem>
            <ListItemText primary="OBJET DU MAIL " secondary={objet} />
          </ListItem>
          <ListItem>
            <ListItemText primary="CONTENU DU MESSAGE" secondary={<LabelHtmlToTextCustom data={message} />} style={{ textAlign: "justify" }} />
          </ListItem>
        </List>
      </div>
    </div>
  );

  return (
    <>
      <IconButton data-toggle="tooltip" title="Visualiser" style={{ color: "#3E60F2" }} onClick={handleOpen}>
        <Visibility className={classes.target} />
      </IconButton>
      <Modal open={open} onClose={handleClose}>
        {body}
      </Modal>
    </>
  );
};

export default EmailView;
