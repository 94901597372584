import React from 'react';
import {
  DialogContentText,
  Button,
  Box,
  CircularProgress
} from '@mui/material';
import Modal from '../Modal/Modal';

const DialogDeleteCustom = ({
  open,
  handleClose,
  handleConfirm,
  title = "Confirmation de suppression",
  label,
  loading = false,
  confirmLabel
}) => {
  const actions = (
    <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
      <Button
        onClick={handleClose}
        disabled={loading}
        variant="outlined"
        color="inherit"
        sx={{
          '&:hover': {
            backgroundColor: 'action.hover',
          },
        }}
      >
        Annuler
      </Button>
      <Button
        onClick={handleConfirm}
        color="error"
        variant="contained"
        disabled={loading}
        startIcon={loading && <CircularProgress size={20} color="inherit" />}
      >
        {loading ? 'Suppression...' : (confirmLabel || 'Supprimer')}
      </Button>
    </Box>
  );

  return (
    <Modal
      open={open}
      onClose={!loading ? handleClose : undefined}
      title={title}
      actions={actions}
      maxWidth="sm"
      closeOnEscape={!loading}
      closeOnBackdropClick={!loading}
    >
      <DialogContentText sx={{ py: 2 }}>
        {label}
      </DialogContentText>
    </Modal>
  );
};

export default DialogDeleteCustom;
