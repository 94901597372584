import {
  Box,
  Collapse,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from "react";
import { AiOutlineHome } from "react-icons/ai";
import { BiWrench } from "react-icons/bi";
import { FaTasks, FaUsers } from "react-icons/fa";
import { FiArchive, FiChevronsLeft, FiChevronsRight } from "react-icons/fi";
import { GiOnTarget } from "react-icons/gi";
import { ImCompass2, ImProfile, ImStatsDots } from "react-icons/im";
import { RiMailSettingsLine } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { v4 } from "uuid";
import LoadingSpinner from '../../components/LoadingSpinner';
import { ROLE } from "../../enums/rolesEnum";
import CandidatImport from "../../pages/Import/Candidat/CandidatImport";
import { setToggleSidebar } from "../../redux/toggle/actions/toggleActions";
import { getUserData } from "../../utils/FunctionHelpers";

const NavWrapper = styled(Box)`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: ${props => props.iscollapsed === 'true' ? '85px' : '280px'};
  background-color: ${props => props.theme.palette.background.paper};
  transition: ${props => props.theme.transitions.create(['width', 'margin'], {
  easing: props.theme.transitions.easing.sharp,
  duration: props.theme.transitions.duration.enteringScreen,
})};
  overflow: hidden;
  border-right: 1px solid ${props => props.theme.palette.divider};
  z-index: 1000;
  ${props => props.theme.breakpoints.down('sm')} {
    display: none;
  }
`;

const NavContent = styled(Box)(({ theme }) => ({
  paddingTop: '80px', // Hauteur du header
}));

const StyledNavLink = styled(NavLink)(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.text.primary,
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1, 1),
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    borderRadius: theme.shape.borderRadius,
  },
  '&.active': {
    backgroundColor: theme.palette.primary.light,
    '& .MuiListItemIcon-root, & .MuiTypography-root': {
      color: '#fff'
    }
  }
}));



const VerticalNavWrapper = ({ sideBarCollapsed = false, handleClose = () => { } }) => {
  const [todos, setTodos] = useState(sideBarCollapsed);
  const dispatch = useDispatch();
  const [authData, setAuthData] = useState({
    isLoading: true,
    userData: null
  });
  const notAllowedRoles = [ROLE.ADMIN, ROLE.COMMERCIAL];

  const [outilsOpen, setOutilsOpen] = useState(false);
  const [showCandidatImport, setShowCandidatImport] = useState(false);

  useEffect(() => {
    const userData = getUserData();
    if (userData && userData.role) {
      setAuthData({
        isLoading: false,
        userData: userData
      });
    } else {
      window.location.replace('/login');
    }
  }, []);



  if (authData.isLoading) {
    return <LoadingSpinner />;
  }

  const showOutilsSection = () => {
    return authData.userData.role.some(role => role === ROLE.ADMIN || role === ROLE.DIRECTION || role === ROLE.MANAGER) ? (
      <>
        <ListItemButton
          onClick={() => setOutilsOpen(!outilsOpen)}
          sx={{
            borderRadius: 1,
            mb: 0.5,
            p: 1.1,
            '&:hover': {
              bgcolor: 'action.hover'
            },
            '&.active': {
              bgcolor: 'primary.light',
              '& .MuiListItemIcon-root, & .MuiTypography-root': {
                color: '#fff'
              }
            }
          }}
        >
          <ListItemIcon sx={{ minWidth: 40, color: 'text.secondary' }}>
            <BiWrench fontSize={32} data-toggle='tooltip' title="OUTILS" />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography
                variant="body2"
                sx={{
                  fontWeight: 'medium',
                  transition: theme => theme.transitions.create('opacity'),
                  display: todos ? 'none' : ''
                }}
              >
                OUTILS
              </Typography>
            }
          />
        </ListItemButton>
        <Collapse in={outilsOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <StyledNavLink
              to="/emailtype"
              activeClassName="activated"
              onClick={handleClose}
              sx={ !todos ? { pl: 4 } : { pl: 2 }}
            >
              <ListItemIcon sx={{ minWidth: 40, color: 'text.secondary' }}>
                <RiMailSettingsLine fontSize={18} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: 'medium',
                      transition: theme => theme.transitions.create('opacity'),
                      display: todos ? 'none' : ''
                    }}
                  >
                    Types d'Email
                  </Typography>
                }
              />
            </StyledNavLink>
            <StyledNavLink
              to="/reporting/intern"
              activeClassName="activated"
              onClick={handleClose}    
              sx={ !todos ? { pl: 4 } : { pl: 2 }}
            >
              <ListItemIcon sx={{ minWidth: 40, color: 'text.secondary' }}>
                <ImStatsDots fontSize={18} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: 'medium',
                      transition: theme => theme.transitions.create('opacity'),
                      display: todos ? 'none' : ''
                    }}
                  >
                    Reporting Interne
                  </Typography>
                }
              />
            </StyledNavLink>
            <StyledNavLink
              to="/reportingClient"
              activeClassName="activated"
              onClick={handleClose}
              sx={ !todos ? { pl: 4 } : { pl: 2 }}
            >
              <ListItemIcon sx={{ minWidth: 40, color: 'text.secondary' }}>
                <ImProfile fontSize={18} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: 'medium',
                      transition: theme => theme.transitions.create('opacity'),
                      display: todos ? 'none' : ''
                    }}
                  >
                    Reporting Client
                  </Typography>
                }
              />
            </StyledNavLink>
            <StyledNavLink
              to="/users"
              activeClassName="activated"
              onClick={handleClose}
              sx={ !todos ? { pl: 4 } : { pl: 2 }}
            >
              <ListItemIcon sx={{ minWidth: 40, color: 'text.secondary' }}>
                <FaUsers fontSize={18} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: 'medium',
                      transition: theme => theme.transitions.create('opacity'),
                      display: todos ? 'none' : ''
                    }}
                  >
                    Utilisateurs
                  </Typography>
                }
              />
            </StyledNavLink>
            <StyledNavLink
              to="/pilotage"
              activeClassName="activated"
              onClick={handleClose}
              sx={ !todos ? { pl: 4 } : { pl: 2 }}
            >
              <ListItemIcon sx={{ minWidth: 40, color: 'text.secondary' }}>
                <ImCompass2 fontSize={18} />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: 'medium',
                      transition: theme => theme.transitions.create('opacity'),
                      display: todos ? 'none' : ''
                    }}
                  >
                    Pilotage
                  </Typography>
                }
              />
            </StyledNavLink>
            <CandidatImport open={showCandidatImport} onClose={() => setShowCandidatImport(false)} todos={todos} />
          </List>
        </Collapse>
      </>
    ) : null;
  };

  return (
    <NavWrapper iscollapsed={todos.toString()}>
      <NavContent>
        <List component="ul" sx={{ width: "100%", p: 2 }}>
          {[
            {
              to: "/accueil",
              activeClassName: "activated",
              onClick: () => handleClose(),
              icon: <AiOutlineHome fontSize={32} data-toggle='tooltip' title="ACCUEIL" />,
              label: "ACCUEIL",
              notAllowedRoles
            },
            {
              to: "/tachescandidat",
              activeClassName: "activated",
              onClick: handleClose,
              icon: <FaTasks fontSize={30} data-toggle='tooltip' title="MES TÂCHES" />,
              label: "MES TÂCHES",
              notAllowedRoles
            },
            {
              to: "/selections",
              activeClassName: "activated",
              onClick: handleClose,
              icon: <FiArchive fontSize={30} data-toggle='tooltip' title="MES SÉLECTIONS" />,
              label: "MES SÉLECTIONS",
              notAllowedRoles: []
            },
            {
              to: "/missions",
              activeClassName: "activated",
              onClick: handleClose,
              icon: <GiOnTarget fontSize={30} data-toggle='tooltip' title="MISSIONS" />,
              label: "MISSIONS",
              notAllowedRoles
            },
          ].map((item) => !authData.userData.role.some(role => item.notAllowedRoles.includes(role)) ? (
            <StyledNavLink
              key={v4()}
              to={item.to}
              onClick={item.onClick}
              sx={{
                display: 'flex',
                alignItems: 'center',
                borderRadius: 1,
                mb: 1,
                p: 1,
                '&:hover': {
                  bgcolor: 'action.hover'
                },
                '&.active': {
                  bgcolor: 'rgb(17, 122, 167)',
                  '& .MuiListItemIcon-root, & .MuiTypography-root': {
                    color: '#fff'
                  }
                }
              }}
            >
              <ListItemIcon sx={{ minWidth: 40, color: 'text.secondary' }}>
                {item.icon}
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: 'medium',
                      transition: theme => theme.transitions.create('opacity'),
                      display: todos ? 'none' : ''
                    }}
                  >
                    {item.label}
                  </Typography>
                }
              />
            </StyledNavLink>
          ) : null)}
          {showOutilsSection()}
        </List>
      </NavContent>
      <Box sx={{ p: 2, display: 'flex', justifyContent: 'flex-end' }}>
        <IconButton
          onClick={() => {
            setTodos(!todos);
            dispatch(setToggleSidebar(!todos));
          }}
          title={todos ? "Elargir" : "Rétrécir"}
          sx={{
            color: 'rgb(17, 122, 167)',
            '&:hover': {
              color: 'rgb(0, 112, 161)'
            }
          }}
        >
          {todos ? (
            <FiChevronsRight fontSize="large" />
          ) : (
            <FiChevronsLeft fontSize="large" />
          )}
        </IconButton>
      </Box>
    </NavWrapper>
  );
};

export default VerticalNavWrapper;
