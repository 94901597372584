import React, { useEffect, useRef, useState } from "react";
import {useParams, useLocation, useHistory, Link} from 'react-router-dom';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import {
  Card,
  FormControl,
  MenuItem,
  Select,
  CircularProgress,
  styled,
  OutlinedInput
} from '@mui/material';
import "./Addsociete.css";
import { addSociete, editSociete, getDetails, deleteSociete } from "../Services/SocieteService";
import { getAllTypesSocieteForSelect } from "../Services/TypeSocieteService";
import { getAllEffectif } from "../Services/EffectifService"
import { Box } from '@mui/material';
import { CardContent } from '@mui/material';
import { getAllSecteursForSelect } from "../Services/SecteurActiviteService";
import Input from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { getAllRegionForSelect } from "../Services/RegionService";
import { getConsultantsAndManagers } from "../Services/UserService";
import { withSnackbar } from "../../utils/SnackbarHOC";
import Loader from "../../components/Loader/Loader";
import { useMediaQuery } from '@mui/material';
import { theme } from "../../utils/Theme";
import LoadingButtonCustom from "../../components/LoadingButtonCustom/LoadingButtonCustom";
import DialogDeleteCustom from "../../components/DialogDeleteCustom/DialogDeleteCustom";
import EditorCustom from "../../components/EditorCustorm/EditorCustom";
import { removeLineBreak } from "../../utils/EditorUtil";
import {ArrowBack} from "@mui/icons-material";

const useStyles = styled(() => ({
  buttonProgress: {
    color: "white",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  firstCard: {
    height: "100%",
  },
  actionArea: {
    marginTop: "1rem",
  },
}));

const FormSociete = ({ editMode, snackbarShowMessage }) => {
  const history = useHistory();
  const [nomSociete, setNomSociete] = useState("");
  const [telephone, setTelephone] = useState("");
  const [adresse, setAdresse] = useState("");
  const [codePostal, setCodePostal] = useState("");
  const [ville, setVille] = useState("");
  const [siteInternet, setSiteInternet] = useState("");
  const [sachezQue, setSachezQue] = useState("");
  const [linkedin, setLinkedin] = useState();
  const [statut, setStatut] = useState("");
  const [typeSociete, setTypeSociete] = useState("");
  const [effectif, setEffectif] = useState("");
  const [secteurActivite, setSecteurActivite] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadSelectData, setLoadSelectData] = useState(true);
  const [typesSociete, setTypesSociete] = useState([]);
  const [regions, setRegions] = useState([]);
  const [region, setRegion] = useState("");
  const [effectifs, setEffectifs] = useState([]);
  const mounted = useRef(false);
  const title = editMode ? "MODIFIER UNE SOCIETE" : "AJOUTER UNE SOCIETE";
  const [loadingData, setLoadingData] = useState(true);
  const { id } = useParams();
  const statuts = { client: "CLIENT", prospect: "PROSPECT", autres: "AUTRES" };
  const classes = useStyles();
  const [secteurs, setSecteurs] = useState([]);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);

  const validate = () => {
    let temp = {};
    temp.nomSociete = nomSociete ? "" : "Le champ 'NOM SOCIETE' est obligatoire.";
    temp.typeSociete = typeSociete ? "" : "Le champ 'TYPE DE SOCIETE' est obligatoire.";
    temp.secteurActivite = secteurActivite ? "" : "Le champ 'SECTEUR D'ACTIVITÉ' est obligatoire.";
    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };
  const [managers, setManagers] = useState([]);
  const [manager, setManager] = useState();
  const isSmMode = useMediaQuery(theme.breakpoints.between(0, 480));

  const handleCloseDialog = () => {
    setOpenDialogDelete(false);
  };
  const handleSubmit = () => {
    if (validate()) {
      (async () => {
        setLoading(true);
        const data = {
          nom_societe: nomSociete,
          n_tel: telephone,
          adresse: adresse,
          code_postal: codePostal,
          ville: ville,
          siteinternet: siteInternet,
          sachez_que: removeLineBreak(sachezQue),
          statut: statut,
          type_societe: typeSociete,
          secteur_dactivite: secteurActivite,
          region_societe: region,
          effectif: effectif,
          linkedin: linkedin ?? "",
          accountManager: manager
        };

        try {
          snackbarShowMessage("Sociéte ajouter avec success !", "success", 4000);
          await addSociete(data);
          history.goBack()
        } catch (error) {
          snackbarShowMessage("Erreur d'ajout de la société: " + error.message, "error", 4000);
          if (!mounted.current) {
            return;
          }
        } finally {
          setLoading(false);
        }
      })();
    }
  };

  const handleUpdate = () => {
    if (validate()) {
      (async () => {
        setLoading(true);
        const data = {
          nom_societe: nomSociete,
          n_tel: telephone,
          adresse: adresse,
          code_postal: codePostal,
          ville: ville,
          siteinternet: siteInternet,
          sachez_que: removeLineBreak(sachezQue),
          statut: statut,
          type_societe: typeSociete,
          secteur_dactivite: secteurActivite,
          region_societe: region,
          effectif: effectif,
          linkedin: linkedin,
          accountManager: manager
        };

        try {
          snackbarShowMessage("Sociéte modifier avec success !", "success", 4000);
          await editSociete(data, id);
          history.goBack()
        } catch (error) {
          snackbarShowMessage("Erreur modification de la société: " + error.message, "error", 4000);
          if (!mounted.current) {
            return;
          }
        } finally {
          setLoading(false);
        }
      })();
    }
  };
  const handleDelete = () => {
    (async () => {
      setLoading(true);
      try {
        await deleteSociete(id);
        snackbarShowMessage("Bien supprimé", "success", 4000);
        if (!mounted.current) {
          return;
        }
        handleCloseDialog();
        history.goBack()
      } catch (error) {
        snackbarShowMessage("Impossible de supprimer cette Société car un contact ou une mission ou un candidat lui est rattaché.", "error", 4000);
        if (!mounted.current) {
          return;
        }
      } finally {
        setLoading(false);
      }
    })();
  }
  useEffect(() => {
    let active = true;

    const fetchData = async () => {
      setLoadingData(true);
      try {
        const response = await getDetails(id);
        if (!active) {
          return;
        }
        setNomSociete(response.data.nom_societe);
        setStatut(response.data.statut);
        setTelephone(response.data.n_tel);
        setAdresse(response.data.adresse);
        setCodePostal(response.data.code_postal);
        setVille(response.data.ville);
        setSiteInternet(response.data.site_internet);
        setTypeSociete(response.data.type_societe?.uuid || "");
        setEffectif(response.data.effectif?.uuid || "");
        setSecteurActivite(response.data.secteur_dactivite?.uuid || "");
        setSachezQue(response.data.sachez_que);
        setRegion(response.data.region?.uuid || "");
        setLinkedin(response.data.linkedin);
        setManager(response.data?.accountManager?.uuid);
      } catch (error) {
        if (!active) {
          return;
        }
        snackbarShowMessage("Error de chargement des données", "error", 4000);
        history.push({
          pathName: "/accueil",
        });
      } finally {
        if (active) {
          setLoadingData(false);
        }
      }
    };

    if (editMode) {
      fetchData();
    }

    return () => {
      active = false;
    };
  }, [id]);

  useEffect(() => {
    let active = true;
    const mounted = { current: true };

    const fetchSelectData = async () => {
      setLoadSelectData(true);
      try {
        const [typeReponse, secteurReponse, effectifReponse, responseRegion, { data }] = await Promise.all([
          getAllTypesSocieteForSelect(),
          getAllSecteursForSelect(),
          getAllEffectif(),
          getAllRegionForSelect(),
          getConsultantsAndManagers('ROLE_DELIVERY_MANAGER')
        ]);
        if (!active) {
          return;
        }
        setTypesSociete(typeReponse.data);
        setSecteurs(secteurReponse.data);
        setEffectifs(effectifReponse.data);
        setRegions(responseRegion.data);
        setManagers(data.managers);
      } catch (error) {
        if (!active) {
          return;
        }
        snackbarShowMessage("Error de chargement des données", "error", 4000);
      } finally {
        if (active) {
          setLoadSelectData(false);
        }
      }
    };

    if (mounted.current) {
      fetchSelectData();
    }

    return () => {
      active = false;
      mounted.current = false;
    };
  }, []);


  const loadingButtonProps = {
    loading,
    editMode,
    handleUpdate,
    handleSubmit,
  }

  const dialogDeleteProps = {
    handleClose: handleCloseDialog,
    handleOpen: openDialogDelete,
    handleDelete: handleDelete,
    loading: loading,
    label: `Voulez vous supprimer la sociéte : "{nomSociete}"?`,
    title: "Supprimer la société",
  };

  const editorCustomProps = {
    value: sachezQue,
    setValue: setSachezQue,
    enableOnBlur: false
  };

  return (
    <Box sx={{ padding: "0 20px " }}>
      {loadingData & editMode ? (
        <Loader />
      ) : (
        <>
          <Box className="candidatedit_header">
            <Grid container spacing={1} direction={isSmMode ? "column-reverse" : ""} >
              <Grid item xs={12} sm={6} md={6} xl={6}>
                <h4>
                  <Link
                    data-toggle="tooltip"
                    title="Retourner vers la page précedente"
                    onClick={(e) => {
                      e.preventDefault();
                      history.goBack();
                    }}
                    className="d-print-none font-sm"
                    style={{ textDecoration: "none", paddingRight: "20px" }}
                >
                  <ArrowBack />
                </Link>
                  {title}
                </h4>
              </Grid>
              <Grid item xs={12} sm={6} md={6} xl={6}>
                <Box sx={{ display: "flex", justifyContent: "end", gap: "10px", width: "100%" }}>
                  <Button
                    onClick={() => history.goBack()}
                    variant="outlined"
                    disabled={loading}
                    sx={{
                      color: "#1b2a4e",
                      backgroundColor: "#d9e2ef",
                      borderColor: "#d9e2ef",
                    }}
                  >
                    Annuler
                  </Button>
                  <LoadingButtonCustom {...loadingButtonProps} />
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box className="candidatedit_container">
            <Grid container spacing={3} alignItems={"stretch"}>
              <Grid item xs={12} sm={6}>
                <Card className={classes.firstCard}>
                  <CardContent>
                    <label className="input-label" htmlFor="nomSociete">
                      NOM SOCIETE<span className="required-input">*</span>
                    </label>
                    <TextField
                      onChange={(e) => setNomSociete(e.target.value.toUpperCase())}
                      name="nomSociete"
                      value={nomSociete}
                      placeholder="NOM SOCIETE"
                      margin="normal"
                      variant="outlined"
                      autoComplete="off"
                      fullWidth
                      {...(errors.nomSociete && {
                        error: true,
                        label: errors.nomSociete,
                      })}
                    />
                    <label className="input-label" htmlFor="statut">
                      STATUT
                    </label>
                    <FormControl variant="outlined" fullWidth margin="normal" required>
                      <Select name="statut" value={statut} onChange={(e) => setStatut(e.target.value)}>
                        {Object.entries(statuts).sort().map(([key, value]) => {
                          return (
                            <MenuItem value={key} key={key}>
                              {value}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    <label className="input-label" htmlFor="telStandard">
                      N° TEL STANDARD
                    </label>
                    <Input
                      defaultCountry="FR"
                      onChange={setTelephone}
                      name="telephone"
                      value={telephone}
                      placeholder="N° TEL STANDARD"
                      margin="normal"
                      variant="outlined"
                      autoComplete="off"
                      fullWidth
                      {...(errors.telephone && {
                        error: true,
                        label: errors.telephone,
                      })}
                    />
                    <label className="input-label" htmlFor="adresse">
                      ADRESSE
                    </label>
                    <TextField
                      onChange={(e) => setAdresse(e.target.value)}
                      name="adresse"
                      value={adresse}
                      placeholder="adresse"
                      margin="normal"
                      variant="outlined"
                      autoComplete="off"
                      fullWidth
                      multiline
                      minRows={2}

                    />
                    <label className="input-label" htmlFor="codePostal">
                      CODE POSTAL
                    </label>
                    <TextField
                      onChange={(e) => setCodePostal(e.target.value)}
                      name="codePostal"
                      value={codePostal}
                      placeholder="CODE POSTAl"
                      margin="normal"
                      variant="outlined"
                      autoComplete="off"
                      fullWidth
                    />
                    <label className="input-label">
                      LINKEDIN
                    </label>
                    <TextField
                      name="linkedin"
                      value={linkedin}
                      onChange={(e) => setLinkedin(e.target.value)}
                      margin="normal"
                      variant="outlined"
                      autoComplete="off"
                      fullWidth
                      placeholder="https://linkedin.com/in/profile."
                    />
                    <label className="input-label" htmlFor="Manager">
                    DELIVERY MANAGER
                    </label>
                    <FormControl variant="outlined" fullWidth={true} margin="normal">
                      {loadSelectData ? (
                        <Box display="flex" justifyContent="center">
                          <CircularProgress />
                        </Box>
                      ) : (
                        <Select
                          value={manager}
                          name="manager"
                          onChange={(e) => setManager(e.target.value)}
                          {...(errors.manager && {
                            error: true,
                          })}
                          input={
                            <OutlinedInput
                              name="manager"
                              {...(errors.manager && {
                                labelWidth: 340,
                              })}
                              notched
                            ></OutlinedInput>
                          }
                        >
                          {loadSelectData ? (
                            <CircularProgress />
                          ) : (
                            managers.map((manager) => (
                              <MenuItem value={manager.uuid} key={manager.uuid}>
                                <span style={{ textTransform: "capitalize" }}>{manager.user}</span>
                              </MenuItem>
                            )
                            )
                          )}
                        </Select>
                      )}
                    </FormControl>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Card>
                  <CardContent>
                    <label className="input-label" htmlFor="ville">
                      VILLE
                    </label>
                    <TextField
                      onChange={(e) => setVille(e.target.value)}
                      name="ville"
                      value={ville}
                      placeholder="VILLE"
                      margin="normal"
                      variant="outlined"
                      autoComplete="off"
                      fullWidth
                    />
                    <label className="input-label" htmlFor="region">
                      REGION
                    </label>
                    <FormControl variant="outlined" fullWidth margin="normal">
                      {loadSelectData ? (
                        <Box display="flex" justifyContent="center">
                          <CircularProgress />
                        </Box>
                      ) : (
                        <Select
                          value={region}
                          name="region"
                          onChange={(e) => {
                            setRegion(e.target.value);
                          }}
                        >
                          {loadSelectData ? (
                            <CircularProgress size={24} />
                          ) : (
                            regions.map((reg) => (
                              <MenuItem value={reg.uuid} key={reg.uuid}>
                                {reg.name}
                              </MenuItem>
                            ))
                          )}
                        </Select>
                      )}
                    </FormControl>
                    <label className="input-label" htmlFor="siteInternet">
                      SITE INTERNET
                    </label>
                    <TextField
                      onChange={(e) => setSiteInternet(e.target.value)}
                      name="siteInternet"
                      value={siteInternet}
                      placeholder="SITE INTERNET"
                      margin="normal"
                      variant="outlined"
                      autoComplete="off"
                      fullWidth
                    />
                    <label className="input-label" htmlFor="typeSociete">
                      TYPE DE SOCIETE <span className="required-input">*</span>
                    </label>
                    <FormControl variant="outlined" fullWidth margin="normal">
                      {loadSelectData ? (
                        <Box display="flex" justifyContent="center">
                          <CircularProgress />
                        </Box>
                      ) : (
                        <Select
                          value={typeSociete}
                          name="typeSociete"
                          onChange={(e) => {
                            setTypeSociete(e.target.value);
                          }}
                          {...(errors.typeSociete && {
                            error: true,
                            alert: errors.typeSociete,
                          })}
                        >
                          {loadSelectData ? (
                            <CircularProgress size={24} />
                          ) : (
                            typesSociete.map((type) => (
                              <MenuItem value={type.uuid} key={type.uuid}>
                                {type.type}
                              </MenuItem>
                            ))
                          )}
                        </Select>
                      )}
                    </FormControl>
                    <label className="input-label" htmlFor="effectif">
                      EFFECTIF
                    </label>
                    <FormControl variant="outlined" fullWidth margin="normal">
                      {loadSelectData ? (
                        <Box display="flex" justifyContent="center">
                          <CircularProgress />
                        </Box>
                      ) : (
                        <Select
                          value={effectif}
                          name="effectif"
                          onChange={(e) => {
                            setEffectif(e.target.value);
                          }}
                        >
                          {loadSelectData ? (
                            <CircularProgress size={24} />
                          ) : (
                            effectifs.map((effectif) => (
                              <MenuItem value={effectif.uuid} key={effectif.uuid}>
                                {effectif.type}
                              </MenuItem>
                            ))
                          )}
                        </Select>
                      )}
                    </FormControl>
                    <label className="input-label" htmlFor="secteurActivite">
                      SECTEUR D'ACTIVITÉ <span className="required-input">*</span>
                    </label>
                    <FormControl variant="outlined" fullWidth margin="normal">
                      {loadSelectData ? (
                        <Box display="flex" justifyContent="center">
                          <CircularProgress />
                        </Box>
                      ) : (
                        <Select
                          value={secteurActivite}
                          name="secteurActivite"
                          onChange={(e) => {
                            setSecteurActivite(e.target.value);
                          }}
                          {...(errors.secteurActivite && {
                            error: true,
                          })}
                        >
                          {loadSelectData ? (
                            <CircularProgress size={24} />
                          ) : (
                            secteurs.map((secteur) => (
                              <MenuItem value={secteur.uuid} key={secteur.uuid}>
                                {secteur.secteur}
                              </MenuItem>
                            ))
                          )}
                        </Select>
                      )}
                    </FormControl>
                    <label className="input-label" htmlFor="sachezQue">
                      SACHEZ QUE
                    </label>
                    <EditorCustom {...editorCustomProps} />
                  </CardContent>
                </Card>
              </Grid>
              <DialogDeleteCustom {...dialogDeleteProps} />
            </Grid>
          </Box>
        </>
      )}
    </Box>
  );
};

export default withSnackbar(FormSociete);
