// This is used to determine if a user is authenticated and
// if they are allowed to visit the page they navigated to.

// If they are: they proceed to the page
// If not: they are redirected to the login page.
import React, { useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";
import { getUserData } from "./utils/FunctionHelpers";
import LoadingSpinner from "./components/LoadingSpinner";

const PrivateRoute = ({ component: Component, notAllowedRoles, ...rest }) => {
  const [authState, setAuthState] = useState({
    isChecking: true,
    isAuthenticated: false,
    shouldRedirect: false,
    redirectPath: "/login"
  });

  useEffect(() => {
    const checkAuth = () => {
      const token = localStorage.getItem("token");
      const isAuthenticated = token && token !== "undefined";

      if (!isAuthenticated) {
        if (token === "undefined") {
          localStorage.clear();
        }
        localStorage.setItem(
          "redirectUrl",
          window.location.pathname + window.location.search
        );
        setAuthState({
          isChecking: false,
          isAuthenticated: false,
          shouldRedirect: true,
          redirectPath: "/login"
        });
        return;
      }

      const userData = getUserData();
      if (!userData || !userData.role) {
        localStorage.clear();
        setAuthState({
          isChecking: false,
          isAuthenticated: false,
          shouldRedirect: true,
          redirectPath: "/login"
        });
        return;
      }

      const hasRestrictedRole = userData.role.some((role) =>
        notAllowedRoles?.includes(role)
      );

      setAuthState({
        isChecking: false,
        isAuthenticated: true,
        shouldRedirect: hasRestrictedRole,
        redirectPath: hasRestrictedRole ? "/selections" : ""
      });
    };

    checkAuth();
  }, [notAllowedRoles]);

  if (authState.isChecking) {
    return <LoadingSpinner />;
  }

  if (authState.shouldRedirect) {
    return <Redirect to={authState.redirectPath} />;
  }

  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

export default PrivateRoute;
