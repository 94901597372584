import React, { useState } from "react";
import {
  Button,
  CircularProgress,
  FormControl,
  Radio,
  FormControlLabel,
  RadioGroup,
  IconButton,
  Box,
  Stack,
  Typography,
  Divider
} from '@mui/material';
import { styled } from "@mui/styles";
import { BsCalendar } from "react-icons/bs";
import { addAppointment, updateAppointment } from "../../../../../Services/AppointmentService";
import { withSnackbar } from "../../../../../../utils/SnackbarHOC";
import { removeLineBreak } from "../../../../../../utils/EditorUtil";
import Modal from "../../../../../../components/Modal/Modal";
import DatePickerWrapper from '../../../../../../components/DatePicker/DatePickerWrapper';

const APPOINTMENT_STATUSES = {
  PROPALE: "Propale",
  EMBAUCHE: "Embauche/Integration",
  REFUSED_BY_CLIENT: "Refusé par client",
  REFUSED_BY_CANDIDATE: "Refusé par candidat"
};

const AppointmentModal = ({
  appointment,
  processId,
  reloadData,
  setReloadData,
  lastAppointment,
  snackbarShowMessage,
  candidatInfo = {}
}) => {
  const classes = styled();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(new Date());
  const [dateIntegration, setDateIntegration] = useState(null);
  const [status, setStatus] = useState("");

  const handleOpen = () => setOpen(true);
  const handleClose = () => !loading && setOpen(false);

  const handleSelected = (newStatus) => {
    if (newStatus !== APPOINTMENT_STATUSES.EMBAUCHE) {
      setDateIntegration(null);
    }
    if ([APPOINTMENT_STATUSES.REFUSED_BY_CLIENT, APPOINTMENT_STATUSES.REFUSED_BY_CANDIDATE].includes(newStatus)) {
      setDate(new Date());
    }
    setStatus(newStatus);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const payload = {
        date,
        status,
        commentaire: removeLineBreak(""),
        dateIntegration,
        candidat: "",
        selected: []
      };

      const response = await addAppointment(processId, payload);
      snackbarShowMessage(response.data);
      setReloadData(!reloadData);
      handleClose();
    } catch (error) {
      snackbarShowMessage(error.response?.data?.error?.detail || "Une erreur est survenue");
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async () => {
    try {
      setLoading(true);
      const response = await updateAppointment({ date, processId });
      snackbarShowMessage(response.data);
      setReloadData(!reloadData);
      handleClose();
    } catch (error) {
      snackbarShowMessage(error.response?.data?.error?.detail || "Une erreur est survenue");
    } finally {
      setLoading(false);
    }
  };

  const renderDatePickers = () => {
    switch (status) {
      case APPOINTMENT_STATUSES.EMBAUCHE:
        return (
          <Stack spacing={2}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Box component="label" sx={{ fontWeight: 500 }}>
                Signé le <Box component="span" sx={{ color: 'error.main' }}>*</Box>
              </Box>
              <DatePickerWrapper
                autoOk
                disableToolbar
                format="dd/MM/yyyy"
                value={date}
                onChange={setDate}
                slotProps={{
                  textField: {
                    variant: 'outlined',
                    size: 'small'
                  }
                }}
              />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Box component="label" sx={{ fontWeight: 500 }}>
                Intègre le
              </Box>
              <DatePickerWrapper
                autoOk
                disableToolbar
                format="dd/MM/yyyy"
                value={dateIntegration}
                onChange={setDateIntegration}
                slotProps={{
                  textField: {
                    variant: 'outlined',
                    size: 'small'
                  }
                }}
              />
            </Box>
          </Stack>
        );

      case APPOINTMENT_STATUSES.PROPALE:
        return (
          <Box sx={{ mt: 2 }}>
            <Box component="label" sx={{ display: 'block', fontWeight: 500, mb: 1 }}>
              Date Propale <Box component="span" sx={{ color: 'error.main' }}>*</Box>
            </Box>
            <DatePickerWrapper
              autoOk
              disableToolbar
              format="dd/MM/yyyy"
              value={date}
              onChange={setDate}
              slotProps={{
                textField: {
                  variant: 'outlined',
                  size: 'small'
                }
              }}
            />
          </Box>
        );

      default:
        return null;
    }
  };

  const renderCandidatInfo = () => {
    if (!candidatInfo) return null;

    return (
      <Box sx={{ mb: 3 }}>
        {candidatInfo.client && (
          <Typography variant="body2" sx={{ mb: 1 }}>
            <strong>Client:</strong> {candidatInfo.client}
          </Typography>
        )}
        {candidatInfo.mission && (
          <Typography variant="body2" sx={{ mb: 1 }}>
            <strong>Mission:</strong> {candidatInfo.mission}
          </Typography>
        )}
        {candidatInfo.consultant && (
          <Typography variant="body2" sx={{ mb: 1 }}>
            <strong>Talent Manager:</strong> {candidatInfo.consultant}
          </Typography>
        )}
        <Divider sx={{ my: 2 }} />
      </Box>
    );
  };

  const modalContent = (
    <Box sx={{ width: '100%' }}>
      {renderCandidatInfo()}

      <FormControl component="fieldset" sx={{ width: '100%' }}>
        <Box component="label" sx={{ fontWeight: 500, mb: 1 }}>
          Actions
        </Box>
        <RadioGroup
          row
          value={status}
          onChange={(e) => handleSelected(e.target.value)}
          sx={{ mb: 2 }}
        >
          <FormControlLabel
            value={APPOINTMENT_STATUSES.PROPALE}
            control={<Radio color="primary" />}
            label="Propale"
          />
          <FormControlLabel
            value={APPOINTMENT_STATUSES.EMBAUCHE}
            control={<Radio color="primary" />}
            label="Embauche/Integration"
          />
        </RadioGroup>

        {renderDatePickers()}

        <Box component="label" sx={{ fontWeight: 500, mt: 3, mb: 1, display: 'block' }}>
          Statut
        </Box>
        <RadioGroup
          row
          value={status}
          onChange={(e) => handleSelected(e.target.value)}
        >
          <Stack direction="row" spacing={2}>
            <FormControlLabel
              value={APPOINTMENT_STATUSES.REFUSED_BY_CLIENT}
              control={<Radio color="primary" />}
              label="Refusé par client"
            />
            <FormControlLabel
              value={APPOINTMENT_STATUSES.REFUSED_BY_CANDIDATE}
              control={<Radio color="primary" />}
              label="Refusé par candidat"
            />
          </Stack>
        </RadioGroup>
      </FormControl>
    </Box>
  );

  return (
    <>
      <Button
        variant="text"
        onClick={handleOpen}
        sx={{
          textTransform: 'none',
          minWidth: 0,
          p: 0
        }}
      >
        <IconButton onClick={handleOpen} className={classes.takeAppointment}>
          <BsCalendar size={20} />
        </IconButton>
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        title={appointment ? "Modifier le RDV" : "Issue du Process"}
        actions={
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
            <Button
              onClick={handleClose}
              variant="outlined"
              color="inherit"
              disabled={loading}
            >
              Annuler
            </Button>
            <Button
              onClick={appointment ? handleUpdate : handleSubmit}
              variant="contained"
              color="primary"
              disabled={loading || !status}
              startIcon={loading && <CircularProgress size={20} />}
            >
              Sauvegarder
            </Button>
          </Box>
        }
      >
        <Box sx={{ width: '100%' }}>
          {renderCandidatInfo()}

          <FormControl component="fieldset" sx={{ width: '100%' }}>
            <Box component="label" sx={{ fontWeight: 500, mb: 1 }}>
              Actions
            </Box>
            <RadioGroup
              row
              value={status}
              onChange={(e) => handleSelected(e.target.value)}
              sx={{ mb: 2 }}
            >
              <FormControlLabel
                value={APPOINTMENT_STATUSES.PROPALE}
                control={<Radio color="primary" />}
                label="Propale"
              />
              <FormControlLabel
                value={APPOINTMENT_STATUSES.EMBAUCHE}
                control={<Radio color="primary" />}
                label="Embauche/Integration"
              />
            </RadioGroup>

            {renderDatePickers()}

            <Box component="label" sx={{ fontWeight: 500, mt: 3, mb: 1, display: 'block' }}>
              Statut
            </Box>
            <RadioGroup
              row
              value={status}
              onChange={(e) => handleSelected(e.target.value)}
            >
              <Stack direction="row" spacing={2}>
                <FormControlLabel
                  value={APPOINTMENT_STATUSES.REFUSED_BY_CLIENT}
                  control={<Radio color="primary" />}
                  label="Refusé par client"
                />
                <FormControlLabel
                  value={APPOINTMENT_STATUSES.REFUSED_BY_CANDIDATE}
                  control={<Radio color="primary" />}
                  label="Refusé par candidat"
                />
              </Stack>
            </RadioGroup>
          </FormControl>
        </Box>
      </Modal>
    </>
  );
};

export default withSnackbar(AppointmentModal);
