import { Avatar, List, ListItem, ListItemAvatar, ListItemText, IconButton } from '@mui/material';
import { Visibility } from '@mui/icons-material';
import moment from "moment";
import React, { useState } from "react";
import { AiOutlineComment } from "react-icons/ai";
import { BiTime } from "react-icons/bi";
import { IoCheckmarkDoneCircleSharp } from "react-icons/io5";
import { RiUser2Fill, RiUserReceivedLine } from "react-icons/ri";
import { VscLoading } from "react-icons/vsc";
import LabelHtmlToTextCustom from "../LabelHtmlToTextCustom/LabelHtmlToTextCustom";
import Modal from "../Modal/Modal";

function ViewTacheModal({ affecteA, affectePar, commentaire, dateTime, statut }) {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true)
    };
    const handleClose = () => setOpen(false);

    const getStatusIcon = () => {
        switch (statut) {
            case "En cours":
                return <VscLoading size={24} />;
            case "Terminé":
                return <IoCheckmarkDoneCircleSharp size={24} color="rgb(66, 186, 150)" />;
            default:
                return null;
        }
    };

    const modalContent = (
        <>
            <List>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar>
                            <RiUser2Fill />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Affecté à" secondary={affecteA} />
                </ListItem>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar>
                            <RiUserReceivedLine />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Affecté par" secondary={affectePar} />
                </ListItem>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar>
                            <BiTime />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Date" secondary={moment(dateTime).format("DD/MM/YYYY HH:mm")} />
                </ListItem>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar>
                            <AiOutlineComment />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                        primary="Commentaire"
                        secondary={commentaire}
                    />
                </ListItem>
            </List>
            {getStatusIcon()}
        </>
    );

    return (
        <>
            <IconButton
                size="small"
                onClick={handleOpen}
                color="primary"
            >
                <Visibility />
            </IconButton>
            <Modal
                open={open}
                onClose={handleClose}
                title="Détails de la tâche"
                children={modalContent}
            />
        </>
    );
}

export default ViewTacheModal;
