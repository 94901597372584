import api from "../../utils/API";

const getAll = (page) => {
  return api.get(`/candidatsmanagement/candidat/list?page=${page}&limit=${7}`);
};

const getDetails = (uuid, params = "") => {
  return api.get(`/candidatsmanagement/candidat/${uuid}/details${params}`);
};

const deleteCandidat = (uuid) => {
  return api.delete(`/candidatsmanagement/candidat/${uuid}/delete`);
};

const addCandidat = (data) => {
  return api.post(`/candidatsmanagement/candidat/add`, data);
};
const addCvCandidat = (data) => {
  return api.post(`/candidatsmanagement/candidat/cv/add`, data, {headers: { 'Content-Type': 'multipart/form-data'}});
};

const editCandidat = (data, uuid) => {
  return api.post(`/candidatsmanagement/candidat/${uuid}/edit`, data, {headers: { 'Content-Type': 'multipart/form-data'}});
};

const searchCandidats = (url, page) => {
  return api.get(`/candidatmanagement/recherche/search?page=${page}&limit=${50}&${url}`);
};

const getAllCandidatsForfMission = (mission, inactive = false) => {
  return api.get(`/candidats/mission/list?inactive=${inactive}&mission=${mission}`);
};

const getCvCandidat = (pdfFileName) => {
  return api.get(`/candidatsmanagement/candidat/${pdfFileName}`);
};

const getAllCandidatsForReporting = (queryParams = "") => {
  return api.get(`/candidats/mission/list${queryParams}`);
}

const getAllCandidatsForSociete = (uuid) => {
  return api.get(`/societe/${uuid}/candidats/list`);
}

const callCandidat = (payload) => {
  return api.post('/ringover/webhook/call', payload);
}

const sendSmsToCandidat = (payload) => {
  return api.post('/ringover/webhook/push/sms', payload);
}

const disableMailing = (id) => {
  return api.put(`candidatsmanagement/candidat/${id}/disable/mailing`)
}
const getCandidatsAndContacts = (uuid) => {
  return api.get(`candidatsmanagement/candidat/${uuid}/related`);
}
export {
  getAll,
  getDetails,
  editCandidat,
  deleteCandidat,
  addCandidat,
  searchCandidats,
  getAllCandidatsForfMission,
  getCvCandidat,
  getAllCandidatsForReporting,
  getAllCandidatsForSociete,
  callCandidat,
  addCvCandidat,
  sendSmsToCandidat,
  disableMailing,
  getCandidatsAndContacts
};
