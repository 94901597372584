import { Box, useMediaQuery } from '@mui/material';
import { useSelector } from "react-redux";
import { theme } from "../../utils/Theme";


export default function ResponsiveLayout({ children, ...rest }) {
    const sidebarOpen = useSelector((state) => state.toggle.isToggle);
    const isMdMode = useMediaQuery(theme.breakpoints.between(0, 992));
    return <Box className={isMdMode ? "box_content_mobile" : sidebarOpen ? "box_content_desktop_expanded" : "box_content_desktop"}{...rest}>
        {children}
    </Box>
}