import DatePickerWrapper from '../../../../components/DatePicker/DatePickerWrapper';
import {
    Box,
    Card,
    CircularProgress, FormControl,
    InputLabel,
    MenuItem, OutlinedInput, Select
} from '@mui/material';
import React, { useRef, useState } from "react";
import { edit, getDetails, getPhoning } from "../../../Services/ContactService";
import { KeyboardDatePicker } from "@mui/x-date-pickers";
import { getConsultantsAndManagers } from "../../../Services/UserService";
import { useParams } from "react-router-dom";
import { Grid } from '@mui/material';
import { withSnackbar } from "../../../../utils/SnackbarHOC";
import { v4 } from "uuid";


function Prospection({ snackbarShowMessage, idCurrentUser }) {
    const [loading, setLoading] = useState(false);
    const [phoning, setPhoning] = useState([]);
    const [managers, setManagers] = useState([]);
    const [errors, setErrors] = useState({});
    const [phone, setPhone] = useState("")
    const [newTitre, setNewTitre] = useState("");
    const [newNom, setNewNom] = useState("");
    const [newPrenom, setNewPrenom] = useState("");
    const [newFonction, setNewFonction] = useState("");
    const [newStatut, setNewStatut] = useState("");
    const [newEmail, setNewEmail] = useState("");
    const [newMobile, setNewMobile] = useState("");
    const [newTelFix, setNewTelFix] = useState("");
    const [newLinkedIn, setNewLinkedIn] = useState("");
    const [newSachezQue, setNewSachezQue] = useState("");
    const [newdatePremierContact, setNewdatePremierContact] = useState("");
    const [newdatederniercontact, setNewdatederniercontact] = useState("");
    const [newsource, setnewsource] = useState("");
    const [newstep, setnewstep] = useState("");
    const [directionSociete, setDirectionSociete] = useState("");
    const [newAccountManager, setNewAccountManager] = useState(idCurrentUser);
    const { id, idContact } = useParams();
    const [datePhoning, setDatePhonning] = useState("");
    const [dmrLKD, setDmrLKD] = useState("");
    const [dmr, setDmr] = useState("");
    const [loadData, setLoadData] = useState(false);
    let mounted = useRef(false);


    React.useEffect(() => {
        let mounted = true;

        const fetchData = async () => {
            setLoadData(true);
            setPhoning([]);

            try {
                const [phoningData, managerData] = await Promise.all([getPhoning(), getConsultantsAndManagers()]);
                if (mounted) {
                    setManagers(managerData.data.managers);
                    setPhoning(phoningData.data);
                }
            } catch (error) {
                snackbarShowMessage("Une erreur est survenue: " + error.message, "error", 4000)
            } finally {
                if (mounted) {
                    setLoadData(false);
                }
            }
        };

        fetchData();

        return () => {
            mounted = false;
        };
    }, []);

    React.useEffect(() => {
        let active = true;
        (async () => {
            setLoading(true);
            try {
                const res = await getDetails(idContact);
                if (!active) {
                    return;
                }
                setNewTitre(res.data.titre);
                setNewPrenom(res.data.prenom);
                setNewNom(res.data.nom);
                setNewFonction(res.data.fonction);
                setNewStatut(res.data.statut);
                setNewAccountManager(res.data.accountManager ? res.data.accountManager : idCurrentUser);
                setNewEmail(res.data.email);
                setNewMobile(res.data.mobile);
                setNewTelFix(res.data.telFix);
                setNewLinkedIn(res.data.linkedIn);
                setNewSachezQue(res.data.sachezQue);
                setNewdatePremierContact(res.data.premiercontact);
                setNewdatederniercontact(res.data.derniercontact);
                setnewsource(res.data.source);
                setnewstep(res.data.step);
                setDirectionSociete(res.data.direction);
                setPhone(res.data.phoning);
                setDmr(res.data.dmr);
                setDmrLKD(res.data.dmrLKD);
                setDatePhonning(res.data.datePhoning);
            } catch (error) {
                if (!active) {
                    return;
                }
            } finally {
                setLoading(false);
            }
        })();

        return () => {
            active = false;
        };
    }, []);

    const handleUpdate = () => {
        setLoading(true);
        (async () => {
            const contact = {
                titre: newTitre,
                nom: newNom,
                prenom: newPrenom,
                fonction: newFonction,
                statut: newStatut,
                accountManager: newAccountManager,
                email: newEmail,
                mobile: newMobile,
                telFixDirect: newTelFix,
                linkedIn: newLinkedIn,
                sachezQue: newSachezQue,
                societe: id,
                direction: directionSociete,
                premiercontact: newdatePremierContact,
                derniercontact: newdatederniercontact,
                source: newsource,
                step: newstep,
                phoning: phone,
                dmrLKD: dmrLKD,
                dmr: dmr,
                datePhoning: datePhoning
            };
            try {
                snackbarShowMessage("Patientez svp!", "warning", null)
                await edit(contact, idContact);
                snackbarShowMessage("Modifié avec succès.", "success");
                if (!mounted.current) {
                    return;
                }
            } catch (error) {
                snackbarShowMessage("Une erreur est survenue: " + error.message, "error", 4000)
            } finally {
                setLoading(false);
            }
        })();

    };

    return (
        <Card style={{ width: "100%", padding: "25px" }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={3} lg={3}>
                    <FormControl variant="outlined" fullWidth>
                        <label className="input-label" htmlFor="phone">
                            STATUT PHONING
                        </label>
                        <InputLabel error={errors?.phone} shrink htmlFor="phone">
                            {errors.phone}
                        </InputLabel>
                        {loadData ? (
                            <Box display="flex" justifyContent="center">
                                <CircularProgress />
                            </Box>
                        ) : (
                            <Select
                                value={phone}
                                disabled={loading}
                                name="phone"
                                autofocus={true}
                                onChange={(value) => setPhone(value.target.value)}
                                onBlur={handleUpdate}
                                error={errors.phone}
                                input={
                                    <OutlinedInput
                                        name="phone"
                                        notched
                                        labelWidth={errors?.phone ? 340 : undefined}
                                    />
                                }
                            >
                                {phoning.map((phone) => (
                                    <MenuItem key={phone.uuid} value={phone.uuid}>
                                        <em>{phone.nom}</em>
                                    </MenuItem>
                                ))}
                            </Select>
                        )}
                    </FormControl>
                </Grid>

                {[{ label: "DATE PHONING", value: datePhoning, onChange: setDatePhonning },
                { label: "DMR LKD", value: dmrLKD, onChange: setDmrLKD },
                { label: "DMR ACCEPTÉE", value: dmr, onChange: setDmr }]
                    .map((field, index) => (
                        <Grid item xs={12} sm={12} md={3} lg={3} key={v4()}>
                            <FormControl variant="outlined" fullWidth>
                                <label className="input-label">{field.label}</label>
                                <DatePickerWrapper
                                    invalidDateMessage=""
                                    style={{ marginTop: "0px" }}
                                    disableToolbar
                                    disabled={loading}
                                    autoOk={true}
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    inputVariant="outlined"
                                    margin="normal"
                                    value={field.value}
                                    onChange={(value) => {
                                        field.onChange(value);
                                        handleUpdate();
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    ))}
                <Grid item xs={12} sm={12} md={3} lg={3}>
                    <FormControl variant="outlined" fullWidth >
                        <label className="input-label" htmlFor="compete-lkd">
                            COMPTE LKD
                        </label>
                        <InputLabel
                            error={errors?.newAccountManager}
                            shrink
                            htmlFor="newAccountManager"
                        >
                            {errors.user}
                        </InputLabel>
                        {loadData ? (
                            <Box display="flex" justifyContent="center">
                                <CircularProgress />
                            </Box>
                        ) : (
                            <Select
                                value={newAccountManager}
                                name="user"
                                onChange={e => setNewAccountManager(e.target.value)}
                                onBlur={handleUpdate}
                                error={errors.user}
                                disabled={loading}
                                input={
                                    <OutlinedInput
                                        name="user"
                                        notched
                                        labelWidth={errors?.source ? 340 : undefined}
                                    />
                                }
                            >
                                {managers.map((manager) => (
                                    <MenuItem key={manager.uuid} value={manager.uuid}>
                                        <em>{manager.user}</em>
                                    </MenuItem>
                                ))}
                            </Select>
                        )}
                    </FormControl>
                </Grid>
            </Grid>
        </Card>

    );
}

export default withSnackbar(Prospection)