import React, { useState } from "react";
import {Link, useHistory, useParams} from 'react-router-dom';
import { Etape1 } from "./Components/etape1";
import { Etape2 } from "./Components/etape2";
import { Etape3 } from "./Components/etape3";
import "./addcandidat.css";
import { Box, Grid, useMediaQuery } from '@mui/material';
import { addCandidat, getDetails, editCandidat } from "../../Services/CandidatService";
import { withSnackbar } from "../../../utils/SnackbarHOC";
import SaveBar from "./Components/SaveBar";
import { addremovecandidattoselection } from "../../Services/SelectionCandidatService";
import { removeLineBreak } from "../../../utils/EditorUtil";
import { theme } from "../../../utils/Theme";
import Loader from "../../../components/Loader/Loader";
import { useQuery } from "../../../utils/FunctionHelpers";
import ShowCVCandidat from "../VisualiserCandidat/ShowCVCandidat";
import {ArrowBack} from "@mui/icons-material";

const CandidatForm = ({ editMode, snackbarShowMessage }) => {
  let query = useQuery();
  const history = useHistory();
  const [datedebut, setDateDebut] = useState(new Date());
  const [linkedin, setLinkedin] = useState("");
  const [titre, setTitre] = useState("");
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [telephone, setTelephone] = useState("");
  const [email, setEmail] = useState("");
  const [employeur, setEmployeur] = useState("");
  const [fonction, setFonction] = useState("");
  const [manager, setManager] = useState("");
  const [talentManager, setTalentManager] = useState("");
  const [sourceur, setSourceur] = useState("");
  const [avatar, setAvatar] = useState("");
  const [sachezque, setSachezQue] = useState("");
  const [disponibilite, setDisponibilite] = useState("");
  const [competencesSectorielles, setCompetencesSectorielles] = useState([]);
  const [candidatSelectionsData, setCandidatSelectionsData] = useState([])
  const [competencesMetiers, setCompetencesMetiers] = useState([]);
  const [competencesTechniques, setCompetencesTechniques] = useState("");
  const [ecole, setEcole] = useState("");
  const [anglais, setAnglais] = useState("");
  const [francais, setFrancais] = useState("");
  const [actuPackage, setActuPackage] = useState("");
  const [actuFixe, setActuFixe] = useState("");
  const [actuVariable, setActuVariable] = useState("");
  const [actuInteressement, setActuInteressement] = useState("");
  //const [actuAutres, setActuAutres] = useState("");
  const [souhaitPackage, setsouhaitPackage] = useState("");
  const [souhaitFixe, setSouhaitFixe] = useState("");
  const [souhaitVariable, setSouhaitVariable] = useState("");
  const [souhaitInteressement, setSouhaitInteressement] = useState("");
  //const [souhaitAutres, setSouhaitAutres] = useState("");
  const [souhaitTjm, setSouhaitTjm] = useState("");
  const [preAvis, setPreAvis] = useState("");
  const [dispoLe, setDispoLe] = useState("");
  const [errors, setErrors] = useState({});
  const [softskills, setSoftskills] = useState('');
  const [commentaire, setCommentaire] = useState('');
  const [pointsforts, setPointsforts] = useState('');
  const [cv, setCv] = useState([]);
  const [cvFormate, setCvFormate] = useState([]);
  const [autresPieces, setAutresPieces] = useState([]);
  const [seniorite, setSeniorite] = useState();
  const title = editMode ? prenom + " " + nom : "Ajouter un candidat ";
  const getFormTitle = () => {
    const numCandidat = query.get('num-candidat');
    return title + (numCandidat ? " - N°" + numCandidat : "");
  }
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const { idCandidat } = useParams();
  const [oldCvs, setOldCvs] = useState([]);
  const [oldCvsFormate, setOldcvsFormate] = useState([]);
  const [oldAutresPieces, setOldAutresPieces] = useState([]);
  const [oldFiles, setOldFiles] = useState([]);
  const [source, setSource] = useState();
  const [localisation, setLocalisation] = useState("");
  const [status, setStatus] = useState("");
  const [reloadData, setReloadData] = useState(false);

  React.useEffect(() => {
    let active = true;

    const fetchData = async () => {
      setLoadingData(true);
      try {
        if (editMode) {
          const { data } = await getDetails(idCandidat);
          if (active) {
            setLinkedin(data.linkedin);
            setTitre(data.titre);
            setPrenom(data.prenom);
            setNom(data.nom);
            setEmail(data.email);
            setTelephone(data.telephone);
            setEmployeur(data.employeur || "");
            setFonction(data.fonction);
            setDateDebut(data.dateDebutExperience ? new Date(data.dateDebutExperience) : null);
            setSeniorite(data.seniorite);
            setManager(data.accountManager ? data.accountManager.uuid : "");
            setTalentManager(data.consultant ? data.consultant.uuid : "");
            setSourceur(data.sourceur ? data.sourceur.uuid : "");
            setAvatar(data.avatar ? data.avatar.uuid : "");
            setSachezQue(data.sachezQue);
            setCompetencesSectorielles(data.competenceSectorielle || "");
            setCompetencesTechniques(data.competencesTechniques || "");
            setCompetencesMetiers(data.competenceMetier);
            setEcole(data.ecole);
            setAnglais(data.anglais);
            setFrancais(data.francais);
            setsouhaitPackage(data.souhaitePackage);
            setSouhaitFixe(data.souhaiteFixe);
            setSouhaitVariable(data.souhaiteVariable);
            setSouhaitInteressement(data.souhaiteInteressemVisualiserCandidatent);
            //setSouhaitAutres(data.souhaiteAutres);
            setSouhaitTjm(data.souhaiteTjm);
            setActuPackage(data.actuellePackage);
            setActuVariable(data.actuelleVariable);
            setActuFixe(data.actuelleFixe);
            setActuInteressement(data.actuelleInteressement);
            //setActuAutres(data.actuelleAutres);
            setCommentaire(data.commentaire);
            setSoftskills(data.softskills);
            setPointsforts(data.pointsforts);
            setDispoLe(data.dispoLe);
            setPreAvis(data.preAvis);
            setOldFiles(data.uploadedFiles);
            setSource(data.source);
            setLocalisation(data.localisation);
            setStatus(data.status);
          }
        }
      } catch (error) {
        snackbarShowMessage(`Error fetching data:${error?.message}`, "error", 4000);
      } finally {
        if (active) {
          setLoadingData(false);
        }
      }
    };

    fetchData();

    return () => {
      active = false;
    };
  }, [idCandidat]);


  const validate = () => {
    let temp = {};
    temp.linkedin = /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)/.test(linkedin) ? "" : "le champ 'LINKEDIN' n'est pas valide.";
    temp.titre = titre?.trim()?.match(/^(Mme|Mr)$/) ? "" : "Le champ 'TITRE' est obligatoire.";
    temp.prenom = prenom ? "" : "Le champ 'PRÉNOM' est obligatoire.";
    temp.source = source ? "" : "Le champ 'SOURCE' est obligatoire.";
    temp.email = email ? "" : "Le champ 'EMAIL' est obligatoire.";
    temp.nom = nom ? "" : "Le champ 'NOM' est obligatoire.";
    temp.seniorite = seniorite < 0 ? "Veuillez choisir une date <ou= à celle d'aujourd'hui." : "";
    temp.fonction = fonction ? "" : "Le champ 'FONCTION ' est obligatoire.";
    temp.datedebut = datedebut ? "" : "Le champ 'DATE DÉBUT EXPÉRIENCE' est obligatoire.";
    temp.candidatSelectionsData = candidatSelectionsData && candidatSelectionsData.length > 0 ? "" : "Le champ 'Selection(s)' est obligatoire.";
    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };

  const nullComment = '<p style="margin-block:1px !important; font-size: 14px;line-height: 18px;">null<p style="margin-block:1px !important; font-size: 14px;line-height: 18px;">'
  const setFormData = () => {
    const formData = new FormData();
    const sanitizeValue = (value) => {
      return value == null || value === "null" || value === "undefined" || value == nullComment ? "" : value;
    };
    cv.forEach((cvFile) => formData.append("cvs[]", cvFile));
    cvFormate.forEach((cvFormateFile) => formData.append("cvsFormate[]", cvFormateFile));
    autresPieces.forEach((autrePiece) => formData.append("autresPieces[]", autrePiece));

    formData.append("linkedin", sanitizeValue(linkedin));
    formData.append("titre", sanitizeValue(titre));
    formData.append("nom", sanitizeValue(nom));
    formData.append("prenom", sanitizeValue(prenom));
    formData.append("email", sanitizeValue(email));
    formData.append("telephone", sanitizeValue(telephone));
    formData.append("employeur", sanitizeValue(employeur?.uuid));
    formData.append("fonction", sanitizeValue(fonction));
    formData.append("datedebut", sanitizeValue(datedebut));
    formData.append("seniorite", sanitizeValue(seniorite));
    formData.append("manager", sanitizeValue(manager));
    formData.append("consultant", sanitizeValue(talentManager));
    formData.append("sourceur", sanitizeValue(sourceur));
    formData.append("avatar", sanitizeValue(avatar));
    formData.append("sachezque", sanitizeValue(sachezque));
    formData.append("source", sanitizeValue(source));
    formData.append("localisation", sanitizeValue(localisation));
    formData.append("status", sanitizeValue(status));

    competencesSectorielles.forEach((secteur) =>
        formData.append("competencesSectorielles[]", sanitizeValue(secteur))
    );
    competencesMetiers.forEach((metier) =>
        formData.append("competencesMetiers[]", sanitizeValue(metier))
    );

    formData.append("competencesTechniques", sanitizeValue(competencesTechniques));
    formData.append("ecole", sanitizeValue(ecole?.id));
    formData.append("anglais", sanitizeValue(anglais));
    formData.append("francais", sanitizeValue(francais));
    formData.append("actuPackage", sanitizeValue(actuPackage));
    formData.append("actuFixe", sanitizeValue(actuFixe));
    formData.append("actuVariable", sanitizeValue(actuVariable));
    formData.append("actuInteressement", sanitizeValue(actuInteressement));
    //formData.append("actuAutres", sanitizeValue(actuAutres));
    formData.append("preAvis", sanitizeValue(preAvis));
    formData.append("dispoLe", sanitizeValue(dispoLe));
    formData.append("souhaitPackage", sanitizeValue(souhaitPackage));
    formData.append("souhaitFixe", sanitizeValue(souhaitFixe));
    formData.append("souhaitVariable", sanitizeValue(souhaitVariable));
    formData.append("souhaitInteressement", sanitizeValue(souhaitInteressement));
    //formData.append("souhaitAutres", sanitizeValue(souhaitAutres));
    formData.append("souhaitTjm", sanitizeValue(souhaitTjm));
    oldFiles?.map((oldFile) => formData.append("oldFiles[]", oldFile.id));
    formData.append("commentaire", sanitizeValue(removeLineBreak(commentaire)));
    formData.append("softskills", sanitizeValue(removeLineBreak(softskills)));
    formData.append("pointsforts", sanitizeValue(removeLineBreak(pointsforts)));

    return formData;
  };

  const handleSubmit = () => {
    if (!validate()) return
    setLoading(true);
    const formData = setFormData();
    (async () => {
      try {
        snackbarShowMessage("Enregistrement en cours...", "warning", 2000);
        const { data } = await addCandidat(formData)
        await addremovecandidattoselection({
          selectedSelections: candidatSelectionsData
        }, data?.newCandidatId)
        history.push({
          pathname: `/candidats/${data?.newCandidatId}/view`,
          state: { snack: true, msg: data },
        });
        snackbarShowMessage("Candidat ajouter avec succès.", "success", 4000);
      } catch (err) {
        snackbarShowMessage(err?.response?.data?.error?.detail, "error", 4000);
      } finally {
        setLoading(false);
      }
    })();
  };

  const handleEdit = () => {
    if (!validate()) return
    const formData = setFormData();
    // return;
    (async () => {
      setLoading(true);
      try {
        snackbarShowMessage("Modification en cours...", "warning", null);
        await editCandidat(formData, idCandidat);
        await addremovecandidattoselection({
          selectedSelections: candidatSelectionsData
        }, idCandidat)
        history.goBack()
        snackbarShowMessage("Candidat modifié avec succès.", "success");
      } catch (err) {
        snackbarShowMessage("Erreur lors de modification du candidat !", "error", 4000);
      } finally {
        setLoading(false);
      }
    })();
  };

  const etape1 = {
    idCandidat,
    linkedin,
    titre,
    nom,
    prenom,
    telephone,
    email,
    employeur,
    fonction,
    datedebut,
    seniorite,
    manager,
    talentManager,
    sourceur,
    avatar,
    sachezque,
    setLinkedin,
    setTitre,
    setPrenom,
    setNom,
    setTelephone,
    setEmail,
    setEmployeur,
    setFonction,
    setDateDebut,
    setSeniorite,
    setManager,
    setTalentManager,
    setSourceur,
    setAvatar,
    setSachezQue,
    errors,
    setErrors,
    editMode,
    handleSubmit,
    handleEdit,
    snackbarShowMessage,
    loading,
    source,
    setSource,
    localisation,
    setLocalisation,
    status,
    setStatus
  };

  const etape2 = {
    idCandidat,
    competencesSectorielles,
    competencesMetiers,
    disponibilite,
    competencesTechniques,
    ecole,
    anglais,
    francais,
    actuPackage,
    actuFixe,
    actuVariable,
    actuInteressement,
    //actuAutres,
    souhaitPackage,
    souhaitFixe,
    souhaitVariable,
    souhaitInteressement,
    //souhaitAutres,
    souhaitTjm,
    candidatSelectionsData,
    setCandidatSelectionsData,
    setDisponibilite,
    setCompetencesSectorielles,
    setCompetencesMetiers,
    setCompetencesTechniques,
    setEcole,
    setAnglais,
    setFrancais,
    setActuPackage,
    setActuFixe,
    setActuVariable,
    setActuInteressement,
    //setActuAutres,
    setsouhaitPackage,
    setSouhaitFixe,
    setSouhaitVariable,
    setSouhaitInteressement,
    //setSouhaitAutres,
    setSouhaitTjm,
    preAvis,
    setPreAvis,
    errors,
    setErrors,
    editMode,
    dispoLe,
    setDispoLe,
    handleSubmit,
    handleEdit,
    snackbarShowMessage,
    loading,
    setReloadData,
    reloadData,
  };
  const etape3 = {
    idCandidat,
    softskills,
    setSoftskills,
    commentaire,
    setCommentaire,
    pointsforts,
    setPointsforts,
    cv,
    setCv,
    cvFormate,
    setCvFormate,
    autresPieces,
    setAutresPieces,
    errors,
    setErrors,
    editMode,
    handleSubmit,
    loading,
    oldCvs,
    oldCvsFormate,
    oldAutresPieces,
    setOldCvs,
    setOldcvsFormate,
    setOldAutresPieces,
    handleEdit,
    oldFiles,
    setOldFiles
  };
  const saveBarProps = {
    handleSubmit, editMode, handleEdit, loading, idCandidat
  }
  const isSmMode = useMediaQuery(theme.breakpoints.between(0, 480));

  return (
    <Box sx={{ padding: "0 20px " }}>

      {loadingData ? (
        <Loader />
      ) : (
        <>
          <Box className="candidatedit_header">
            <Grid container spacing={1} direction={isSmMode ? "column-reverse" : ""} >
              <Grid item xs={12} sm={6} md={6} xl={6}>
                <Box sx={{ display: "flex", gap: "10px" }}>
                  <h4>
                      <Link
                        data-toggle="tooltip"
                        title="Retourner vers la page précedente"
                        onClick={(e) => {
                          e.preventDefault();
                          history.goBack();
                        }}
                        className="d-print-none font-sm"
                        style={{ textDecoration: "none", paddingRight: "20px" }}
                    >
                      <ArrowBack />
                    </Link>
                    { getFormTitle() }
                  </h4>
                  {editMode && <ShowCVCandidat
                    {...{
                      idCandidat: query.get('idCandidat'),
                      snackbarShowMessage,
                      files: oldFiles,
                      editMode
                    }}
                  />
                  }
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6} xl={6}>
                <SaveBar item {...saveBarProps} />
              </Grid>
            </Grid>
          </Box>

          <Box className="candidatedit_container">
            <Grid container spacing={1}>
              <Etape1 {...etape1} />
              <Etape2 {...etape2} />
              <Etape3 {...etape3} />
            </Grid>
          </Box>
        </>

      )}
    </Box>
  );
};

export default withSnackbar(CandidatForm);
