import { AttachFile, Business, EventNote } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import fr from 'date-fns/locale/fr';
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { FiSend } from 'react-icons/fi';
import DialogDeleteCustom from "../../components/DialogDeleteCustom/DialogDeleteCustom";
import Loader from "../../components/Loader/Loader";
import { withSnackbar } from "../../utils/SnackbarHOC";
import AppointmentModal from "../Candidat/VisualiserCandidat/Tabs/Modals/CandidatProcess/AppointmentModal";
import SendCandidatToClientModal from "../Candidat/VisualiserCandidat/Tabs/Modals/CandidatProcess/SendCandidatToClientModal";
import { getAllCandidatsForReporting } from "../Services/CandidatService";
import { updateProcessDate } from "../Services/ProcessService";
import { getAllCandidatSelections } from "../Services/SelectionCandidatService";
import SelectionLabel from './Components/SelectionLabel';
import SelectionsPopover from './Components/SelectionsPopover';
import ProcessViewModal from "./ProcessViewModal";

// Composant pour les sélections avec mémoisation
const CandidatSelections = React.memo(({ candidatId }) => {
  const [selections, setSelections] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadSelections = async () => {
      try {
        const response = await getAllCandidatSelections(candidatId);
        setSelections(response.data.selectionsCandidat || []);
      } catch (error) {
        console.error('Erreur lors du chargement des sélections:', error);
      } finally {
        setLoading(false);
      }
    };

    loadSelections();
  }, [candidatId]);

  if (loading) return null;

  return (
    <Box display="flex" gap={1} flexWrap="wrap">
      {selections.map((selection) => (
        <Chip
          key={selection.id}
          label={selection.nom}
          size="small"
          color="primary"
          variant="outlined"
          title={`Ajouté par ${selection.auteur} le ${moment(selection.dateAjout).format('DD/MM/YYYY')}`}
        />
      ))}
    </Box>
  );
});

function ProcessView({ snackbarShowMessage }) {
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [loading, setLoading] = useState(true);
  const [processes, setProcesses] = useState([]);
  const [modalStates, setModalStates] = useState({});
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [dateModalOpen, setDateModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [movingCandidate, setMovingCandidate] = useState(null);
  const [targetColumn, setTargetColumn] = useState(null);
  const [selectionAnchorEl, setSelectionAnchorEl] = useState(null);
  const [selectedCandidatId, setSelectedCandidatId] = useState(null);
  const [processSelections, setProcessSelections] = useState({});

  const columns = [
    {
      id: "prequalif",
      titre: "Préqualif RH",
      dateType: null,
      color: '#FFF5F5', // Rouge très clair
      borderColor: '#FFE5E5',
      chipColor: '#FF9999'
    },
    {
      id: "evaluation",
      titre: "Évaluation",
      dateType: "rvTech",
      color: '#F5FAFF', // Bleu très clair
      borderColor: '#E5F3FF',
      chipColor: '#99CAFF'
    },
    {
      id: "presentation",
      titre: "Présentation Client",
      dateType: "rvClient",
      color: '#F5FFF7', // Vert très clair
      borderColor: '#E5FFE9',
      chipColor: '#99FFB3'
    },
    {
      id: "validation",
      titre: "Validation Client",
      dateType: "rvClosing",
      color: '#FFFAF5', // Orange très clair
      borderColor: '#FFF2E5',
      chipColor: '#FFB899'
    },
    {
      id: "integration",
      titre: "Intégration",
      dateType: null,
      color: '#FAF5FF', // Violet très clair
      borderColor: '#F2E5FF',
      chipColor: '#CC99FF'
    }
  ];

  const fetchProcessSelections = useCallback(async (candidatId) => {
    try {
      const response = await getAllCandidatSelections(candidatId);
      setProcessSelections(prev => ({
        ...prev,
        [candidatId]: response.data.selectionsCandidat
      }));
    } catch (error) {
      console.error('Error fetching selections:', error);
    }
  }, []);

  const fetchProcesses = useCallback(async () => {
    setLoading(true);
    try {
      const params = new URLSearchParams();
      params.append('inactive', false);
      params.append('status', 'ACTIVE');

      const response = await getAllCandidatsForReporting(`?${params.toString()}`);
      const candidateData = response?.data?.candidats || [];

      const groupedCandidates = candidateData.reduce((acc, candidate) => {
        let processStep;
        const status = candidate.lastAppointmentStatus;
        const expectedStatus = [
          "Propale",
          "Embauche/Integration",
          "REFUSÉ PAR CLIENT",
          "REFUSÉ PAR CANDIDAT",
          "REFUS PROPALE"
        ];

        if (expectedStatus.includes(status)) {
          processStep = status === "Embauche/Integration" ? "integration" : "validation";
        } else if (candidate.rvClosing?.date) {
          processStep = "validation";
        } else if (candidate.rvClient?.date) {
          processStep = "presentation";
        } else if (candidate.sent?.date) {
          processStep = "presentation";
        } else if (candidate.rvTech?.date) {
          processStep = "evaluation";
        } else {
          processStep = "prequalif";
        }

        const formattedCandidate = {
          ...candidate,
          dateAccrochage: candidate.dateAccrochage?.date ? new Date(candidate.dateAccrochage.date) : null,
          rvTech: candidate.rvTech?.date ? new Date(candidate.rvTech.date) : null,
          sent: candidate.sent?.date ? new Date(candidate.sent.date) : null,
          rvClient: candidate.rvClient?.date ? new Date(candidate.rvClient.date) : null,
          lastAppointmentDatePropale: candidate.lastAppointmentDatePropale?.date ? new Date(candidate.lastAppointmentDatePropale.date) : null,
          lastAppointmentDateIntegration: candidate.lastAppointmentDateIntegration?.date ? new Date(candidate.lastAppointmentDateIntegration.date) : null,
          lastUpdate: (() => {
            // Récupérer toutes les dates disponibles
            const dates = [
              candidate.dateAccrochage?.date,
              candidate.rvTech?.date,
              candidate.sent?.date,
              candidate.rvClient?.date,
              candidate.lastAppointmentDatePropale?.date,
              candidate.lastAppointmentDateIntegration?.date
            ].filter(date => date); // Filtrer les dates null/undefined

            // Si aucune date n'est disponible, retourner null
            if (dates.length === 0) return null;

            // Trouver la date la plus récente
            return new Date(Math.max(...dates.map(date => new Date(date))));
          })(),
          titre: candidate.titre || '',
          nom: candidate.nom || '',
          description: candidate.nom_mission || '',
          societe: candidate.nom_societe || '',
          status: processStep,
          tech: Array.isArray(candidate.techs) ? candidate.techs : [],
          missionId: candidate.missionId || '',
          consultant: candidate.consultant || '',
          processId: candidate.idProcess || '',
          uploadedFiles: Array.isArray(candidate.uploadedFiles) ? candidate.uploadedFiles : [],
          rawData: candidate,
          key: `${candidate.id}_${candidate.idProcess}_${Date.now()}`
        };

        if (!acc[processStep]) {
          acc[processStep] = [];
        }

        const existingCandidate = acc[processStep].find(c => c.candidatId === candidate.id);
        if (!existingCandidate) {
          acc[processStep].push(formattedCandidate);
        }

        return acc;
      }, {});

      Object.keys(groupedCandidates).forEach(step => {
        if (Array.isArray(groupedCandidates[step])) {
          groupedCandidates[step].sort((a, b) => {
            return moment(b.lastUpdate, 'DD/MM/YYYY').diff(moment(a.lastUpdate, 'DD/MM/YYYY'));
          });
        }
      });

      setProcesses(groupedCandidates);

      // Fetch selections for each process
      await Promise.all(
        candidateData.map(candidate => fetchProcessSelections(candidate.id))
      );
    } catch (error) {
      console.error("Error fetching processes:", error);
      snackbarShowMessage("Erreur lors du chargement des processus", "error");
    } finally {
      setLoading(false);
    }
  }, [fetchProcessSelections, snackbarShowMessage]);

  useEffect(() => {
    fetchProcesses();
  }, [fetchProcesses]);

  const handleDateChange = async (date) => {
    if (!date || !movingCandidate || !targetColumn) return;

    try {
      const column = columns.find(col => col.id === targetColumn);
      if (!column.dateType) {
        handleMoveCandidate();
        return;
      }

      const formattedDate = moment(date).format('YYYY-MM-DD');
      await updateProcessDate(
        movingCandidate.processId,
        column.dateType,
        formattedDate
      );

      handleMoveCandidate();
      snackbarShowMessage('Date mise à jour avec succès', 'success');
    } catch (error) {
      console.error('Error updating date:', error);
      snackbarShowMessage('Erreur lors de la mise à jour de la date', 'error');
    } finally {
      setDateModalOpen(false);
      setSelectedDate(null);
      setMovingCandidate(null);
      setTargetColumn(null);
    }
  };

  const handleMoveCandidate = () => {
    if (!movingCandidate || !targetColumn) return;

    const newProcesses = { ...processes };
    const sourceColumn = movingCandidate.status;

    if (!Array.isArray(newProcesses[sourceColumn])) {
      newProcesses[sourceColumn] = [];
    }
    if (!Array.isArray(newProcesses[targetColumn])) {
      newProcesses[targetColumn] = [];
    }

    newProcesses[sourceColumn] = newProcesses[sourceColumn].filter(
      c => c.id !== movingCandidate.id
    );

    const updatedCandidate = {
      ...movingCandidate,
      status: targetColumn,
      id: `${movingCandidate.candidatId}_${targetColumn}_${Date.now()}`
    };
    newProcesses[targetColumn].push(updatedCandidate);

    setProcesses(newProcesses);
    //fetchProcesses();
  };

  const handleDateModalClose = () => {
    setDateModalOpen(false);
    setSelectedDate(null);
    setMovingCandidate(null);
    setTargetColumn(null);
    fetchProcesses();
  };

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) return;

    const sourceColumn = source.droppableId;
    const targetColumn = destination.droppableId;

    const sourceIndex = columns.findIndex(col => col.id === sourceColumn);
    const targetIndex = columns.findIndex(col => col.id === targetColumn);
    if (targetIndex < sourceIndex) {
      snackbarShowMessage('Impossible de revenir en arrière dans le processus', 'warning');
      return;
    }

    const candidate = processes[sourceColumn]?.find(c => c.id === draggableId);
    if (!candidate) return;

    setMovingCandidate(candidate);
    setTargetColumn(targetColumn);

    const column = columns.find(col => col.id === targetColumn);
    if (column.dateType) {
      setDateModalOpen(true);
    } else {
      handleMoveCandidate();
    }
  };

  const handleOpenModal = (candidateId, modalType) => {
    setModalStates(prev => ({
      ...prev,
      [candidateId]: {
        ...prev[candidateId],
        [modalType]: true
      }
    }));
  };

  const handleCloseModal = (candidateId, modalType) => {
    setModalStates(prev => ({
      ...prev,
      [candidateId]: {
        ...prev[candidateId],
        [modalType]: false
      }
    }));
  };

  const handleViewCandidate = useCallback((process) => {
    setSelectedCandidate({
      ...process,
      selections: processSelections[process.id] || []
    });
    setViewModalOpen(true);
  }, [processSelections]);

  const handleCloseViewModal = () => {
    setViewModalOpen(false);
    setSelectedCandidate(null);
  };

  const handleNoteAdded = () => {
    fetchProcesses();
  };

  const handleConfirmDelete = async () => {
    setOpenDialogDelete(false);
  };

  const handleOpenSelections = (event, id) => {
    event.stopPropagation();
    setSelectionAnchorEl(event.currentTarget);
    setSelectedCandidatId(id);
  };

  const handleCloseSelections = () => {
    setSelectionAnchorEl(null);
    setSelectedCandidatId(null);
  };

  const handleSelectionChange = () => {
    fetchProcesses();
  };

  const renderProcessCard = useCallback((process, index) => {
    const column = columns.find(col => col.id === process.status);
    const selections = processSelections[process.id] || [];

    return (
      <Draggable key={process.key} draggableId={process.id} index={index}>
        {(provided, snapshot) => (
          <Card
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            sx={{
              p: 2,
              mb: 2,
              minHeight: '100px',
              position: 'relative',
              backgroundColor: 'white',
              transition: 'all 0.3s ease',
              '&:hover': {
                transform: 'translateY(-2px)',
                boxShadow: 3,
              },
              ...(snapshot.isDragging && {
                transform: 'rotate(2deg)',
                boxShadow: 10,
              })
            }}
          >
            <Box 
              onClick={() => !snapshot.isDragging && handleViewCandidate(process)}
              sx={{ 
                cursor: 'pointer',
                '&:hover': { backgroundColor: 'action.hover' },
                p: 1,
                borderRadius: 1
              }}
            >
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      fontWeight: 'bold',
                      color: 'text.primary',
                      mb: 1
                    }}
                  >
                    {process.titre} {process.nom}
                  </Typography>
                  <Box
                    onClick={e => e.stopPropagation()}
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: 0.5,
                      mb: 2
                    }}
                  >
                    <SelectionLabel
                      selections={selections}
                      onClick={(e) => handleOpenSelections(e, process.id)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 1 }}
                  >
                    {process.description || 'Aucune description'}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <Business
                      fontSize="small"
                      sx={{
                        mr: 0.5,
                        color: column?.chipColor
                      }}
                    />
                    <Typography variant="body2" color="text.secondary">
                      {process.societe || 'Aucune société'}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {Array.isArray(process.tech) && process.tech.slice(0, 3).map((tech, index) => (
                      <Chip
                        key={`${process.id}_tech_${index}`}
                        label={tech}
                        size="small"
                        sx={{
                          fontSize: '0.7rem',
                          backgroundColor: column?.chipColor,
                          color: 'white',
                          '&:hover': {
                            backgroundColor: column?.borderColor
                          }
                        }}
                      />
                    ))}
                    {Array.isArray(process.tech) && process.tech.length > 3 && (
                      <Chip
                        label={`+${process.tech.length - 3}`}
                        size="small"
                        sx={{
                          fontSize: '0.7rem',
                          backgroundColor: column?.chipColor,
                          color: 'white',
                          '&:hover': {
                            backgroundColor: column?.borderColor
                          }
                        }}
                      />
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mt: 1
                  }}>
                    <Typography
                      variant="caption"
                      sx={{
                        color: 'text.secondary',
                        fontStyle: 'italic'
                      }}
                    >
                      Mis à jour le {process.lastUpdate ? moment(process.lastUpdate).format('DD/MM/YYYY') : 'Non défini'}
                    </Typography>
                    {Array.isArray(process.uploadedFiles) && process.uploadedFiles.length > 0 && (
                      <Chip
                        icon={<AttachFile sx={{ fontSize: '1rem' }} />}
                        label={process.uploadedFiles.length}
                        size="small"
                        sx={{
                          height: '20px',
                          backgroundColor: column?.chipColor,
                          color: 'white'
                        }}
                      />
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Box>
            
            <Box 
              sx={{ 
                display: 'flex', 
                gap: 1,
                mt: 1,
                justifyContent: 'flex-end'
              }}
              onClick={e => e.stopPropagation()}
            >
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  handleOpenModal(process.id, 'appointment');
                }}
                title="Planifier un rendez-vous"
                sx={{
                  color: column?.chipColor,
                  '&:hover': {
                    backgroundColor: `${column?.color}80`
                  }
                }}
              >
                <EventNote fontSize="small" />
              </IconButton>
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  handleOpenModal(process.id, 'send');
                }}
                title="Envoyer au client"
                sx={{
                  color: column?.chipColor,
                  '&:hover': {
                    backgroundColor: `${column?.color}80`
                  }
                }}
              >
                <FiSend style={{ fontSize: '1.2rem' }} />
              </IconButton>
            </Box>
          </Card>
        )}
      </Draggable>
    );
  }, [handleOpenSelections, processSelections]);

  // Mémoisation des colonnes de processus
  const processColumns = useMemo(() => {
    return columns.map(column => (
      <Grid item xs={12} sm={6} md={3} key={column.id}>
        <Paper
          sx={{
            p: 2,
            height: '100%',
            backgroundColor: column.color,
            borderTop: `4px solid ${column.borderColor}`,
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              flexShrink: 0,
              color: 'text.primary',
              fontWeight: 'bold',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            {column.titre}
            {Array.isArray(processes[column.id]) && processes[column.id].length > 0 && (
              <Chip
                label={processes[column.id].length}
                size="small"
                sx={{
                  backgroundColor: column.borderColor,
                  color: 'white',
                  fontWeight: 'bold'
                }}
              />
            )}
          </Typography>
          <Droppable droppableId={column.id}>
            {(provided) => (
              <Box
                ref={provided.innerRef}
                {...provided.droppableProps}
                sx={{
                  flex: 1,
                  overflowY: 'auto',
                  minHeight: 0,
                  '&::-webkit-scrollbar': {
                    width: 8,
                  },
                  '&::-webkit-scrollbar-track': {
                    backgroundColor: 'rgba(255, 255, 255, 0.4)',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: column.borderColor,
                    borderRadius: 4,
                  },
                }}
              >
                {Array.isArray(processes[column.id]) && processes[column.id].map((process, index) =>
                  renderProcessCard(process, index)
                )}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </Paper>
      </Grid>
    ));
  }, [processes, renderProcessCard, columns]);

  if (loading) {
    return <Loader />;
  }

  return (
    <Box
      sx={{
        height: 'calc(100vh - 100px)',
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <DragDropContext onDragEnd={onDragEnd}>
        <Box
          sx={{
            overflowX: 'auto',
            flex: 1,
            minHeight: 0,
            '&::-webkit-scrollbar': {
              height: 8,
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: 'background.paper',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'grey.400',
              borderRadius: 4,
            },
          }}
        >
          <Grid
            container
            spacing={2}
            sx={{
              flexWrap: 'nowrap',
              minWidth: 'min-content',
              height: '100%',
              p: 2
            }}
          >
            {columns.map(column => (
              <Grid
                item
                key={column.id}
                sx={{
                  width: 300,
                  flexShrink: 0
                }}
              >
                <Paper
                  sx={{
                    p: 2,
                    height: '100%',
                    backgroundColor: column.color,
                    borderTop: `4px solid ${column.borderColor}`,
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <Typography
                    variant="h6"
                    gutterBottom
                    sx={{
                      flexShrink: 0,
                      color: 'text.primary',
                      fontWeight: 'bold',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    {column.titre}
                    {Array.isArray(processes[column.id]) && processes[column.id].length > 0 && (
                      <Chip
                        label={processes[column.id].length}
                        size="small"
                        sx={{
                          backgroundColor: column.borderColor,
                          color: 'white',
                          fontWeight: 'bold'
                        }}
                      />
                    )}
                  </Typography>
                  <Droppable droppableId={column.id}>
                    {(provided) => (
                      <Box
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        sx={{
                          flex: 1,
                          overflowY: 'auto',
                          minHeight: 0,
                          '&::-webkit-scrollbar': {
                            width: 8,
                          },
                          '&::-webkit-scrollbar-track': {
                            backgroundColor: 'rgba(255, 255, 255, 0.4)',
                          },
                          '&::-webkit-scrollbar-thumb': {
                            backgroundColor: column.borderColor,
                            borderRadius: 4,
                          },
                        }}
                      >
                        {Array.isArray(processes[column.id]) && processes[column.id].map((process, index) =>
                          renderProcessCard(process, index)
                        )}
                        {provided.placeholder}
                      </Box>
                    )}
                  </Droppable>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Box>
      </DragDropContext>

      {Object.entries(modalStates).map(([candidateId, states]) => (
        <React.Fragment key={candidateId}>
          <AppointmentModal
            open={states?.appointment || false}
            onClose={() => handleCloseModal(candidateId, 'appointment')}
            candidatId={candidateId}
            onSuccess={fetchProcesses}
          />
          <SendCandidatToClientModal
            open={states?.send || false}
            onClose={() => handleCloseModal(candidateId, 'send')}
            candidatId={candidateId}
            onSuccess={fetchProcesses}
          />
        </React.Fragment>
      ))}
      <ProcessViewModal
        open={viewModalOpen}
        onClose={handleCloseViewModal}
        process={selectedCandidate}
        onNoteAdded={handleNoteAdded}
        snackbarShowMessage={snackbarShowMessage}
      />
      <Dialog
        open={dateModalOpen}
        onClose={handleDateModalClose}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>
          Sélectionner une date
        </DialogTitle>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={fr}>
            <DatePicker
              value={selectedDate}
              onChange={setSelectedDate}
              renderInput={(params) => <TextField {...params} fullWidth />}
              format="dd/MM/yyyy"
            />
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDateModalClose}>
            Annuler
          </Button>
          <Button
            onClick={() => handleDateChange(selectedDate)}
            variant="contained"
            color="primary"
          >
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
      <DialogDeleteCustom
        open={openDialogDelete}
        handleClose={() => setOpenDialogDelete(false)}
        onConfirm={handleConfirmDelete}
        title="Supprimer le processus"
        content={`Êtes-vous sûr de vouloir supprimer ce processus ?`}
      />
      <SelectionsPopover
        anchorEl={selectionAnchorEl}
        onClose={handleCloseSelections}
        candidatId={selectedCandidatId}
        onSelectionChange={handleSelectionChange}
      />
    </Box>
  );
}

export default withSnackbar(ProcessView);
