import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { Label as LabelIcon, Add as AddIcon } from '@mui/icons-material';

const SelectionLabel = ({ selections = [], onClick, compact = false }) => {
  if (compact) {
    return (
      <Box 
        onClick={onClick}
        sx={{ 
          display: 'flex',
          alignItems: 'center',
          gap: 0.5,
          cursor: 'pointer',
          color: 'text.secondary',
          '&:hover': {
            color: 'primary.main'
          }
        }}
      >
        <LabelIcon fontSize="small" />
        {selections.length > 0 && (
          <Typography variant="body2" component="span">
            {selections.length}
          </Typography>
        )}
        <AddIcon fontSize="small" />
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 0.5 }}>
      {selections.map((selection) => (
        <Box
          key={selection.id}
          sx={{
            backgroundColor: 'primary.main',
            color: 'white',
            borderRadius: '3px',
            py: 0.25,
            px: 1,
            fontSize: '0.75rem',
            display: 'inline-flex',
            alignItems: 'center',
            height: '20px',
            lineHeight: 1,
            '&:hover': {
              filter: 'brightness(0.9)'
            }
          }}
        >
          {selection.nom}
        </Box>
      ))}
      <Box
        onClick={onClick}
        sx={{
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '20px',
          px: 0.5,
          borderRadius: '3px',
          backgroundColor: 'rgba(0, 0, 0, 0.08)',
          cursor: 'pointer',
          color: 'text.secondary',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.12)',
            color: 'text.primary'
          }
        }}
      >
        <AddIcon fontSize="small" />
      </Box>
    </Box>
  );
};

export default React.memo(SelectionLabel);
