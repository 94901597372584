import { Box, Table, TableBody, TableCell, TableContainer, TableRow, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import LinkedIn from '@mui/icons-material/LinkedIn';
import "./icons.css";
import LabelHtmlToTextCustom from "../../../components/LabelHtmlToTextCustom/LabelHtmlToTextCustom";

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  marginTop: 20,
  boxShadow: "rgb(0 0 0 / 50%) 0px 0px 15px",
}));

const StyledBox = styled(Box)(({ theme }) => ({
  padding: "15px",
  boxShadow: "none",
  "& strong": {
    color: "#117aa7",
  }
}));

const ContactInformationsTable = ({ fonction, statut, email, mobile, telFix, linkedIn, Manager, sachezQue, directionName, nom_societe }) => {
  const tableData = [
    {
      title: 'MOBILE',
      value: mobile ? mobile.replace(/(\d{2})\D?(\d{1})\D?(\d{2})\D?(\d{2})\D?(\d{2})\D?(\d{2})/, "$1 $2 $3 $4 $5 $6") : "-",
    },
    {
      title: 'TÉL.FIXE DIRECT',
      value: telFix ? telFix.replace(/(\d{2})\D?(\d{1})\D?(\d{2})\D?(\d{2})\D?(\d{2})\D?(\d{2})/, "$1 $2 $3 $4 $5 $6") : "-",
    },
    {
      title: 'EMAIL PRO',
      value: email,
    },
    {
      title: 'LINKEDIN',
      value: (
        <a href={linkedIn} target="_blank" rel="noreferrer">
          <LinkedIn sx={{ height: 20 }} />
        </a>
      ),
    },
    {
      title: 'DIRECTION',
      value: directionName ? directionName.toUpperCase() : "-",
    },
  ];

  const tableRows = [
    { label: 'SOCIÉTÉ', value: nom_societe },
    { label: 'FONCTION', value: fonction },
    { label: 'STATUT', value: statut },
    { label: 'DELIVERY MANAGER', value: Manager },
    { label: 'SACHEZ QUE', value: <LabelHtmlToTextCustom data={sachezQue} /> },
  ];

  return (
    <StyledBox style={{ paddingTop: 0 }}>
      <StyledTableContainer>
        <Grid container>
          <Grid item sm={12} md={6} xl={6}>
            <Table className={"table table-striped"}>
              <TableBody component="tbody">
                {tableData?.map((row) => (
                  <TableRow key={row.title}>
                    <TableCell sx={{ fontSize: "0.8rem", width: "50%" }} scope="row">
                      <strong>{row.title}</strong>
                    </TableCell>
                    <TableCell sx={{ 
                      fontSize: "0.8rem", 
                      width: "50%", 
                      padding: row.title === "LINKEDIN" ? "0.5rem" : "0.75rem" 
                    }} align="right">
                      {row.value}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
          <Grid item sm={12} md={6} xl={6}>
            <Table className={"table table-striped"}>
              <TableBody component="tbody">
                {tableRows.map((row) => (
                  <TableRow key={row.label}>
                    <TableCell sx={{ fontSize: "0.8rem", width: "50%" }} scope="row">
                      <strong>{row.label}</strong>
                    </TableCell>
                    <TableCell sx={{ 
                      fontSize: "0.8rem", 
                      width: "50%", 
                      textTransform: "capitalize", 
                      padding: "0.75rem" 
                    }} align="right">
                      {row.value}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </StyledTableContainer>
    </StyledBox>
  );
};

export default ContactInformationsTable;
