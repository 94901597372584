import React, { useState } from 'react';
import { Chip, TextField, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const ChipContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(0.5),
  marginTop: theme.spacing(1),
}));

const ChipInput = ({
  value = [],
  onChange,
  label,
  placeholder,
  error,
  helperText,
  fullWidth = false,
  variant = 'outlined',
  ...props
}) => {
  const [inputValue, setInputValue] = useState('');

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && inputValue.trim()) {
      event.preventDefault();
      const newValue = [...value, inputValue.trim()];
      onChange(newValue);
      setInputValue('');
    } else if (event.key === 'Backspace' && !inputValue && value.length > 0) {
      const newValue = value.slice(0, -1);
      onChange(newValue);
    }
  };

  const handleDelete = (chipToDelete) => {
    const newValue = value.filter((chip) => chip !== chipToDelete);
    onChange(newValue);
  };

  return (
    <div>
      <TextField
        label={label}
        placeholder={placeholder}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyDown={handleKeyDown}
        error={error}
        helperText={helperText}
        fullWidth={fullWidth}
        variant={variant}
        {...props}
      />
      <ChipContainer>
        {value.map((chip, index) => (
          <Chip
            key={index}
            label={chip}
            onDelete={() => handleDelete(chip)}
            color="primary"
            variant="outlined"
          />
        ))}
      </ChipContainer>
    </div>
  );
};

export default ChipInput;
